/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import {Spinner, Input, Row, Button, Col, FormGroup } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import lang from 'lang';
import { getAllCountriesData, getAllStateData, getAllBillingData, setCompanyData } from "api/admin";
import { getColor } from "utils/token";

export default function EditEnterprise() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({load: false, country: false, state: false, timezone: false, access: false, billing: false});
  const [arrCountries, setArrCountries] = useState([]);
  const [arrTimezone, setArrTimezone] = useState([]);
  const [arrState, setArrState] = useState([]);
  const [arrBilling, setArrBilling] = useState([]);
  const [name, setName] = useState('');
  const [selCountry, setSelCountry] = useState('');
  const [selState, setSelState] = useState('');
  const [selTimezone, selSetTimezone] = useState('');
  const [color, setColor] = useState('');
  const [fontColor, setFontColor] = useState('');
  const [billing, setBilling] = useState('');
  const [access, setAccess] = useState([]);
  const [publicName, setPublicName] = useState('');
  const [check, setCheck] = useState({gm: false, gz: false});

  useEffect(() => {
    getAllCountries();
    getAllBilling();
  },[]);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const getAllCountries = async () => {
    const res = await getAllCountriesData();
    setArrCountries(res.data);
    setLoading({...loading, country: false});
  }

  const getDataFromCountry = async (id) => {
    setLoading({...loading, timezone: true});
    const country_data = arrCountries.filter( (ac) => {
      return ac.id === (parseInt(id))
    });

    setSelCountry(id);
    setArrTimezone(country_data[0].timezone);
    setLoading({...loading, timezone: false});
    getAllState(id);
  }

  const getAllState = async (id) => {
    setLoading({...loading, state: true});

    const res = await getAllStateData(id);
    setArrState(res.data);

    setLoading({...loading, state: false});
  }
  
  const getAllBilling = async () => {
    setLoading({...loading, billing: true});

    const res = await getAllBillingData();
    setArrBilling(res.data);

    setLoading({...loading, billing: false});
  }

  const addAccess = async (value) => {
    var _access = access;
    const inx = _access.indexOf(value);

    if(inx === -1) {
      _access.push(value);
      
      if(value === 2) {
        setCheck({...check, gm: true});
      }
      else if(value === 3) {
        setCheck({...check, gz: true});
      }
    }
    else {
      _access.splice(inx, 1);
      if(value === 2) {
        setCheck({...check, gm: false});
      }
      else if(value === 3) {
        setCheck({...check, gz: false});
      }
    }
    

    setAccess(_access);
  }

  const createCompany = async () => {
    if(name === '') {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_NAME"));
    }
    else if(selCountry === '') {
      notifyAlert("default", lang("MESSAGE"), lang("SEL_COUNTRY_EMPTY"));
    }
    else if (selTimezone === '') {
      notifyAlert("default", lang("MESSAGE"), lang("SEL_HOUR_EMPTY")); 
    }
    else if (selState === '') {
      notifyAlert("default", lang("MESSAGE"), lang("STATE_EMPTY")); 
    }
    else if (billing === '') {
      notifyAlert("default", lang("MESSAGE"), lang("SEL_BILLING_EMPTY")); 
    }
    else if (access.length === 0) {
      notifyAlert("default", lang("MESSAGE"), lang("ACCESS_COMPANY_EMPTY")); 
    }
    else if(check.gz === true && publicName === "") {
      notifyAlert("default", lang("MESSAGE"), lang("PUBLIC_NAME")); 
    }
    else {
      const primary_color = (color === '') ? '#0071bc' : color;
      const secondary_color = (fontColor === '') ? '#333333' : fontColor;

      const jsn = {
        "name": name,
        "country": selCountry,
        "state": selState,
        "timezone": selTimezone.substring(3),
        "color": primary_color,
        "font_color": secondary_color,
        "billing": billing,
        "access": access,
        "public_name": publicName
      } 
      
      const res = await setCompanyData(jsn);

      if(res.success === true) {
        window.location.href = '/admin/enterprise';
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
    }

  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <div style={{padding: '10px'}}>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label"> {lang("COMPANY_NAME")} </label>
              <Input
                value={name}
                placeholder={lang("NAME")}
                className="form-control"
                onChange={val => setName(val.target.value)} />
            </FormGroup>
          </Col>

          <Col lg="6">
            <FormGroup>
              <label className="form-control-label"> {lang("SEL_COUNTRY")} </label>
              {
                (loading.country) ? <>
                  <br />
                  <Spinner style={{color: getColor(1)}} size="sm" />
                </> : <>
                  <select
                    className="form-control"
                    value={selCountry}
                    onChange={val => getDataFromCountry(val.target.value)}
                  >
                    <option>{lang("SELECT")}</option>
                    {
                      arrCountries.map( (c, i) => (
                        <option 
                          key={i}
                          value={c.id}>{c.name}</option>
                      ))
                    }
                  </select>                          
                </>
              }
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label"> {lang("SEL_HOUR")} </label>
              {
                (loading.timezone) ? <>
                  <br />
                  <Spinner style={{color: getColor(1)}} size="sm" />
                </> : <>
                  <select
                    className="form-control"
                    value={selTimezone}
                    onChange={val => selSetTimezone(val.target.value)}
                  >
                    <option>{lang("SELECT")}</option>
                    {
                      arrTimezone.map( (s, i) => (
                        <option 
                          key={i}
                          value={s}>{s}</option>
                      ))
                    }
                  </select>
                </>
              }
            </FormGroup>
          </Col>

          <Col lg="6">
            <FormGroup>
              <label className="form-control-label"> {lang("SEL_STATE_COMPANY")} </label>
              <select
                className="form-control"
                value={selState}
                onChange={val => setSelState(val.target.value)}
              >
                <option>{lang("SELECT")}</option>
                {
                  arrState.map( (s, i) => (
                    <option 
                      key={i}
                      value={s.id}>{s.state}</option>
                  ))
                }
              </select>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label"> {lang("MAIN_COLOR")} </label> <br />
              <input
                type="color"
                style={{
                  width: '20%',
                  display: 'inline-block',
                  top: '2px',
                  position: 'relative'
                }}
                className="form-control"
                value={color}
                onChange={val => setColor(val.target.value)} />
                <input 
                  value={color}
                  className="form-control"
                  style={{
                    width: '80%',
                    display: 'inline-block'
                  }}
                  onChange={val => setColor(val.target.value)} />
            </FormGroup>
          </Col>

          <Col lg="6">
            <FormGroup>
              <label className="form-control-label"> {lang("TEXT_COLOR")} </label> <br />
              <input
                type="color"
                style={{
                  width: '20%',
                  display: 'inline-block',
                  top: '2px',
                  position: 'relative'
                }}
                className="form-control"
                value={fontColor}
                onChange={val => setFontColor(val.target.value)} />

                <input 
                  value={fontColor}
                  className="form-control"
                  style={{
                    width: '80%',
                    display: 'inline-block'
                  }}
                  onChange={val => setFontColor(val.target.value)} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label"> {lang("SEL_BILLING")} </label>
              {
                (loading.billing) ? <>
                  <br />
                  <Spinner style={{color: getColor(1)}} size="sm" />
                </> : <>
                  <select
                    className="form-control"
                    value={billing}
                    onChange={val => setBilling(val.target.value)}
                  >
                    <option>{lang("SELECT")}</option>
                    {
                      arrBilling.map( (s, i) => (
                        <option 
                          key={i}
                          value={s.id}>{s.name}</option>
                      ))
                    }
                  </select>
                </>
              }
            </FormGroup>
          </Col>
          <Col lg="6">
            <label className="form-control-label"> {lang("ACCESS_COMPANY")} </label>
            <ul style={{
              listStyle: "none",
              padding: "0",
              lineHeight:"2"
            }}>
              <input 
                type="checkbox" 
                checked={check.gm}
                onChange={() => addAccess(2)}/>
              <span style={{marginLeft: '5px'}}>GoMap</span>
              <br />
              <input 
                type="checkbox" 
                checked={check.gz}
                onChange={() => addAccess(3)}/>
              <span style={{marginLeft: '5px'}}>GoZelle</span>                
            </ul>
          </Col>
        </Row>

        {
          (check.gz) ? 
          <>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label"> {lang("PUBLIC_NAME")} </label>
                <Input
                  value={publicName}
                  placeholder={lang("NAME")}
                  className="form-control"
                  onChange={val => setPublicName(val.target.value)} />
              </FormGroup>
            </Col>
          </Row>
          </>:<></>
        }

        <Row>
          <Col lg="12" >
            <br />
            <div className="float-right">
              <Button color="gray" to="/admin/enterprise" tag={Link}> {lang("BACK")} </Button>

              <Button
                color="primary"
                style={{
                  backgroundColor: getColor(1),
                  color: getColor(2),
                  border: 0
                }}
                onClick={() => createCompany()} 
                > {lang("SAVE")}
              </Button>
            </div>
          </Col>
        </Row>
      </div>

    </>
  )
}
