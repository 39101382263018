/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Card, CardBody, CardHeader, Container, Row, Button, Col, FormGroup, Input, Spinner} from "reactstrap";
import Header from "components/Headers/Header";
import NotificationAlert from "react-notification-alert";
import lang from 'lang';
import { getColor } from "utils/token";
import { getCompanyEmailData, updatePasswordData } from "api/company";

export default function Settings() {
  const notificationAlertRef = React.useRef(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState({oldPass: '', newPass: '', repeatPass: ''});
  const [loading, setLoading] = useState({ email_page: true, email: false, pass: false});

  useEffect(() => {
    getCompanyEmail();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const getCompanyEmail = async () => {
    const res = await getCompanyEmailData();
    setEmail(res.data.email)
    setLoading({...loading, email_page: false});
  }

  const updatePassword = async () => {
    setLoading({...loading, pass: true});

    const {newPass, oldPass, repeatPass} = password;
    
    if(oldPass === "") {
      notifyAlert("default", lang("MESSAGE"), lang("OLD_PASSWORD_EMPTY"));
    }
    else if(newPass === '') {
      notifyAlert("default", lang("MESSAGE"), lang("NEW_PASSWORD_EMPTY"));
    }
    else if(repeatPass === '') {
      notifyAlert("default", lang("MESSAGE"), lang("REPEAT_PASSWORD_EMPTY"));
    }
    else if(oldPass === newPass) {
      notifyAlert("default", lang("MESSAGE"), lang("PASSWORD_ALREADY_USE"));
    }
    else if(newPass !== repeatPass) {
      notifyAlert("default", lang("MESSAGE"), lang("PASSWORD_NO_EQUAL"));
    }
    else {
      const jsn = {
        "oldPass": oldPass,
        "newPass": newPass
      }

      const res = await updatePasswordData(jsn);
      
      if(res.success === true) {
        setPassword({oldPass: '', newPass: '', repeatPass: ''});
        notifyAlert("success", lang("MESSAGE"), res.data);
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
    }
    setLoading({...loading, pass: false});
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("SECURITY")} </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className='paddingHorizontal'>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label">{lang("OLD_PASSWORD")}</label>
                        <Input
                          value={password.oldPass}
                          type="password"
                          placeholder={lang("OLD_PASSWORD")}
                          onChange={val => setPassword({...password, oldPass: val.target.value})}
                        />
                        <br />
                        <label className="form-control-label">{lang("NEW_PASSWORD")}</label>
                        <Input
                          value={password.newPass}
                          type="password"
                          placeholder={lang("NEW_PASSWORD")}
                          onChange={val => setPassword({...password, newPass: val.target.value})}
                        />
                        <br />

                        <label className="form-control-label">{lang("REPEAT_PASSWORD")}</label>
                        <Input
                          value={password.repeatPass}
                          type="password"
                          placeholder={lang("REPEAT_PASSWORD")}
                          onChange={val => setPassword({...password, repeatPass: val.target.value})}
                        />

                      </FormGroup>
                      {
                        (loading.pass) ? <div>
                          <Spinner style={{
                            color: getColor(1)
                          }} />
                        </div> :
                        <>
                          <Button
                            style={{
                              backgroundColor: getColor(1),
                              color: getColor(2),
                              border: 0
                            }}
                            onClick={() => updatePassword()}
                            > {lang("SAVE")}
                          </Button>
                        </>
                      }
                    </Col>

                    <Col md="6">
                      {
                        (loading.email_page) ? <> 
                          <Spinner style={{ color: getColor(1)}} />
                        </>:
                        <>
                          <FormGroup>
                            <label className="form-control-label">{lang("EMAIL")}</label>
                            <Input
                              disabled={true}
                              value={email}
                              onChange={val => setEmail(val.target.value)}
                              placeholder={lang("ENTER_EMAIL")}
                            />
                          
                            <span className="text-muted" style={{
                              fontSize: '12px'
                            }}>{lang('UPDATE_EMAIL_REQUEST_TEXT')}</span>
                          </FormGroup>
                        </>
                      }
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
