/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, {useState, useEffect} from "react";
import {Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Button} from "reactstrap";
import { useLocation, Route, Switch, useHistory } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar";
import AdminFooter from "components/Footers/AdminFooter";
import Sidebar from "components/Sidebar/Sidebar";
import { useIdleTimer } from 'react-idle-timer';
import { AuthContext } from "context/AuthContext";
import lang from 'lang';
import {getCompanyModules} from "api/company";
import {getToken, getTokenData, getColor} from "utils/token";
import {routes} from "routes";
import NotFound from "views/notfound/NotFound";

var timesRun = 0;
var interval;

function Admin() {
  const history = useHistory();
  const {signOut} = React.useContext(AuthContext);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [picture, setPicture] = useState('');
  const [access, setAccess] = useState([]);

  const [sidenavOpen, setSidenavOpen] = useState(true);
  const location = useLocation();
  const mainContentRef = React.useRef(null);

  useEffect(() => {
    getData();
    tryToRedirect();
  },[]);
  
  const getData = async () => {
    var arrModules = [];
    const _name = getTokenData('GoSuiteToken', 'name');
    const _picture = getTokenData('GoSuiteToken', 'picture');
    const data = await getCompanyModules();

    const module_data = data.data;

    for(var d of module_data) {
      arrModules.push(d.access);
    }

    setName(_name);
    setPicture(_picture);
    setAccess(arrModules);
    setLoading(false);
  }

  const tryToRedirect = () => {
    const newUrlToRedirect = window.sessionStorage.getItem('redirect');
    window.sessionStorage.removeItem('redirect')
    console.log("URL ===> ", newUrlToRedirect);

    if(newUrlToRedirect) {      
      if(newUrlToRedirect === "/auth/login" || newUrlToRedirect === "/admin" || newUrlToRedirect === "/") {
        console.log('igual');
      }
      else {
        history.push(newUrlToRedirect);
      }
    }
  }

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin" && prop.access === 0 || access.includes(prop.access)) {
        return (
          <Route
            exact={true}
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  const handleStart = () => {  
    interval = setInterval(function(){
      timesRun += 1;
      if(timesRun === 300){        
        clearInterval(interval);
        signOut();
      }
    }, 1000); 

  }
 
  const handleReset = () => {
    clearInterval(interval);
  }
  
  const handleOnIdle = event => {
    if(getToken("GoSuiteToken") !== null) {
      setModal(true);
      handleStart();
    }
  }

  const closeModalAndRestart = () => {
    setModal(false);
    handleReset();
  }

  useIdleTimer({
    timeout: 900000,
    onIdle: handleOnIdle,
    debounce: 500
  });

  return (
    <>
      {
        (loading) ? <>
          <div style={{
            position: 'absolute',
            display: 'block',
            top: '50%',
            left: '50%',
          }}>
            <Spinner style={{color: getColor(1), width: '3rem', height: '3rem' }}/>
          </div>
        </> :
        <>
          <Sidebar
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            access={access}
            logo={{
              innerLink: "/",
              imgSrc: require("assets/img/logo.png").default,
              imgAlt: "...",
            }}
          />

          <div className="main-content" ref={mainContentRef}>
            <AdminNavbar
              name={name}
              picture={picture}
              toggleSidenav={toggleSidenav}
              sidenavOpen={sidenavOpen}
              brandText={getBrandText(location.pathname)}
            />

            <Switch>
              {getRoutes(routes)}
              <Route component={NotFound} />
              {/* <Redirect from="*" to="/admin/" /> */}
            </Switch>
            <AdminFooter />

          </div>

          {sidenavOpen ? (
            <div className="backdrop d-xl-none" onClick={toggleSidenav} />
          ) : null}

            <Modal 
              isOpen={modal} 
              toggle={closeModalAndRestart} 
              external={false}
              style={{
                backgroundColor: 'rgba(200,200,200,0.9)'
              }}>
              <ModalHeader toggle={closeModalAndRestart}>{lang("MESSAGE")}</ModalHeader>
              <ModalBody>
                {lang("WAIT_TIME")}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={() => signOut()}>{lang("LOGOUT")}</Button>
                <Button
                  color="primary"
                  style={{
                    backgroundColor: getColor(1),
                    color: getColor(2),
                    border: 0
                  }}
                  onClick={() => closeModalAndRestart()}
                  > {lang("CONTINUE")}
                </Button>
              </ModalFooter>
            </Modal>
        </>
      }
    </>
  );
}

export default Admin;
