/* eslint-disable-next-line react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, {useState, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import {Card, Container, CardHeader, Row, Button, Col, CardBody, FormGroup, Input, Spinner } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import Header from "components/Headers/Header";
import lang from 'lang';
import { getColor } from "utils/token";
import { getPaymentByIdData } from "api/payments";
import dayjs from 'dayjs';
import { es } from "dayjs/locale/es";
dayjs.locale("es");

const PaymentDetail = () => {
  const notificationAlertRef = React.useRef(null);
  const {id} = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    id: '',
    amount: '',
    customer_name: '',
    customer_email: '',
    current_status: '',
    reference_number: '',
    invoices: [],
    tracking: []
  });

  useEffect(() => {
    getPaymentById();
  },[]);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const getPaymentById = async () => {
    const res = await getPaymentByIdData(id);

    if(res.success === true) {  
      setData({
        customer_name: res.data.customer_name,
        customer_email: res.data.customer_email,
        reference_number: res.data.reference_number,
        id: res.data.id,
        amount: Number(res.data.amount).toFixed(2),
        current_status: res.data.current_status,
        invoices: JSON.parse(res.data.invoices),
        tracking: JSON.parse(res.data.tracking)
      })
    }
    else {
      notifyAlert("danger", lang("MESSAGE"), res.error.message);
    }

    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

            <CardHeader className="border-0">
              <Row>
                <div className="col mb-0 float-left">
                  <h3> {lang("PAYMENTS")} </h3>
                </div>
              </Row>
            </CardHeader>
            {
              (loading) ? <>
                <div className="text-center" style={{
                  paddingBottom: '20px'
                }}>
                  <Spinner style={{color: getColor(1)}} />
                </div>
              </> :
              <>
                <CardBody>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label"> {lang("NAME")} </label>
                          <Input
                            readOnly
                            value={data.customer_name}
                            className="form-control"
                            onChange={val => setName(val.target.value)} />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label"> {lang("EMAIL")} </label>
                          <Input
                            readOnly
                            value={data.customer_email}
                            className="form-control"/>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label"> {lang("ZELLE_REFERENCE")} </label>
                          <Input
                            readOnly
                            value={data.reference_number}
                            className="form-control" />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label"> {lang("PAYMENT_ID")} </label>
                          <Input
                            readOnly
                            value={data.id}
                            className="form-control" />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label"> {lang("AMOUNT")} </label>
                          <Input
                            readOnly
                            value={data.amount}
                            className="form-control" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label"> {lang("STATUS")} </label>
                          <Input
                            readOnly
                            value={data.current_status}
                            className="form-control" />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label"> {lang("STATUS")} </label>
                          <ul style={{
                            listStyle: 'none',
                            padding: 0
                          }}>
                            {
                              (data.tracking).map( (t, index) => (
                                <li 
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                  }}> 
                                  <span> {t.status} </span>
                                  <b>{dayjs(t.date).subtract(4, 'hour').format('ddd, MMM D, YYYY h:mma')}</b>
                                </li>
                              ))
                            }
                          </ul>
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label"> {lang("INVOICES")} </label>
                          <ul style={{
                            listStyle: 'none',
                            padding: 0
                          }}>
                            {
                              (data.invoices).map( (i, index) => (
                                <li 
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}> 
                                  <span>{i.invoice}</span>
                                  <b>${i.total}</b>
                                </li>
                              ))
                            }
                          </ul>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12" >
                        <br />
                        <div className="float-right">
                          <Button color="gray" to="/admin/gozelle/payments" tag={Link}> {lang("BACK")} </Button>  
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </>
            }
            </Card>
 
          </div>
        </Row>
      </Container>
    </>
  )
}

export default PaymentDetail;