import React, {useState} from 'react';
import { Collapse, Card, Container, Button } from "reactstrap";
import Title from './Title';
import lang from 'lang';

export default function Accordion({id, bank_name, active, accounts, repair, action, removeItem}) {
  const dots = {
    display: 'inline-block',
    height: '5px', 
    width: '5px', 
    backgroundColor: '#333', 
    borderRadius: '50%',
    marginRight: '2px',
    marginLeft: '2px',
    marginBottom: '1px'
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
     <div
      style={{
        padding: '10px',
      }}>
      <Card style={{border:'1px solid', padding: '10px'}}>
        <ul style={{
          padding: '0px',
          listStyle: 'none',
          display: 'inline-flex',
          justifyContent: 'space-between'
        }}>
          <li style={{display: 'inline-flex',}}>
            <Title 
              title={bank_name}
              active={active}
              toggle={toggle}
            />
            {
              (!active) ? <> 
                <div style={{
                  cursor: 'pointer',
                  marginLeft: '10px',
                  display: 'inline-flex',
                  marginTop: '12px',
                }}>
                  <Button className="float-right btn-sm" color='warning' onClick={() => repair(id)}> {lang("FIX_ACCOUNT")} </Button>
                </div>
              </> : <></>
            }
          </li>
          <li>
            {
              (!isOpen) ? <i style={{
                cursor: 'pointer',
                marginTop: '19px',
                marginRight: '5px',
                fontSize: '20px'
              }}
              className="fas fa-chevron-down" onClick={toggle}/> : 
              <i style={{
                cursor: 'pointer',
                marginTop: '19px',
                marginRight: '5px',
                fontSize: '20px'
              }}
              className="fas fa-chevron-up" onClick={toggle}/>
            }
          </li>
        </ul>

        <Collapse isOpen={isOpen}>
          <Container>
            {
              (accounts).map((a, j) => (
                <div key={j}>
                  <div style={{display: 'block'}}>
                    <ul style={{
                      padding: '10px',
                      listStyle: 'none',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}>
                      <li>
                        <ul style={{
                          padding: '0px',
                          listStyle: 'none',
                          display: 'inline-flex',
                        }}>
                          <li>
                            <label style={{display: 'block', marginBottom: '-5px'}}>{a.name}</label>
                          </li>
                          <li>
                            <ul style={{
                              listStyle: 'none',
                              display: 'flex',
                            }}>
                              <li>
                                <span style={dots}></span>
                                <span style={dots}></span>
                                <span style={dots}></span>
                                <span style={dots}></span>
                              </li>
                              <li>
                                <span style={{fontSize: '12px', marginLeft: '2px', marginRight: '2px'}}>-</span>
                              </li>
                              <li>
                                <span style={{fontSize: '12px'}}>{a.mask}</span>
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <br />
                        <span style={{fontSize: '12px'}}>{a.official_name}</span>
                      </li>
                      <li>
                        <div style={{display: 'block', textAlign: 'right'}}>
                          <div>
                            <label style={{
                              display: 'inline-flex',
                            }}>{lang("ZELLE_VERIFICATION")}</label>
                            <div style={{display: 'inline-flex', verticalAlign: 'text-top', marginLeft: '10px'}} className="toggle-switch">
                              <input
                                key={j}
                                id={`chk-${a.id}`}
                                name="chkTest"
                                type="checkbox"
                                value={a.id}
                                checked={a.active}
                                onChange={(val) => action({id: a.id, value: val.target.checked})}/> 
                              <label htmlFor={`chk-${a.id}`}>
                                <span className="toggle-track"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <hr />
                </div>
              ))
            }
            
            <div>
              <Button
                color="danger"
                className="float-right btn-sm"
                style={{cursor:'pointer', marginTop:'0px', marginBottom:'20px'}} 
                onClick={() => removeItem(id)}
              >
                {lang("DELETE_ACCOUNT")}
              </Button>
            </div>
          </Container>
        </Collapse>
      </Card>
    </div>
    </>
  )
}