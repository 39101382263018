import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

export async function getAllStateData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.allStates, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setStateData(selState, active, latitude, longitude, zoom) {
	const token = getToken('GoSuiteToken');

	const jsn = {
		"state": selState,
		"active": (active === '') ? 1 : active,
		"latitude": latitude,
		"longitude": longitude,
		"zoom": zoom
	}

	try {
		const res = await axios.post(CONS.setStates, jsn, {
	    headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getAllCompanyStateData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.state, {
			headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getCompanyStateInfoData(id) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.state}/${id}`, {
			headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateStateData(id, active, latitude, longitude, zoom) {
	const token = getToken('GoSuiteToken');
	
	const jsn = {
		"active": (active === '') ? 1 : active,
		"latitude": latitude,
		"longitude": longitude,
		"zoom": zoom
	}

	try {
		const res = await axios.put(`${CONS.state}/${id}`, jsn, {
		  headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setCityData(name, selState, latitude, longitude, zoom) {
	const token = getToken('GoSuiteToken');

	const jsn = {
		"name": name,
		"state": selState,
		"latitude": latitude,
		"longitude": longitude,
		"zoom": zoom
	}

	try {
		const res = await axios.post(CONS.city, jsn, {
		  headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getCompanyCitiesData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.city, {
			headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getCompanyCityInfoData(id) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.city}/${id}`, {
	    headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function deleteCityData(id) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.delete(`${CONS.city}/${id}`, {
		  headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateCityData(id, name, selState, latitude, longitude, zoom) {
	const token = getToken('GoSuiteToken');

	const jsn = {
		"name": name,
		"state": selState,
		"latitude": latitude,
		"longitude": longitude,
		"zoom": zoom
	}

	try {
		const res = await axios.put(`${CONS.city}/${id}`, jsn, {
		  headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getCitiesByStateData(id) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.citiesByState}/${id}`, {
		  headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getCoordsByStateData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.stateCoords, {
		  headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function deleteStateData(id) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.delete(`${CONS.state}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}
