/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Spinner, Row, Button, Input, Col, FormGroup} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import lang from 'lang';
import { getColor } from "utils/token";
import { getCompanyNotificationsConfig, updateCompanyNotificationsConfig } from "api/gozelle";

export default function Notification() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({load: true, save: false});
  const [notificationsConfig, setNotificationsConfig] = useState({supportEmail:'', messageNewText:'', messageInProcessText:'', messageApproveText:'', messageDeclinedText:''});

  useEffect(() => {
    funct();
  },[]);

  const funct = async () => {
    setLoading({...loading, load: true});
    const response = await getNotificationsConfig();

    if(response) {
      setLoading({...loading, load: false});
    }
  }
  
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  
  const getNotificationsConfig = async () => {
    const res = await getCompanyNotificationsConfig();
    
    setNotificationsConfig({
      supportEmail: res.data.supportEmail,
      messageNewText: res.data.messageNewText,
      messageInProcessText: res.data.messageInProcessText,
      messageApproveText: res.data.messageApproveText,
      messageDeclinedText: res.data.messageDeclinedText
    });
  }

  const updateNotificationsConfig = async () => {
    setLoading({...loading, save: true});

    let jsn = {
      "supportEmail": notificationsConfig.supportEmail.trim(),
      "messageNewText": notificationsConfig.messageNewText.trim(),
      "messageInProcessText": notificationsConfig.messageInProcessText.trim(),
      "messageApproveText": notificationsConfig.messageApproveText.trim(),
      "messageDeclinedText": notificationsConfig.messageDeclinedText.trim()
    }

    const response = await updateCompanyNotificationsConfig(jsn);
    
    if(response.success === true) {
      notifyAlert("success", lang("MESSAGE"), response.data);
    }
    else {
      notifyAlert("danger", lang("MESSAGE"), response.error.message);
    }

    setLoading({...loading, save: false});
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="tabInside">
        <label 
          className="navbar-heading mb-4"
          style={{
            fontSize: '1.06rem',
            color: '#32325d',
            fontWeight: 'bold'
          }}
        >
          {lang("EMAIL_NOTIFICATIONS_TITLE")}
        </label>
        
        <Row>
          <Col md="12">
            <FormGroup>
              <label className="form-control-label"> {lang("SUPPORT_EMAIL")} </label>
              <Input
                className="form-control"
                value={notificationsConfig.supportEmail}
                placeholder={notificationsConfig.supportEmail}
                onChange={val => setNotificationsConfig({...notificationsConfig, supportEmail: val.target.value})}
              />
            </FormGroup>
          </Col>
        </Row>
        
        <Row>
          <Col md="12">
            <FormGroup>
              <label className="form-control-label">{lang("MESSAGE_NEW_TEXT")}</label>
              <textarea 
                className="form-control"
                value={notificationsConfig.messageNewText}
                onChange={val => setNotificationsConfig({...notificationsConfig, messageNewText: val.target.value})}
              >
              </textarea>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <FormGroup>
              <label className="form-control-label">{lang("MESSAGE_PROCESS_TEXT")}</label>
              <textarea 
                className="form-control"
                value={notificationsConfig.messageInProcessText}
                onChange={val => setNotificationsConfig({...notificationsConfig, messageInProcessText: val.target.value})}
              >
              </textarea>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <FormGroup>
              <label className="form-control-label">{lang("MESSAGE_APPROVE_TEXT")}</label>
              <textarea 
                className="form-control"
                value={notificationsConfig.messageApproveText}
                onChange={val => setNotificationsConfig({...notificationsConfig, messageApproveText: val.target.value})}
              >
              </textarea>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <FormGroup>
              <label className="form-control-label">{lang("MESSAGE_DECLINED_TEXT")}</label>
              <textarea 
                className="form-control"
                value={notificationsConfig.messageDeclinedText}
                onChange={val => setNotificationsConfig({...notificationsConfig, messageDeclinedText: val.target.value})}
              >
              </textarea>
            </FormGroup>
          </Col>
        </Row>

        <Row className="float-right">
          <Col md="12">
          {
            (loading.save) ? <div className="text-center">
              <Spinner style={{
                color: getColor(1)
              }} />
            </div> :
            <>
              <Button
                style={{
                  backgroundColor: getColor(1),
                  color: getColor(2),
                  border: 0
                }}
                onClick={() => updateNotificationsConfig()}
                > {lang("SAVE")}
              </Button>
            </>
          }
          </Col>
        </Row>
      </div>
    </>
  )
}
