import React, {useState, useEffect} from 'react';
import Header from "components/Headers/Header";
import { Spinner, Card, CardHeader, Container, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import lang from 'lang';
import {
  createLinkToken, 
  exchangePublicToken, 
  getBankAccountsData, 
  updateLinkToken, 
  updatePlaidItem, 
  removePlaidItem,
  setAccountActiveData
} from 'api/gozelle';

import { getColor } from "utils/token";
import Accordion from './component/Accordion';

const Bank = () => {
  const Plaid = window.Plaid;
  const notificationAlertRef = React.useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalItemRemove, setModalItemRemove] = useState(false);
  const [checkId, setCheckId] = useState({});
  const [itemRemove, setItemRemove] = useState(0);
  const [progressBar, setProgressBar] = useState(false);
  const [progressBarValue, setProgressBarValue] = useState(0);

  useEffect( () => {
    getBankAccounts();
  }, []);
  
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const toggleDelete = () => setModalDelete(!modalDelete);
  const toggleRemove = () => setModalItemRemove(!modalItemRemove);

  const getBankAccounts = async () => {
    setLoading(true);
    const res = await getBankAccountsData();
    console.log(res.data);

    if(res.success === true) {
      setError(false);
      setData(res.data);
    }
    else {
      setError(true);
      setData([]);
    }
    setLoading(false);
  }
  
  const actionItem = async (data) => {
    setCheckId(data);
    
    if(data.value === false) {
      toggleDelete();
    }
    else {
      const res = await setAccountActiveData(data.id, 1);
      
      if(res.success === true) {
        notifyAlert("success", lang("MESSAGE"), lang("ACTIVE_ACCOUNT"));
        getBankAccounts();
      }
      else {
        toggleDelete();
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
    }
  }

  const accountAction = async () => {
    const res = await setAccountActiveData(checkId.id, 0);

    if(res.success === true) {
      toggleDelete();
      getBankAccounts();
    }
    else {
      toggleDelete();
      notifyAlert("danger", lang("MESSAGE"), res.error.message);
    }
  }

  let accounts_data;
  async function plaidLink() {

    async function fetchLinkToken() {
      const data = await createLinkToken();
      console.log("LINK_TOKEN_DATA", data);
      //Data fields => expiration, link_token, request_id, status_code; success
      const {link_token} = data.data;
      return link_token;
    }

    async function fetchExchangeToken(metadata, public_token) {
      const {institution_id, name} = metadata.institution;
      const {accounts} = metadata;
      
      //Data fields => expiration, link_token, request_id, status_code; success
      accounts_data = await exchangePublicToken(institution_id, name, public_token, accounts);
      console.log("ACCOUNTS_DATA", accounts_data);  
    }

    const configs = {
      token: await fetchLinkToken(),
      onSuccess: async (public_token, metadata) => {
        console.log('PUBLIC TOKEN ==>', public_token);
        console.log('METADATA ==>', metadata);
        await fetchExchangeToken(metadata, public_token);
      },
      onEvent: (metadata) => {
        console.log("METADATA ON_EVENT===>", metadata);
        if(metadata === "HANDOFF") {
          plaidCallBack();
        }
      },
      onExit: (error, metadata) => {
        console.log("ERROR ==>", error);
        console.log("METADATA ON_EXIT ===>", metadata);
      }
    }
  
    const handler = Plaid.create(configs);
    handler.open() 
  }

  async function plaidCallBack() {
    setProgressBar(true);

    let wait_time = 35000;
    let timer = 0;
    let progress = 0;

    var progressAdvance = window.setInterval(function() {
      timer = timer + 1000;
      progress = (timer/wait_time)*100;
      if(progress < 100) {
        setProgressBarValue(progress);
      }
      else {
        setProgressBarValue(100);
      }
    }, 1000);

    setTimeout(function () {
      clearInterval(progressAdvance);
      setProgressBar(false);
      setProgressBarValue(0);
      if(accounts_data.success === true) {
        getBankAccounts();
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), accounts_data.error.message);
      }

    }, (wait_time + 5000));
  }

  async function plaidLinkUpdateMode(BankCredentialID) {

    async function fetchLinkTokenUpdate() {
      console.log("ITEM_BANK_CREDENTIAL_ID ===>", BankCredentialID);
      const data = await updateLinkToken(BankCredentialID);
      console.log("LINK_TOKEN_UPDATE_MODE_DATA ===>", data);
      //Data fields => expiration, link_token, request_id, status_code; success
      const {link_token} = data.data;
      return link_token;
    }

    const configs = {
      token: await fetchLinkTokenUpdate(),
      onSuccess: async (public_token, metadata) => {
        console.log('PUBLIC TOKEN ==>', public_token);
        console.log('METADATA ==>', metadata);
        console.log('ONSUCCESS ===> BankCredentialID', BankCredentialID);
        const data = await updatePlaidItem(BankCredentialID);
        if(data.success === true) {
          console.log(data);
          getBankAccounts();
        }
      },
      onExit: (error, metadata) => {
        console.log("ERROR ==>", error);
        console.log("METADATA ===>", metadata);
      }
    }
  
    const handler = Plaid.create(configs);
    handler.open() 
  }

  const setPlaidItem = async (id) => {
    console.log('ITEM_ID ===>', id);
    setItemRemove(id);
    toggleRemove();
  }

  const plaidItemRemove = async (id) => {
    const data = await removePlaidItem(id);
    if(data.success === true) {
      notifyAlert("danger", lang("MESSAGE"), lang("PLAID_DELETED_ITEM"));
      getBankAccounts();
      toggleRemove();
    }
   console.log(data);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("BANK_ACCOUNT")} </h3>
                  </div>
                  <div className="col">
                    <Button 
                      className="float-right btn-sm"
                      style={{
                        backgroundColor: getColor(1),
                        color: getColor(2),
                        border: 0
                      }}
                      onClick={() => plaidLink()}
                    >{lang("NEW_BANK_ACCOUNT")}</Button>
                  </div>
                </Row>
              </CardHeader>
              
              {
                (loading) ? <>
                  <div className='text-center'>
                    <Spinner style={{color: getColor(1)}} />
                    <br/> <br/>
                  </div>
                </> : 
                  (error) ? 
                  <div>
                    <div className='text-muted text-center'>
                      <label>{lang("ERROR_LOAD")}</label>
                      <br />
                      <Button 
                        color="secondary"
                        onClick={() => getBankAccounts()}>{lang("LOAD")}</Button>
                    </div>
                  </div>:
                  (progressBar) ? 
                    <div className="text-center p-4">
                      <label>{lang("PLAID_PROCESSING_ITEM_CREATION")}</label>
                      <Progress 
                        color={getColor(1)} 
                        value={progressBarValue}
                        style={{height:'15px', marginBottom:'-10px', fontSize: '13px'}}
                        animated 
                      >
                        {progressBarValue.toFixed(2) + " %"}
                      </Progress>
                    </div>
                  :
                  (data.length === 0) ? 
                  <div style={{textAlign: 'center'}}>
                    <label className='text-muted text-center'> {lang("EMPTY_BANK_DATA")} </label>
                  </div>
                  :
                <>
                  {
                    data.map((d, i) => (
                      <Accordion 
                        key={i}
                        id={d.id}
                        bank_name={d.bank_name}
                        active={d.active} 
                        accounts={(d.accounts) ? (d.accounts) : []} 
                        repair={(val) => plaidLinkUpdateMode(val)}
                        action={(val) => actionItem(val)}
                        removeItem={(val) => setPlaidItem(val)}
                      />
                    ))
                  }
                </>
              }
            </Card>
          </div>
        </Row>
      </Container>

      <Modal isOpen={modalDelete} toggle={toggleDelete}>
        <ModalHeader toggle={() => setModalDelete()}> {lang("MESSAGE")} </ModalHeader>
        <ModalBody>
          {lang("ACCOUNT_TXT_ACTION")}	
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModalDelete()}>{lang("CANCEL")}</Button>
          <Button color="danger" 
            onClick={() => accountAction()}> {lang("DEACTIVATE")} </Button>
        </ModalFooter>
      </Modal>
      
      <Modal isOpen={modalItemRemove} toggle={toggleRemove}>
        <ModalHeader toggle={() => setModalItemRemove()}> {lang("MESSAGE")} </ModalHeader>
        <ModalBody>
          {lang("ACCOUNT_REMOVE_ACTION")}	
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleRemove}>{lang("CANCEL")}</Button>
          <Button color="danger" 
            onClick={() => plaidItemRemove(itemRemove)}> {lang("DELETE")} </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default Bank;
