import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

export async function getAllLayerData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.layer, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getInfoLayerData(id) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.layer}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateLayerData(id, name, active, description, color, btnStyle, checkURL, newUrl, coord, message) {
	const token = getToken('GoSuiteToken');

  const jsn = {
    "name": name,
    "active": active,
    "description": description,
    "color": color,
    "btnStyle": JSON.stringify(btnStyle),
		"checkURL": (checkURL === true) ? 1 : 0, 
		"newUrl": (newUrl === '') ? null : newUrl, 
		"coord": (coord === true) ? 1 : 0,
		"message": message
  }

	try {
		const res = await axios.put(`${CONS.layer}/${id}`, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getAllActiveLayerData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.layer}/active`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}
