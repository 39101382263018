import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

export async function getPaymentStatusData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.payment_status, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getPaymentByStatusIdData(id, rows) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.payment_status}/${id}/${rows}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

/**
 * 
 * @param {*} status Payment status 
 * @param {*} rows Rows selected
 * @param {*} page Page number
 * @returns 
 */
export async function getPaginationPaymentData(id, rows, page) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.payment_pagination}/${id}/${rows}/${page}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getPaymentByDate(date) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.payment_by_date}/${date}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getPaymentBySearchData(text) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.payment_by_text}/${text}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getPaymentByIdData(id) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.payment_data}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getPaymentByDateRangeData(init, end) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.post(CONS.payment_date_range, {
			"init": init,
			"end": end
		}, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getReportByStatusId(id) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.report_payment_status}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}