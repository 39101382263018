/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, {useState, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Col, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import Header from "components/Headers/Header";
import NotificationAlert from "react-notification-alert";
import lang from 'lang';
import { getCompanyStateInfoData, updateStateData, deleteStateData } from "api/places";
import { getColor } from "utils/token";

var map, marker;

const EditState = () => {
  const notificationAlertRef = React.useRef(null);
  const {id} = useParams();
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [selState, setSelState] = useState({});
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [active, setActive] = useState('');
  const [zoom, setZoom] = useState('');
  const [modalDelete, setModalDelete] = useState(false);

  useEffect(() => {
    getInfoState();
  },[]);
  
  useEffect(() => {
    initializeMap();
  },[]);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  
  const toggleDelete = () => setModalDelete(!modalDelete);

  const getInfoState = async () => {
    const res = await getCompanyStateInfoData(id);
    setSelState(res.data);
    setLatitude(res.data.Latitude);
    setLongitude(res.data.Longitude);
    setActive(res.data.IsActive);
    setZoom(res.data.Zoom);
    initializeMap(res.data.Latitude, res.data.Longitude, res.data.Zoom);
    setMarkerLatLngDym(res.data.Latitude, res.data.Longitude)
    setLoading(false);
  }

  const initializeMap = async (lat, lng, zm) => {
    const _lat = (lat === undefined) ? 6.873422608947892 : lat;
    const _lng = (lng === undefined) ? -66.34430341114883 : lng;

    try {
      map = new google.maps.Map(document.getElementById('map'), {
        zoom: zm,
        center: new google.maps.LatLng(_lat, _lng),
        zoomControl: true,
        mapTypeControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false
      });
    }
    catch(err) {
      console.log(err);
    }

    google.maps.event.addListener(map, 'click', function(event) {
      setMarkerLatLng(event);
    });

    google.maps.event.addListener(map, 'zoom_changed', function() {
      var z = map.getZoom();
      setZoom(z);
    });
  }

  const setMarkerLatLng = (pos) => {
    if(marker !== undefined) {
      marker.setMap(null);
    }
    
    setLatitude(pos.latLng.lat());
    setLongitude(pos.latLng.lng());

    const latLng = {
      lat: pos.latLng.lat(),
      lng: pos.latLng.lng()
    }

    marker = new google.maps.Marker({
      position: latLng,
      map,
    });
  }

  const setMarkerLatLngDym = (lat, lng) => {
    if(marker !== undefined) {
      marker.setMap(null);
    }
    
    setLatitude(lat);
    setLongitude(lng);

    const latLng = {
      lat: lat,
      lng: lng
    }

    marker = new google.maps.Marker({
      position: latLng,
      map,
    });
  }

  const deleteState = async () => {
    setLoadingBtn(true);
    const res = await deleteStateData(id);
    
    if(res.success === true) {
      setModalDelete(false);
      window.location.href = '/admin/gomap/state';
    }
    else {
      setModalDelete(false);
      notifyAlert("danger", lang("MESSAGE"), res.error.message);
      setModal(true);
    }

    setLoadingBtn(false);
  }

  const updateState = async () => {
    setLoadingBtn(true);
    const res = await updateStateData(id, active, latitude, longitude, zoom);

    if(res.success === true) {
      window.location.href = '/admin/gomap/state';
    }
    else {
      notifyAlert("danger", lang("MESSAGE"), res.error.message);
    }
    setLoadingBtn(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("STATE")} </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <label className="form-control-label"> {lang("MAP")} </label>
                      <div id="map" style={{
                        height: '400px',
                        width: '100%',
                        border: '1px solid #444'
                      }}></div>
                    </Col>

                    {
                      (loading) ? <>
                        <div style={{
                          textAlign: 'center',
                          marginTop: '20px'
                        }}>
                          <Spinner style={{color: getColor(1)}} />
                        </div>
                      </> : <>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("NAME")} </label>
                            <Input
                              disabled={true}
                              readOnly={true}
                              value={selState.Name}
                              className="form-control"/>
                          </FormGroup>

                          <FormGroup>
                            <label className="form-control-label"> {lang("ACTIVE")} </label>
                            <select
                              className="form-control"
                              value={active}
                              onChange={val => setActive(val.target.value)}
                            >
                              <option value="1">{lang("YES")}</option>
                              <option value="0">{lang("NO")}</option>
                            </select>
                          </FormGroup>

                          <FormGroup>
                            <label className="form-control-label"> {lang("LATITUDE")} </label>
                            <Input 
                              readOnly={true}
                              value={latitude}
                              placeholder={lang("LATITUDE")}
                              onChange={(val) => setLatitude(val.target.value)} 
                            />
                          </FormGroup>

                          <FormGroup>
                            <label className="form-control-label"> {lang("LONGITUDE")} </label>
                            <Input 
                              readOnly={true}
                              value={longitude}
                              placeholder={lang("LONGITUDE")} 
                              onChange={(val) => setLongitude(val.target.value)}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label className="form-control-label"> {lang("ZOOM")} </label>
                            <Input
                              value={zoom}
                              readOnly={true}
                              className="form-control"/>
                          </FormGroup>
                        </Col>
                      </>
                    }
                  </Row>
                     
                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loadingBtn) ? <>
                            <Spinner style={{color: getColor(1)}} />
                          </> : 
                          <>
                            <Button color="gray" to="/admin/gomap/state" tag={Link}> {lang("BACK")} </Button>
                            <Button color="danger"
                              onClick={() => toggleDelete()}> {lang("DELETE")} </Button>
                            <Button
                              color="primary" 
                              style={{
                                backgroundColor: getColor(1),
                                color: getColor(2),
                                border: 0
                              }}
                              onClick={() => updateState()}
                              > {lang("SAVE")}
                            </Button>
                          </>
                        }
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>

        <Modal isOpen={modalDelete} toggle={toggleDelete}>
          <ModalHeader toggle={() => setModalDelete()}> {lang("MESSAGE")} </ModalHeader>
          <ModalBody>
            {lang("STATE_TXT_DELETE")}	
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModalDelete()}>{lang("CANCEL")}</Button>
            <Button color="danger"
              onClick={() => deleteState()}> {lang("DELETE")} </Button>
          </ModalFooter>
        </Modal>

      </Container>
    </>
  )
}

export default EditState;