/* eslint-disable-next-line react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, {useState, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import {Card, Container, CardHeader, Row, Button, Col, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Input, Spinner } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import Header from "components/Headers/Header";
import lang from 'lang';
import { getAllCompanyStateData, getCompanyCityInfoData, updateCityData, deleteCityData } from "api/places";
import { getColor, getTokenData } from "utils/token";

var map, marker;

const EditCity = () => {
  const notificationAlertRef = React.useRef(null);
  const [role, setRole] = useState(0);
  const {id} = useParams();
  const [city, setCity] = useState({});
  const [data, setData] = useState([]);
  const [name, setName] = useState('');
  const [selState, setSelState] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [zoom, setZoom] = useState('');
  const [modalDelete, setModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingComp, setLoadingComp] = useState(true);

  useEffect(() => {
    setRole(getTokenData('GoSuiteToken', 'role'));
    getInfoCity();
    getAllState();
  },[]);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  
  const toggleDelete = () => setModalDelete(!modalDelete);

  const getAllState = async () => {
    const res = await getAllCompanyStateData();
    setData(res.data);
  }

  const getInfoCity = async () => {
    const res = await getCompanyCityInfoData(id);

    if(res.success === true) {  
      setCity(res.data);
      setName(res.data.Name);
      setSelState(res.data.State);
      setLatitude(res.data.Latitude);
      setLongitude(res.data.Longitude);
      setZoom(res.data.Zoom);
      initializeMap(res.data.Latitude, res.data.Longitude, res.data.Zoom);
      setMarkerLatLngDym(res.data.Latitude, res.data.Longitude);
    }
    else {
      notifyAlert("danger", lang("MESSAGE"), res.error.message);
    }

    setLoadingComp(false);
  }

  const initializeMap = async (lat, lng, zm) => {
    const _lat = (lat === undefined) ? 6.873422608947892 : lat;
    const _lng = (lng === undefined) ? -66.34430341114883 : lng;

    try {
      map = new google.maps.Map(document.getElementById('map'), {
        zoom: zm,
        center: new google.maps.LatLng(_lat, _lng),
        zoomControl: true,
        mapTypeControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false
      });
    }
    catch(err) {
      console.log(err);
    }

    google.maps.event.addListener(map, 'click', function(event) {
      setMarkerLatLng(event);
    });

    google.maps.event.addListener(map, 'zoom_changed', function() {
      var z = map.getZoom();
      setZoom(z);
    });
  }

  const setMarkerLatLng = (pos) => {
    if(marker !== undefined) {
      marker.setMap(null);
    }
    
    setLatitude(pos.latLng.lat());
    setLongitude(pos.latLng.lng());

    const latLng = {
      lat: pos.latLng.lat(),
      lng: pos.latLng.lng()
    }

    marker = new google.maps.Marker({
      position: latLng,
      map,
    });
  }

  const setMarkerLatLngDym = (lat, lng) => {
    if(marker !== undefined) {
      marker.setMap(null);
    }
    
    setLatitude(lat);
    setLongitude(lng);

    const latLng = {
      lat: lat,
      lng: lng
    }

    marker = new google.maps.Marker({
      position: latLng,
      map,
    });
  }

  const updateCity = async () => {
    setLoading(true);

    if(name.trim() === '') {
      notifyAlert("default", lang("MESSAGE"), lang("CITY_NAME"));
    }
    else if(selState === '') {
      notifyAlert("default", lang("MESSAGE"), lang("STATE_EMPTY"));
    }
    else {
      const res = await updateCityData(id, name, selState, latitude, longitude, zoom);

      if(res.success === true) {
        notifyAlert("success", lang("MESSAGE"), res.data);
        window.location.href = '/admin/gomap/city';
      }
      else {
        notifyAlert("default", lang("MESSAGE"), res.error.message);
      }
    }

    setLoading(false);
  }

  const deleteCity = async () => {
    const res = await deleteCityData(id);
    
    if(res.success === true) {
      setModalDelete(false);
      window.location.href = '/admin/gomap/city';
    }
    else {
      setModalDelete(false);
      setMsg(res.error.message);
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

            <CardHeader className="border-0">
              <Row>
                <div className="col mb-0 float-left">
                  <h3> {lang("CITY")} </h3>
                </div>
              </Row>
            </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <label className="form-control-label"> {lang("MAP")} </label>
                      <div id="map" style={{
                        height: '400px',
                        width: '100%',
                        border: '1px solid #444'
                      }}></div>
                    </Col>

                    <Col lg="6">
                      {
                        (loadingComp) ? <>
                        <div className="text-center">
                          <Spinner style={{color: getColor(1)}} />
                        </div>
                      </> : <>
                        <FormGroup>
                          <label className="form-control-label"> {lang("NAME")} </label>
                          <Input
                            disabled={![1,2,3].includes(role)}
                            value={name}
                            placeholder={lang("NAME")}
                            className="form-control"
                            onChange={val => setName(val.target.value)} />
                        </FormGroup>

                        <FormGroup>
                          <label className="form-control-label"> {lang("SEL_STATE")} </label>
                          <select
                            disabled={![1,2,3].includes(role)}
                            value={selState}
                            placeholder={lang("SEL_STATE")}
                            className="form-control"
                            onChange={val => setSelState(val.target.value)}
                          >
                            <option>{lang("SELECT")}</option>
                            {
                              data.map( (s, i) => (
                                <option 
                                  key={i}
                                  value={s.id}>{s.name}</option>
                              ))
                            }
                          </select>
                        </FormGroup>

                        <FormGroup>
                          <label className="form-control-label"> {lang("LATITUDE")} </label>
                          <Input 
                            readOnly={true}
                            value={latitude}
                            placeholder={lang("LATITUDE")}
                            onChange={(val) => setLatitude(val.target.value)} 
                          />
                        </FormGroup>

                        <FormGroup>
                          <label className="form-control-label"> {lang("LONGITUDE")} </label>
                          <Input 
                            readOnly={true}
                            value={longitude}
                            placeholder={lang("LONGITUDE")} 
                            onChange={(val) => setLongitude(val.target.value)}
                          />
                        </FormGroup>

                        <FormGroup>
                          <label className="form-control-label"> {lang("ZOOM")} </label>
                          <Input 
                            readOnly={true}
                            value={zoom}
                            placeholder={lang("ZOOM")} 
                            onChange={(val) => setLongitude(val.target.value)}
                          />
                        </FormGroup>                      
                      </>
                      }
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loading) ? <> 
                            <Spinner style={{color: getColor(1)}} />
                          </>:
                          <>
                            <Button color="gray" to="/admin/gomap/city" tag={Link}> {lang("BACK")} </Button>  
                          {
                            (Object.keys(city).length === 0) ? <></> :
                            <>
                              {
                                ([1,2,3].includes(role)) ? <>
                                  <Button color="danger"
                                    onClick={() => toggleDelete()}> {lang("DELETE")} </Button>

                                  <Button
                                    color="primary"
                                    style={{
                                      backgroundColor: getColor(1),
                                      color: getColor(2),
                                      border: 0
                                    }}
                                    onClick={() => updateCity()}
                                    > {lang("SAVE")}
                                  </Button>
                                </> : <></>
                              }
                            </>
                          }
                          </>                          
                        }
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
 
          </div>
        </Row>

        <Modal isOpen={modalDelete} toggle={toggleDelete}>
          <ModalHeader toggle={() => setModalDelete()}> {lang( "MESSAGE")} </ModalHeader>
          <ModalBody>
            {lang( "CITY_TXT_DELETE")}	
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModalDelete()}>{lang( "CANCEL")}</Button>
            <Button color="danger" 
              onClick={() => deleteCity()}> {lang( "DELETE")} </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  )
}

export default EditCity;