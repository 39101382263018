/* eslint-disable no-unused-vars */
var BASE_URL;
const URL = window.location.href;
const _local = String(URL).includes('localhost');

if(_local) {
  BASE_URL = "http://localhost:3001/api";
}
else {
  const _dev = String(URL).includes('dev');
  if(_dev) {
    BASE_URL = "https://gosuite-back-dev.azurewebsites.net/api";
  }
  else {
    const qa = String(URL).includes('qa');
    if(qa) {
      BASE_URL = "https://gosuite-back-qa.azurewebsites.net";
    }
    else {
      BASE_URL = "https://gosuite-back.azurewebsites.net/api";
    }
  }
}

export const session                   = `${BASE_URL}/auth/session`;
export const login                     = `${BASE_URL}/auth/login`;
export const password                  = `${BASE_URL}/auth/password`;
export const logout                    = `${BASE_URL}/logout`;

export const allStates                 = `${BASE_URL}/places/all/state`;
export const setStates                 = `${BASE_URL}/places/state`;
export const state                     = `${BASE_URL}/places/state`;
export const city                      = `${BASE_URL}/places/city`;
export const citiesByState             = `${BASE_URL}/places/state/cities`;
export const stateCoords               = `${BASE_URL}/places/state/map/coords`;

export const layer                     = `${BASE_URL}/layer`;
export const services                  = `${BASE_URL}/services`;
export const draw                      = `${BASE_URL}/draw`;

export const brand                     = `${BASE_URL}/brand`;
export const brand_image               = `${BASE_URL}/brand/picture`;

export const map                       = `${BASE_URL}/map`;
export const map_area                  = `${BASE_URL}/map/area`;
export const map_routes                = `${BASE_URL}/map/area/routes`;

export const company                   = `${BASE_URL}/company`;
export const company_email             = `${BASE_URL}/company/email`;
export const company_pass              = `${BASE_URL}/company/password`;
export const company_image             = `${BASE_URL}/company/picture`;
export const company_apikey            = `${BASE_URL}/company/apikey`;
export const company_mapHelp           = `${BASE_URL}/company/map/help`;
export const company_info              = `${BASE_URL}/company/info`;
export const company_collapse          = `${BASE_URL}/company/collapse`;
export const company_url               = `${BASE_URL}/company/url`;
export const company_modules           = `${BASE_URL}/company/modules`;
export const company_billing_system    = `${BASE_URL}/company/billing/id`;
export const company_billing           = `${BASE_URL}/company/billing`;
export const company_user              = `${BASE_URL}/company/user`;
export const custom_field              = `${BASE_URL}/company/custom/field`
export const company_map               = `${BASE_URL}/company/map`;
export const company_payment_type      = `${BASE_URL}/company/payment/type`;
export const company_client            = `${BASE_URL}/company/client`;
export const contractor                = `${BASE_URL}/company/contractor`;
export const gopay_email               = `${BASE_URL}/company/gopay/email`;
export const createLinkToken           = `${BASE_URL}/company/link/token/create`;
export const exchangePublicToken       = `${BASE_URL}/company/item/public_token/exchange`;
export const bank_accounts             = `${BASE_URL}/company/bank/accounts`;
export const gozelle_config            = `${BASE_URL}/company/customize/data`
export const gozelle_image             = `${BASE_URL}/company/customize/picture`
export const updateLinkToken           = `${BASE_URL}/company/link/token/update`;
export const updatePlaidItem           = `${BASE_URL}/company/item/update`
export const removePlaidItem           = `${BASE_URL}/company/item/remove`;
export const accountUpdate             = `${BASE_URL}/company/item/account/update`;
export const gozelle_company_zoho      = `${BASE_URL}/company/gozelle/zoho`;
export const gozelle_company_chargebee = `${BASE_URL}/company/gozelle/chargebee`;

export const notificationsConfig       = `${BASE_URL}/notifications/config`;
export const notificationsUpdate       = `${BASE_URL}/notifications/update`;

export const payment_status            = `${BASE_URL}/payment/status`;
export const payment_by_date           = `${BASE_URL}/payment/find/date`;
export const payment_by_text           = `${BASE_URL}/payment/find/text`;
export const payment_pagination        = `${BASE_URL}/payment/status/pagination`;
export const payment_data              = `${BASE_URL}/payment/data`;
export const payment_date_range        = `${BASE_URL}/payment/find/date/range`;
export const report_payment_status     = `${BASE_URL}/report/payment/status`;

export const companies                 = `${BASE_URL}/admin/companies`;
export const countries                 = `${BASE_URL}/admin/countries`;
export const country_state             = `${BASE_URL}/admin/state`;
export const country_access            = `${BASE_URL}/admin/company/access`;
export const country_billing           = `${BASE_URL}/admin/company/billing`;
export const company_creation          = `${BASE_URL}/admin/company/new`;
export const user_roles                = `${BASE_URL}/admin/users/roles`;
export const register_user             = `${BASE_URL}/admin/users/register`;
export const company_users             = `${BASE_URL}/admin/users`;
export const update_company_user       = `${BASE_URL}/admin/users/update`;

export const get_export_data            = `${BASE_URL}/pause/report`
export const notifications_list         = `${BASE_URL}/pause/notifications/list`;
export const notifications_add          = `${BASE_URL}/pause/notifications/add`;
export const notifications_delete       = `${BASE_URL}/pause/notifications/delete`;
export const dates_list                 = `${BASE_URL}/pause/notifications/dates/list`;
export const dates_add                  = `${BASE_URL}/pause/notifications/dates/add`;
export const dates_delete               = `${BASE_URL}/pause/notifications/dates/delete`
