/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Header from "components/Headers/Header";
import {Spinner, Card, CardHeader, Table, Container, Row, Col, Button, Input, InputGroup, Collapse } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import lang from 'lang';
import { getPaymentStatusData, getPaymentByStatusIdData, getPaymentByDate, 
  getPaymentBySearchData, getPaginationPaymentData, getPaymentByDateRangeData, 
  getReportByStatusId } from 'api/payments';
import { getColor } from "utils/token";
import dayjs from 'dayjs';
import ExportExcel from 'react-export-excel'

const ExcelFile = ExportExcel.ExcelFile;
const ExcelSheet = ExportExcel.ExcelSheet;
const ExcelColumn = ExportExcel.ExcelColumn;

const Payments = () => {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({table: true});
  const [arrStatus, setArrStatus] = useState([]);
  const [status, setStatus] = useState('0');
  const [date, setDate] = useState('');
  const [dateRange, setDateRange] = useState({init: '', end: ''});
  const [data, setData] = useState([]);
  const [rows, setRows] = useState('50');
  const [page, setPage] = useState({page: 1, currentPage: 1, total: 0})
  const [error, setError] = useState(false);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(false);
  const [pdfLoader, setPdfLoader] = useState(false);

  useEffect( () => {
    funct();
  }, []);

  const funct = async () => {
    await getPaymentStatus();
    await getPaymentByStatusId(status, rows);
  }

  const getPaymentStatus = async () => {
    const res = await getPaymentStatusData();
    
    if(res.success) {
      setArrStatus(res.data);
    }
    else {
      setArrStatus([]);
    }
  }

  const getPaymentByStatusId = async (_status, _rows) => {
    setLoading({...loading, table: true});

    const res = await getPaymentByStatusIdData(_status, _rows);
    
    setData(res.data.data);
    setPage({...page, total: Number(res.data.page)})
    setLoading({...loading, table: false});
  }

  const findByDate = async () => {
    setLoading({...loading, table: true});

    console.log(date);
    if(date === '') {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_DATE"));
    }
    else {
      const _date = dayjs(date).format('YYYY-MM-DD');
      console.log(_date);
  
      const res = await getPaymentByDate(_date);

      if(res.success === true) {
        setData(res.data);
      }
      else {
        setData([]);
        notifyAlert("danger", lang("MESSAGE"), res.error);
      }

    }

    setLoading({...loading, table: false});
  }

  const findByStatus = async (id) => {
    setLoading({...loading, table: true});

    console.log(status);
  
    const res = await getPaymentByStatusIdData(id, rows);

    if(res.success === true) {
      setData(res.data.data);
      setStatus(id);
      setPage({...page, total: Number(res.data.page)})
    }
    else {
      setData([]);
      setError(true);
      notifyAlert("danger", lang("MESSAGE"), res.error);
    }

    setLoading({...loading, table: false});
  }

  const searchByText = async () => {
    const txt = String(search).trim();

    if(txt === '') {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_TEXT"));
    }
    else {
      const res = await getPaymentBySearchData(txt);
      console.log(res.data);
      if(res.success === true) {
        setData(res.data);
      }
      else {
        setData([]);
        notifyAlert("danger", lang("MESSAGE"), res.error);
      }
    }
  }

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const getMoreRowsBySelect = async (val) => {
    setLoading(true);
    const res = await getPaymentByStatusIdData(status, val);

    if(res.success === true) {
      setRows(val);
      setData(res.data.data);
      setPage({...page, currentPage: 1, total: Number(res.data.page)});
      setLoading({...loading, table: false});
    }
    else {
      setError(true);
      setData([]);
    }
    setLoading(false);
  }

  const morePage = async () => {
    setLoading(true);
    const cp = page.currentPage;
    const newCp = cp + 1;

    if(cp < newCp) {
      const res = await getPaginationPaymentData(status, rows, newCp);
      setPage({...page, currentPage: newCp});
      setData(res.data);
    }
    setLoading(false);
  }

  const lessPage = async () => {
    setLoading(true);
    const cp = page.currentPage;
    const newCp = cp - 1;

    if(cp > 0) {
      const res = await getPaginationPaymentData(status, rows, newCp)
      setPage({...page, currentPage: newCp});
      setData(res.data);
    }
    setLoading(false);
  }

  const findByDateRange = async () => {
    setLoading({...loading, table: true});

    if(dateRange.init === '' || dateRange.end === '') {
      notifyAlert('warning', lang('MESSAGE'), lang("FILL_DATE"))
    }
    else {
      const res = await getPaymentByDateRangeData(dateRange.init, dateRange.end);
      
      if(res.success === true) {
        setData(res.data);
      }
      else {
        setData([]);
        notifyAlert("danger", lang("MESSAGE"), res.error);
      }
    }

    setLoading({...loading, table: false});
  }

  const pdfReport = async () => {
    setPdfLoader(true);
    const res = await getReportByStatusId(status);

    if(res.success === true) {
      var link = document.createElement('a');
      link.download = `Reporte ${dayjs().format('DD/MM/YYYY')}.pdf`;
      link.href = 'data:application/octet-stream;base64,' + res.data;
      link.click();
    }
    else {
      notifyAlert('danger', lang("MESSAGE"), lang("REPORT_ERROR"));
    }
    setPdfLoader(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("PAYMENTS")} </h3>
                  </div>
                </Row>
              </CardHeader>

              <div className='paddingHorizontal'>
                <ul style={{listStyle: 'none', padding: '0px', display: 'inline-flex', width: '100%', }}>
                  <li style={{width: '100%' }}>
                    <InputGroup>
                      <Input
                        style={{
                          borderRadius: '0.25rem'
                        }}
                        value={search}
                        type="search"
                        placeholder={lang("SEARCH_PAYMENT")}
                        onChange={(val) =>  setSearch(val.target.value)}
                      />
                      <Button 
                        style={{
                          backgroundColor: getColor(1),
                          color: getColor(2),
                          border: 0,
                          marginInline: '2px',
                          marginTop: '2px'
                        }}
                        onClick={() => searchByText()}>{lang("SEARCH")}</Button>
                    </InputGroup>
                  </li>
                  <li style={{width: '30%'}}>
                    <select
                      value={status} 
                      className="form-control" style={{width: '100%' }}
                      onChange={(val) => findByStatus(val.target.value)}>
                        <option value="0">{lang("ALL_STATUS")}</option>
                      {
                        arrStatus.map( (s, i) => (
                          <option key={i} value={s.id}>{s.status}</option>
                        ))
                      }
                    </select>
                  </li>
                  <li>
                    {
                      (pdfLoader) ? <>
                        <div style={{
                          marginTop: '8px',
                          marginLeft: '15px'
                        }}>
                          <Spinner size="sm" style={{color: getColor(1)}} />
                        </div>
                      </> : 
                      <>
                        <i
                          style={{
                            marginTop: '15px',
                            marginLeft: '15px',
                            marginRight: "10px",
                            color: '#F40F02',
                            cursor: 'pointer'
                          }} 
                          className="far fa-file-pdf" 
                          title='Make a report'
                          onClick={() => pdfReport()}/>
                      </>
                    }
                  </li>
                  <li>
                    <ExcelFile element={<i style={{marginTop: '15px', marginLeft: '15px', marginRight: "10px", color: '#1D6F42', cursor: 'pointer' }} className="far fa-file-excel"/> } 
                      filename="Reporte Excel">
                      <ExcelSheet data={data} name="Pagos" >
                        <ExcelColumn label="Nombre" value="name" />
                        <ExcelColumn label="E-mail" value="email" />
                        <ExcelColumn label="ID De Pago" value="tracking" />
                        <ExcelColumn label="Monto" value="amount" />
                        <ExcelColumn label="Estado" value="status_name" />
                        <ExcelColumn label="Referencia Zelle" value="zelle_ref_number" />
                        <ExcelColumn label="Fecha" value="parse_date" />
                      </ExcelSheet>
                    </ExcelFile>
                  </li>
                  <li>
                    <i
                      style={{
                        marginTop: '15px',
                        marginLeft: '15px',
                        marginRight: "10px"
                      }} 
                      className="fas fa-filter"  onClick={() => setFilter(!filter)}/>
                  </li>
                </ul>
                <Collapse isOpen={filter}>
                  <Container>
                    <Row>
                      <Col md="6">
                        <div>
                          <span> {lang("SPECIFIC_DATE")}</span>
                          <Input
                            type="date"
                            value={date}
                            onChange={(val) =>  setDate(val.target.value)}
                          />
                          <div className="text-right">
                            <Button style={{
                              backgroundColor: getColor(1),
                              color: getColor(2),
                              border: 0,
                              marginTop: '5px'
                            }}
                            onClick={() => findByDate()}>{lang("SEARCH")}</Button>
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div>
                          <span> {lang("DATE_RANGE")}</span>
                          <div style={{display: 'flex'}}>
                            <Input
                              type="date"
                              style={{width: '50%'}}
                              value={dateRange.init}
                              onChange={(val) => setDateRange({...dateRange, init: val.target.value})}
                            />
                            <Input
                              type="date"
                              style={{width: '50%'}}
                              value={dateRange.end}
                              onChange={(val) => setDateRange({...dateRange, end: val.target.value})}
                            />
                          </div>
                          <div className="text-right">
                            <Button style={{
                              backgroundColor: getColor(1),
                              color: getColor(2),
                              border: 0,
                              marginTop: '5px'
                            }}
                            onClick={() => findByDateRange()}>{lang("SEARCH")}</Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    
                  </Container>
                </Collapse>
              </div>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col"> {lang("NAME")} </th>
                    <th scope="col"> {lang("EMAIL")} </th>
                    <th scope="col"> {lang("PAYMENT_ID")} </th>
                    <th scope="col"> {lang("AMOUNT")} </th>
                    <th scope="col"> {lang("STATUS")} </th>
                    <th scope="col"> {lang("ZELLE_REFERENCE")} </th>
                    <th scope="col"> {lang("DATE")} </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {
                    (loading.table) ?
                    <tr>
                      <td colSpan={10} className='text-center'>
                        <Spinner style={{color: getColor(1)}} />
                      </td>
                    </tr>:
                    (error) ? 
                    <tr>
                      <td colSpan={10} className='text-muted text-center'>
                        <label>{lang("ERROR_LOAD")}</label>
                        <br />
                        <Button 
                          color="secondary"
                          onClick={() => getPaymentByStatusId()}>{lang("LOAD")}</Button>
                      </td>
                    </tr>:
                    (data.length === 0) ? 
                    <tr>
                      <td colSpan={10} className='text-muted text-center'> {lang("EMPTY_PAYMENT")} </td>
                    </tr>
                    :
                    data.map( (d,i) => (
                      <tr key={i}>
                        <td>
                          {d.name}
                        </td>
                        <td>
                          {d.email}
                        </td>
                        <td>
                          {d.tracking}
                        </td>
                        <td>
                          ${d.amount}
                        </td>
                        <td>
                          {d.status_name}
                        </td>
                        <td>
                          {d.zelle_ref_number}
                        </td>
                        <td>
                          {dayjs(d.date).add(1, 'day').format('DD-MM-YYYY')}
                        </td>
                        <td className="text-right">
                          <Button color="secondary" 
                            to={`/admin/gozelle/payments/${d.id}`} 
                            tag={Link}
                          > {lang("VIEW_DETAILS")} </Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              
              {
                (page.total === 0) ? <> </> : 
                <>
                  <div style={{
                    display: 'inline-flex',
                    justifyContent: 'space-between',
                    marginTop: '15px'
                  }}>
                    <div style={{
                      marginLeft: '10px'
                    }}>
                      <select 
                        className="form-control"
                        value={rows}
                        onChange={(val) => getMoreRowsBySelect(val.target.value)} >
                        <option values="50"> 50 </option>
                        <option values="150"> 150 </option>
                        <option values="350"> 350 </option>
                        <option values="500"> 500 </option>
                      </select>
                    </div>

                    <div style={{
                      marginRight: '10px'
                    }}>
                      <ul id="pagination">
                        <li>
                          {
                            (page.currentPage === 1) ? <></> : <>
                              <i 
                                className="fas fa-chevron-left paginationBtn" 
                                onClick={() => lessPage()}/>
                            </>
                          }
                        </li>
                        <li style={{marginLeft: '10px', marginRight: '10px'}}>{`${page.currentPage} / ${page.total}`}</li>
                        <li>
                          {
                            (page.currentPage === page.total) ? <> </> : <>
                              <i 
                                className="fas fa-chevron-right paginationBtn"
                                onClick={() => morePage()} />
                            </>
                          }
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              }
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default Payments;