/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { Link, useParams } from "react-router-dom";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "components/Headers/Header";
import NotificationAlert from "react-notification-alert";
import lang from 'lang';
import { getDrawTypeByIdData, updDrawTypeData, deleteDrawTypeData } from "api/draw";
import { getColor, getTokenData } from "utils/token";

const EditDraw = () => {
  const notificationAlertRef = React.useRef(null);
  const {id} = useParams();
  const [role, setRole] = useState(0);
  const [name, setName] = useState('');
  const [active, setActive] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState({page: true, btn: false});
  const [modalDelete, setModalDelete] = useState(false);

  useEffect( () => {
    setRole(getTokenData('GoSuiteToken', 'role'));
    getDraw();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const getDraw = async () => {
    const res = await getDrawTypeByIdData(id);
    setName(res.data.name)
    setActive(res.data.active)
    setDescription(res.data.description)
    setLoading({...loading, page: false })
  }

  const toggleDelete = () => setModalDelete(!modalDelete);

  const deleteDraw = async () => {
    setLoading({...loading, btn: true });
    const res = await deleteDrawTypeData(id);
    
    if(res.success === true) {
      setModalDelete(false);
      window.location.href = '/admin/gomap/draw';
    }
    else {
      setModalDelete(false);
      notifyAlert("danger", lang("MESSAGE"), res.error.message);
    }
    setLoading({...loading, btn: false });
  }

  const updateDraw = async () => {
    setLoading({...loading, btn: true });

    const _name = name.trim();

    if(_name === "") {
      notifyAlert("danger", lang("MESSAGE"), lang("EMPTY_NAME_DRAW"));
    }
    else {
      const jsn = {
        "name": _name, 
        "description": description, 
        "active": active  
      }

      const res = await updDrawTypeData(id, jsn);

      if(res.success === true) {
        notifyAlert("success", lang("MESSAGE"), lang(res.data));
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
    }
    setLoading({...loading, btn: false });
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("DRAW_TYPE")} </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <div>
                  {
                    (loading.page) ? <div className="text-center">
                      <Spinner style={{
                        color: getColor(1)
                      }} />
                    </div> :
                    <>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("NAME")} </label>
                            <Input
                              disabled={![1,2].includes(role)}
                              value={name}
                              className="form-control"
                              onChange={val => setName(val.target.value)}/>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("ACTIVE")} </label>
                            <select
                              disabled={![1,2].includes(role)}
                              className="form-control"
                              value={active}
                              onChange={val => setActive(val.target.value)}>
                              <option value="1">{lang("YES")}</option>
                              <option value="0">{lang("NO")}</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label"> {lang("DESCRIPTION")}{' '}{lang("OPTIONAL")} </label>
                            <textarea
                              disabled={![1,2].includes(role)}
                              id='txtArea'
                              className="form-control"
                              value={description}
                              onChange={val => setDescription(val.target.value)}>
                            </textarea>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col lg="12" >
                          <br />
                          <div className="float-right">
                            {
                              (loading.btn) ? <div>
                                <Spinner style={{
                                  color: getColor(1)
                                }} />
                              </div> :
                              <>
                                <Button color="gray" to="/admin/gomap/draw" tag={Link}> {lang("BACK")} </Button>
                                {
                                  ([1,2].includes(role)) ? <>
                                    <Button color="danger"
                                      onClick={() => toggleDelete()}> {lang("DELETE")} </Button>
                                    <Button
                                      style={{
                                        backgroundColor: getColor(1),
                                        color: getColor(2),
                                        border: 0
                                      }}
                                      onClick={() => updateDraw()}
                                      > {lang("SAVE")}
                                    </Button>                                  
                                  </>:<></>
                                }
                              </>
                            }
                          </div>
                        </Col>
                      </Row>
                    </>
                  }
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>     

        <Modal isOpen={modalDelete} toggle={toggleDelete}>
          <ModalHeader toggle={() => setModalDelete()}> {lang("MESSAGE")} </ModalHeader>
          <ModalBody>
            {lang("DRAW_TXT_DELETE")}	
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModalDelete()}>{lang("CANCEL")}</Button>
            <Button color="danger" 
              onClick={() => deleteDraw()}> {lang("DELETE")} </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  )
}

export default EditDraw;