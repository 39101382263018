import jwt_decode from "jwt-decode";

export function getToken(index) {
	return window.sessionStorage.getItem(index);
}

export function setToken(index,value) {
	window.sessionStorage.setItem(index,value);
}

export function deleteToken(index){
	window.sessionStorage.removeItem(index);	
}

export function getColor(num) {
	if(num === 1) {
		const token = jwt_decode(window.sessionStorage.getItem('GoSuiteToken'));
		return token.color;
	}
	else if(num === 2) {
		const token = jwt_decode(window.sessionStorage.getItem('GoSuiteToken'));
		return token.sColor;
	}
}
//#1171EF

/**
 * 
 * @param {*} token Key token name
 * @param {*} value Value to return from token
 * @returns 
 */
export function getTokenData(token, value) {
	const _token = jwt_decode(window.sessionStorage.getItem(token));
	return _token[value];
}