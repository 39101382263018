import jwt_decode from "jwt-decode";

const spanish = {
  EMPTY_INIT: '',
  DASHBOARD: 'Tablero (beta)',
  DASHBOARD2: 'Tablero de suscriptores (beta)',
  METRICS: 'Métricas',
  STATE: 'Estados',
  CITY: 'Ciudades',
  CITY_ONE: 'Ciudad',
  SUBSCRIBERS: 'Suscriptores',
  MAP: 'Mapa',
  SETTINGS: 'Configuraciones',
  LOGOUT: 'Cerrar sesión',
  SERVICES: 'Servicios',
  LAYERS: 'Capas',
  BRANDING: 'Identidad',
  DRAW_TYPE: 'Tipos de dibujo',
  NEW_CITY: 'Nueva ciudad',
  NAME: 'Nombre',
  LASTNAME: 'Apellido',
  BACK: 'Regresar',
  SAVE: 'Guardar',
  ACTIVE: 'Activo',
  SEARCH: 'Buscar',
  SEL_STATE: 'Seleccionar estado',
  SELECT: 'Seleccionar',
  YES: 'Si',
  NO: 'No',
  ACTIVE_MSG: 'En caso de no seleccionar una opción quedará ACTIVO',
  MESSAGE: 'Mensaje',
  NO_STATE_SEL: 'Todavía no ha seleccionado ningún estado',
  NO_CITY_SEL: 'Todavía no ha seleccionado ningún ciudad',
  NO_BANK_SEL: 'Todavía no ha registrado una cuenta bancaria.',
  NO_SERVICES_SEL: "Todavía no ha seleccionado ningún servicio",
  NO_INFO_YET: "Todavía no posee información registrada",
  LOAD: 'Cargar',
  LATITUDE: 'Latitud',
  LONGITUDE: 'Longitud',
  CITY_MSG: 'En caso de no agregar las coordenadas, se agregarán la latitud y longitud predeterminadas del estado',
  DELETE: 'Eliminar',
  CITY_TXT_DELETE: '¿Estás seguro de borrar la ciudad?',
  CANCEL: 'Cancelar',
  COLOR_LAYER: 'Color de la capa',
  DESCRIPTION: 'Descripción',
  BUTTON_STYLE: 'Estilo del botón',
  BORDER: 'Borde',
  BACKGROUND_COLOR: 'Color de fondo',
  FONT_COLOR: 'Color del texto',
  TEXT_BUTTON: 'Texto del botón',
  BORDER_RADIUS: 'Radio de borde',
  CODE: 'Código',
  SERVICES_TXT_DELETE: '¿Estás seguro de borrar el servicio?',
  STATE_TXT_DELETE: '¿Estás seguro de borrar el estado?',
  DRAW_POINT: 'Dibujar punto',
  DRAW_LINE: 'Dibujar líneas',
  DRAW_POLYGON: 'Dibujar polígono',
  DRAW_CIRCLE: 'Dibujar círculo',
  DELETE_DRAW: 'Eliminar área',
  SERVICES_TYPE: 'Servicio',
  GO: "Ir",
  AREAS: 'Áreas',
  COLOR:'Color',
  SHOW_SHAPE: 'Ver áreas',
  EDIT: 'Editar',
  ZOOM: 'Zoom',
  CONTINUE: 'Continuar',
  AREA_DELETE: '¿Estás seguro de borrar el área?',
  SEARCH_BY_NAME_CODE: 'Buscar por nombre, código, estado o ciudad',
  LANGUAGES: 'Idiomas',
  ENGLISH: 'English',
  SPANISH: 'Español',
  EMAIL: 'Correo electrónico',
  PASSWORD: 'Contraseña',
  ENTER_PASSWORD: 'Ingrese su contraseña',
  TEST: 'Prueba',
  PRODUCTION: 'Producción',
  MAP_MODAL_MARKER: 'Modal marker',
  TITLE: 'Título',
  PROFILE: 'Perfil',
  MAP_HELP: 'Ayuda del mapa',
  INTEGRATION: 'Integración',
  OLD_PASSWORD: 'Contraseña actual',
  NEW_PASSWORD: 'Nueva contraseña',
  REPEAT_PASSWORD: 'Repita contraseña',
  POSITION_CHECK: 'Texto de confirmación de posición',
  LAYER: 'Capa',
  SECURITY: 'Seguridad',
  DRAW_TXT_DELETE: '¿Estás seguro de borrar el tipo de dibujo?',
  INFO_TITLE: 'Tu ubicación seleccionada',
  INFO_MESSAGE: 'Ingrese su mensaje',
  BUSINESS_UNIT: 'Unidad de negocio',
  NEW_ROUTE: 'URL personalizada',
  SEND_COORD: 'Enviar coordenadas',
  INTEGRATION_ROUTE: 'Utilizar integración',
  BUTTON_ACTION: 'Acción del botón',
  FONT_SIZE: 'Tamaño de la fuente',
  PADDING: 'Relleno',
  OPTIONAL: "(Opcional)",
  TEXT_COLLAPSE: 'Navegación por zonas',
  COLLAPSE_SHOW: 'Texto para mostrar',
  COLLAPSE_HIDE: 'Texto para ocultar',
  COPY: `Copiar`,
  PERSONALIZATION: 'Personalización',
  PLAN: 'ID del Plan',
  QUANTITY_DAYS: 'Días de instalación',
  TEXT: 'Texto del botón',
  URL_VERSION: 'Versión del catálogo de producto de GoPay',
  SERVICE: 'Servicio',
  GEN_SUB_BTN: 'Generador del botón de subscripción',
  MAIN_COLOR: 'Color principal',
  TEXT_COLOR: 'Color del texto',
  NEW_LAYER: 'Nueva capa',
  NEW_STATE: 'Nuevo estado',
  NEW_MAP: 'Nueva área',
  RELOAD: 'Recargar',
  NEW_SERVICES: 'Nuevo servicio',
  EMAIL_INVALID: "No es un correo electrónico válido",
  STATE_EMPTY: "Debe seleccionar un estado",
  ERROR_LOAD: 'Se ha presentado un error, por favor intente cargar nuevamente',
  CITY_NAME: 'Debe agregar el nombre de la ciudad',
  LATITUDE_NOVALID: 'La latitud no es correcta',
  LONGITUDE_NOVALID: 'La longitud no es correcta',
  LATITUDE_EMPTY: 'La latitud está vacía',
  LONGITUDE_EMPTY: 'La longitud está vacía',
  EMPTY_NAME_LAYER: 'El nombre de la capa está vacía',
  EMPTY_COLOR_LAYER: 'El color de la capa está vacío',
  BTN_EMPTY_TEXT: 'El texto del botón está vacío',
  BTN_EMPTY_BORDER: 'El borde del botón está vacío',
  BTN_EMPTY_RADIUS_BORDER: 'El radio de borde está vacío',
  BTN_EMPTY_BACKGROUND_COLOR: 'El color del fondo está vacío',
  BTN_EMPTY_FONT_COLOR: 'El color del texto está vacío',
  EMPTY_NAME_SERVICES: 'El nombre del servicio está vacío',
  EMPTY_CODE_SERVICES: 'El código del servicio está vacío',
  EMPTY_NAME_MAP: 'El nombre está vacío',
  EMPTY_STATE_MAP: 'Debes seleccionar un estado',
  EMPTY_CITY_MAP: 'Debes seleccionar una ciudad',
  EMPTY_SERVICES_MAP: 'Debes seleccionar un servicio',
  EMPTY_LAYER_MAP: 'Debes seleccionar una capa',
  EMPTY_DRAW_TYPE_MAP: 'Debes seleccionar un tipo de dibujo',
  EMPTY_POLYGONS_MAP: 'Debes realizar tu dibujo o marcar en un punto en el mapa',
  EMPTY_AREAS: "No hay registro de áreas",
  EMPTY_COLOR: 'El color está vacío',
  EMPTY_NAME: 'El nombre está vacío',
  WRONG_EMAIL: 'El correo electrónico no es válido',
  EMAIL_UPDATE: 'Su correo electrónico ha sido actualizado',
  PASSWORD_NO_EQUAL: "Las contraseñas no son iguales",
  OLD_PASSWORD_EMPTY: 'La contraseña actual está vacía',
  NEW_PASSWORD_EMPTY: 'La contraseña nueva está vacía',
  REPEAT_PASSWORD_EMPTY: 'Debe repetir su contraseña',
  PASSWORD_ALREADY_USE: "La contraseña nueva es igual a la anterior",
  AP_TEST_EMPTY: 'La API Key SANDBOX está vacía',
  AP_PROD_EMPTY: 'La API Key LIVE está vacía',
  TITLE_EMPTY: 'El título está vacío',
  MESSAGE_EMPTY: 'El mensaje está vacío',
  MAP_BRAND_UPDATE: 'La identidad ha sido actualizada',
  EMPTY_NAME_DRAW: 'El nombre del tipo de dibujo está vacío',
  EMPTY_TITLE: 'El título esta vacío',
  EMPTY_CONFIRM_TEXT: 'El texto de confirmación esta vacío',
  COPY_CLIPBOARD: 'Se ha copiado el script',
  ATAG_SERVICES: 'Debe seleccionar servicios',
  ATAG_PLAN: 'Debe agregar el plan',
  ATAG_TEXT: 'Debe agregar el texto',
  URL_VERSION_CHOOSE: 'Debe seleccionar versión de la URL',
  EMPTY_IMAGE: 'Debe cargar una nueva imagen',
  CHOOSE_LAYER_URL: "Debe seleccionar una URL",
  NEW_DRAW_TYPE: 'Nuevo tipo de dibujo',
  TITLE_UPDATE: "El título has sido actualizado",
  UPDATE_EMAIL_REQUEST_TEXT: 'Para solicitar el cambio de correo electrónico comuniquese con support@equaldata.com',
  BANK_ACCOUNT: 'Cuentas bancarias',
  NEW_BANK_ACCOUNT: 'Nueva cuenta',
  EMPTY_BANK_DATA: 'No posee cuentas registradas',
  LOGO_APP: 'Logo de la app',
  WINDOW_TITLE: 'Título de la app',
  EMAIL_LABEL: 'Etiqueta de correo electrónico',
  FETCH_BUTTON_TEXT: 'Texto del botón de validación',
  FETCH_BUTTON_COLOR: 'Color del botón de validación',
  FETCH_BUTTON_COLOR_FONT: 'Color de la fuente en botón de validación',
  WARNING_MESSAGE: 'Mensaje de error',
  INVOICE_TITLE: 'Título de las facturas',
  TOTAL_LABEL: 'Etiqueta total',
  ZELLE_INSTRUCTIONS: 'Instrucciones de zelle',
  EMAIL_ZELLE: 'Correo electrónico para zelle',
  EMAIL_ZELLE_LABEL: "Etiqueta de referencia zelle",
  ZELLE_INFO_MESSAGE: 'Mensaje de información',
  SUBMIT_BUTTON_TEXT: 'Texto del botón de envío',
  SUBMIT_BUTTON_COLOR: 'Color del botón de envío',
  SUBMIT_BUTTON_COLOR_FONT: 'Color de la fuente en botón de envío',
  SUCCESS_ICON: 'Icono de éxito',
  SUCCESS_MESSAGE1: 'Primer texto',
  SUCCESS_MESSAGE2: 'Segundo texto',
  SUCCESS_MESSAGE3: 'Tercer texto',
  WAIT_TIME: "No hemos detectado actividad y su sesión finalizará en 5 minutos.",
  DETAIL: "Detalle",
  ACCOUNT_TXT_ACTION:'¿Estás seguro en desactivar la verificación zelle?',
  ACTIVATE: 'Activar',
  DESACTIVATE: 'Desactivar',
  BANK_NAME: 'Nombre del banco',
  ACCOUNT_NUMBER: 'Número de cuenta',
  ACCOUNT_OFFICIALNAME: "Nombre oficial de la cuenta",
  ACCOUNT_NAME: "Nombre de la cuenta",
  FIX_ACCOUNT: 'Reparar Conexión',
  LOST_ACCOUNT: 'Las credenciales de acceso a este banco han dejado de funcionar',
  ZELLE_VERIFICATION: 'Verificación Zelle',
  ACTIVE_ACCOUNT: 'Verificación zelle activa',
  DELETE_ACCOUNT: 'Eliminar cuenta',
  ACCOUNT_REMOVE_ACTION: '¿Estás seguro en eliminar la cuenta bancaria?',
  PAYMENTS: 'Pagos',
  DEACTIVATE: 'Desactivar',
  ZELLE_REFERENCE: 'Referencia Zelle',
  AMOUNT: "Monto",
  EMPTY_DATE: 'Debe seleccionar una fecha',
  DATE: 'Fecha',
  EMPTY_PAYMENT: 'Todavia no tiene ningún pago registrado',
  DOMAIN: 'Dominio',
  IS_TAXABLE: 'Producto gravable',
  ROL_TYPE: 'Tipo de rol',
  PLAID_DELETED_ITEM: 'La cuenta ha sido eliminada',
  PLAID_PROCESSING_ITEM_CREATION: 'Estamos procesando su solicitud, espere por favor.',
  EMAIL_NOTIFICATIONS_CONFIG: 'Notificaciones de Email',
  EMAIL_NOTIFICATIONS_TITLE: 'Configuración general para las notificaciones de email de GoZelle',
  SUPPORT_EMAIL: "Email de Soporte",
  PAYMENT_ID: 'Id de Pago',
  STATUS: "Estado",
  EMPTY_TEXT: 'El campo esta vacio',
  INVOICES: 'Facturas',
  FILTER: 'Filtro',
  DATE_RANGE: 'Rango de fecha',
  SEARCH_PAYMENT: 'Buscar por Nombre, Correo Electrónico, Id de Pago, Referencia Zelle',
  FILL_DATE: 'Debe llenar ambos campos de fecha',
  MESSAGE_NEW_TEXT: 'Texto para nuevo pago realizado',
  MESSAGE_PROCESS_TEXT: 'Texto para pago en proceso',
  MESSAGE_APPROVE_TEXT: 'Texto para pago aprobado',
  MESSAGE_DECLINED_TEXT: 'Texto para pago rechazado',
  SPECIFIC_DATE: 'Fecha específica',
  ENTERPRISE: "Empresas",
  USERS: "Usuarios",
  NEW_ENTERPRISE: 'Nueva empresa',
  SEL_COUNTRY: "Pais",
  SEL_HOUR: "Zona horaria",
  SEL_STATE_COMPANY: 'Estados',
  SEL_COUNTRY_EMPTY: "Debe seleccionar pais",
  SEL_HOUR_EMPTY: "Debe seleccionar zona horaria",
  SEL_LANGUAGE: "Debe seleccionar un idioma",
  SEL_BILLING: "Sistema de subscripción",
  SEL_BILLING_EMPTY: "Debe seleccionar tipo de pago",
  ACCESS_COMPANY: "Accesos de la compañia",
  ACCESS_COMPANY_EMPTY: "Debe seleccionar el acceso de la compañia",
  PUBLIC_NAME: 'Identificador URL',
  PUBLIC_NAME_EMPTY: 'El identificador URL esta vacio',
  COMPANY_LOGO: 'Logo de la compañia',
  NEW_USER: 'Nuevo usuario',
  USERNAME: "Nombre y apellido",
  NEW_USERNAME: 'Ingrese nombre y apellido',
  USER_PICTURE: 'Imagen del usuario',
  NO_USERS_REGISTERED: 'No hay usuarios registrados',
  USER: 'Usuario',
  ACCEPT: 'Aceptar',
  ROLE: 'Rol',
  ADD_EMAIL: 'Agregar correo electrónico',
  EMAIL_ALREADY_ADDED: 'El correo electrónico ya esta registrado',
  EMPTY_FIELDS: 'Algun campo esta vacio',
  ZELLE_RECIPIENT_NAME: 'Titular de la cuenta zelle',
  GOZELLE_UPDATE: 'La información ha sido actualizada',
  COMPANY_NAME: 'Nombre de la compañia',
  VIEW_DETAILS: 'Ver detalles',
  CONFIRMATION_SCREEN: 'Pantallas de confirmacion',
  TAXES_SETUP_DATA: 'Datos de la configuración de impuestos',
  ORG_ID: 'ID de la organización',
  TAX_ID: 'ID de impuestos',
  TAX_AUTHORITY_ID: 'ID de la autoridad recaudadora de impuestos',
  TAX_AUTHORITY_NAME: ' Nombre de la autoridad recaudadora de impuestos',
  TAX_EXEMPTION_ID: 'ID de excepción de impuestos',
  TAX_EXEMPTION_CODE: 'Código de excepción de impuestos',
  ZELLE_ACCOUNT_DATA: 'Cuentas zelle',
  HOLDER: 'Titular',
  ADD_HOLDER: 'Agregar titular',
  CUSTOM_FIELDS: 'Campos personalizados',
  FIELD_TYPE: 'Tipo de campo',
  FIELD_VALUES: 'Valores',
  NEW_FIELD: 'Nuevo campo personalizado',
  IS_MANDATORY: 'Obligatorio',
  API_FIELD_NAME: 'Campo nombre API',
  LABEL_NAME: 'Etiqueta',
  FIELD_TYPE_NAME: 'Texto',
  FIELD_TYPE_SELECT: 'Lista de selección',
  FIELD_LIST_VALUES: 'Valores de la lista',
  LABEL_NAME_EMPTY: 'La etiqueta esta vacia',
  API_FIELD_NAME_EMPTY: 'El campo nombre API esta vacio',
  FIELD_LIST_VALUES_EMPTY: 'Debe agregar al menos un valor',
  VALUE_LIST: 'Valor',
  TEXT_LIST: 'Texto',
  ENTITY: "Entidad",
  CLIENT: 'Cliente',
  SUBSCRIPTION: 'Suscripción',
  CUSTOMER_TYPE: 'Tipo de cliente',
  BUSINESS: 'Empresa',
  INDIVIDUAL: 'Individual',
  SELECT_CUSTOMER_TYPE: 'Debe seleccionar el tipo de cliente',
  MAP_ZH_ERROR: 'No posee un mapa configurado',
  ALL_STATUS: 'Todos',
  REPORT_ERROR: 'Se ha presentado un error en la creacion del reporte',
  NEW_CLIENT: 'Nuevo cliente',
  FULLNAME: 'Nombre completo',
  PHONE: 'Telefono',
  CARD_RIF: 'Cedula/RIF',
  IS_CLIENTE: '¿Cliente nuevo?',
  PAYMENT_TYPE: 'Tipo de pago',
  PAYMENT_DATE: 'Fecha de pago',
  OBSERVATION: 'Observaciones',
  REGISTERED: 'Registrado',
  ADD_USER_ZOHO: 'Agregar cliente a GoPay',
  FISCAL_ADDRESS: 'Dirección fiscal',
  INSTALATION_ADDRESS: 'Dirección de instalación',
  SUBJECT: 'Asunto',
  SEND: 'Enviar',
  CONTRACTOR: 'Contratista',
  NEW_CONTRACTOR: 'Nueva contratista',
  CONTACT_NAME: 'Nombre de contacto',
  COMMENTS: 'Comentarios',
  ADDRESS: 'Dirección',
  PROGRAMMING_PAUSE: 'Cortes programados',
  REPORTS: 'Reportes',
  INIT_DATE: 'Fecha inicial',
  END_DATE: 'Fecha final',
  GENERATE: 'Generar',
  LIST: 'Listado',
  HOLIDAYS: 'Días de feriados',
  WEEKEND: 'Fin de semana',
  EMAIL_TEXT_NOTIFICATION: 'Indique un email para recibir los reportes diarios',
  ENTER_NOTIFICATIONS_DATA: 'Ingrese la información solicitada para recibir los reportes',
  ENTER_NAME: 'Ingrese su nombre completo por favor',
  ENTER_EMAIL: 'Ingrese su correo electrónico por favor',
  ENTER_DATES: 'Asegúrese de haber ingresado la fecha inicial y final para el informe',
  INVALID_PERIOD_REPORT: 'La fecha final no puede ser una fecha más antigua que la inicial',
  ENTER_DATES_DATA: 'Ingrese la información solicitada para registrar fechas de excepción a cortes',
  ENTER_DAY_NAME: 'Por favor, ingrese un nombre para la fecha',
  ENTER_DATE: 'Por favor ingrese la fecha',
  REQUEST_DATA: 'Solicitar Información',
}

const english = {
  EMPTY_INIT: '',
  DASHBOARD: 'Dashboard (beta)',
  DASHBOARD2: 'Subscribers dashboard (beta)',
  METRICS: 'Metrics',
  STATE: 'State',
  CITY: 'City',
  SUBSCRIBERS: 'Subscribers',
  MAP: 'Map',
  SETTINGS: 'Settings',
  LOGOUT: 'Logout',
  SERVICES: 'Services',
  LAYERS: 'Layers',
  BRANDING: 'Branding',
  DRAW_TYPE: 'Shape type',
  NEW_CITY: 'New city',
  NAME: 'Name',
  LASTNAME: 'Lastname',
  BACK: 'Back',
  SAVE: 'Save',
  ACTIVE: 'Active',
  SEARCH: 'Search',
  SEL_STATE: 'Select state',
  SELECT: 'Select',
  YES: 'Yes',
  NO: 'No',
  ACTIVE_MSG: 'If you do not select any option, it will be ACTIVE',
  MESSAGE: 'Message',
  NO_STATE_SEL: 'You do not have any state yet',
  NO_CITY_SEL: 'You do not have any city yet',
  NO_BANK_SEL: 'You do not have any bank register yet',
  NO_SERVICES_SEL: "You do not have any services yet",
  NO_INFO_YET: "You do not have any record registered",
  LOAD: 'Load',
  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',
  CITY_MSG: 'If coordinates are not added, the default ones are added',
  DELETE: 'Delete',
  CITY_TXT_DELETE: 'Are you sure to delete the city?',
  STATE_TXT_DELETE: 'Are you sure to delete the state?',
  CANCEL: 'Cancel',
  COLOR_LAYER: 'Layer color',
  DESCRIPTION: 'Description',
  BUTTON_STYLE: 'Button style',
  BORDER: 'Border',
  BACKGROUND_COLOR: 'Background color',
  FONT_COLOR: 'Font color',
  TEXT_BUTTON: 'Button text',
  BORDER_RADIUS: 'Border radius',
  CODE: 'Code',
  SERVICES_TXT_DELETE: 'Are you sure to delete the services?',
  DRAW_POINT: 'Draw point',
  DRAW_LINE: 'Draw lines',
  DRAW_POLYGON: 'Draw polygon',
  DRAW_CIRCLE: 'Draw Circle',
  DELETE_DRAW: 'Delete drawing',
  SERVICES_TYPE: 'Service',
  GO: "Go",
  AREAS: 'Areas',
  COLOR:'Color',
  SHOW_SHAPE: 'Show shapes',
  EDIT: 'Edit',
  ZOOM: 'Zoom',
  CONTINUE: 'Continue',
  AREA_DELETE: 'Are you sure to delete the map area?',
  SEARCH_BY_NAME_CODE: 'Search by name, code, state or city',
  LANGUAGES: 'Languages',
  ENGLISH: 'English',
  SPANISH: 'Español',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  ENTER_PASSWORD: 'Enter your password',
  TEST: 'Test',
  PRODUCTION: 'Production',
  MAP_MODAL_MARKER: 'Map modal marker',
  PROFILE: 'Profile',
  MAP_HELP: 'Map Help',
  INTEGRATION: 'Integration',
  OLD_PASSWORD: 'Old password',
  NEW_PASSWORD: 'New password',
  REPEAT_PASSWORD: 'Repeat password',
  POSITION_CHECK: 'Position confirmation text',
  LAYER: 'Layer',
  SECURITY: 'Security',
  DRAW_TXT_DELETE: 'Are you sure to delete the draw type?',
  INFO_TITLE: 'Your current position',
  INFO_MESSAGE: 'Type your message',
  MAP_BRAND_UPDATE: 'Branding has been updated',
  EMPTY_NAME_DRAW: 'Shape type name is empty',
  BUSINESS_UNIT: 'Business unit',
  NEW_ROUTE: 'New routes',
  SEND_COORD: 'Send coordinates',
  INTEGRATION_ROUTE: 'Integration URL',
  BUTTON_ACTION: 'Button action',
  FONT_SIZE: 'Font size',
  PADDING: 'Padding',
  OPTIONAL: "(Optional)",
  TEXT_COLLAPSE: 'Navegation Zone',
  COLLAPSE_SHOW: 'Text to show',
  COLLAPSE_HIDE: 'Text to hide',
  COPY: `Copy`,
  PERSONALIZATION: 'Personalization',
  PLAN: 'Plan ID',
  QUANTITY_DAYS: 'Installation days',
  TEXT: 'Button text',
  URL_VERSION: 'GoPay catalog version',
  SERVICE: 'Services',
  GEN_SUB_BTN: 'Generator subscription button',
  MAIN_COLOR: 'Main color',
  TEXT_COLOR: 'Text color',
  NEW_LAYER: 'New layer',
  NEW_STATE: 'New state',
  NEW_MAP: 'New area',
  RELOAD: 'Reload',
  NEW_SERVICES: 'New service',
  EMAIL_INVALID: "It is not a valid email",
  STATE_EMPTY: "You must select a state",
  ERROR_LOAD: 'An error occurred, please try to load again',
  CITY_NAME: 'You must add the city name',
  LATITUDE_NOVALID: 'The latitude is not correct',
  LONGITUDE_NOVALID: 'The longitude is not correct',
  LATITUDE_EMPTY: 'The latitude is empty',
  LONGITUDE_EMPTY: 'The latitude is empty',
  EMPTY_NAME_LAYER: 'The layer name is empty',
  EMPTY_COLOR_LAYER: 'The layer color is empty',
  BTN_EMPTY_TEXT: 'The button text is empty',
  BTN_EMPTY_BORDER: 'The button border is empty',
  BTN_EMPTY_RADIUS_BORDER: 'The border radius is empty',
  BTN_EMPTY_BACKGROUND_COLOR: 'The background color is empty',
  BTN_EMPTY_FONT_COLOR: 'The font color is empty',
  EMPTY_NAME_SERVICES: 'The services name is empty',
  EMPTY_CODE_SERVICES: 'The services code is empty',
  EMPTY_NAME_MAP: 'The name is empty',
  EMPTY_STATE_MAP: 'You need to choose a state',
  EMPTY_CITY_MAP: 'You need to choose a city',
  EMPTY_SERVICES_MAP: 'You need to choose a service',
  EMPTY_LAYER_MAP: 'You need to choose a layer',
  EMPTY_DRAW_TYPE_MAP: 'You need to choose a draw type',
  EMPTY_POLYGONS_MAP: 'You need to draw on the map',
  EMPTY_AREAS: 'There is not areas registered',
  EMPTY_COLOR: 'The color is empty',
  EMPTY_NAME: 'The name is empty',
  WRONG_EMAIL: 'The email is invalid',
  EMAIL_UPDATE: 'Your email has been updated',
  PASSWORD_NO_EQUAL: "Passwords are not the same",
  PASSWORD_ALREADY_USE: "The new password is the same as the old one",
  AP_TEST_EMPTY: 'SANDBOX API Key is empty',
  AP_PROD_EMPTY: 'LIVE API Key is empty',
  TITLE_EMPTY: 'Title is empty',
  MESSAGE_EMPTY: 'Message is empty',
  EMPTY_TITLE: 'The title is empty',
  EMPTY_CONFIRM_TEXT: 'The confirm text is empty',
  COPY_CLIPBOARD: 'The script is copy to clipboard',
  ATAG_SERVICES: 'You must select the services',
  ATAG_PLAN: 'You need to type the plan',
  ATAG_TEXT: 'You need to type the text',
  URL_VERSION_CHOOSE: 'You need to choose a url version',
  EMPTY_IMAGE: 'You need load a picture',
  CHOOSE_LAYER_URL: "You need to choose an URL",
  NEW_DRAW_TYPE: 'New shape type',
  TITLE: 'Title',
  TITLE_UPDATE: "The title has been updated",
  UPDATE_EMAIL_REQUEST_TEXT: 'To change the email, please contact support@equaldata.com',
  BANK_ACCOUNT: 'Bank account',
  NEW_BANK_ACCOUNT: 'New account',
  EMPTY_BANK_DATA: 'You need to create a new account',
  LOGO_APP: 'App logo',
  WINDOW_TITLE: 'App title',
  EMAIL_LABEL: 'Email label',
  FETCH_BUTTON_TEXT: 'Fetch button text',
  FETCH_BUTTON_COLOR: 'Fetch button color',
  FETCH_BUTTON_COLOR_FONT: 'Fetch button font color',
  WARNING_MESSAGE: 'Warning message',
  INVOICE_TITLE: 'Invoice title',
  TOTAL_LABEL: 'Total label',
  ZELLE_INSTRUCTIONS: 'Zelle instructions',
  EMAIL_ZELLE: 'Zelle email',
  EMAIL_ZELLE_LABEL: "Zelle reference label",
  ZELLE_INFO_MESSAGE: 'Info message',
  SUBMIT_BUTTON_TEXT: 'Submit text button',
  SUBMIT_BUTTON_COLOR: 'Submit color button',
  SUBMIT_BUTTON_COLOR_FONT: 'Submit font color button',
  SUCCESS_ICON: 'Success icon',
  SUCCESS_MESSAGE1: 'First text',
  SUCCESS_MESSAGE2: 'Second text',
  SUCCESS_MESSAGE3: 'Third text',
  WAIT_TIME: "An inactivity has been detected, in 5 minutes the session will end.",
  DETAIL: "Detail",
  ACCOUNT_TXT_ACTION:'Are you sure to disable zelle verification?',
  ACTIVATE: 'Activate',
  DESACTIVATE: 'Desactivate',
  FIX_ACCOUNT: 'Fix',
  LOST_ACCOUNT: 'Access to this account has been lost',
  ZELLE_VERIFICATION: 'Zelle verification',
  DELETE_ACCOUNT: 'Eliminar cuenta',
  ACCOUNT_REMOVE_ACTION: 'Are you sure to delete this account?',
  PAYMENTS: 'Payments',
  DEACTIVATE: 'Deactivate',
  ZELLE_REFERENCE: 'Zelle Reference',
  AMOUNT: "Amount",
  EMPTY_DATE: 'You must select a date',
  DATE: 'Date',
  EMPTY_PAYMENT: 'You do not have any payment yet',
  DOMAIN: 'Domain',
  IS_TAXABLE: 'Is Taxable',
  ROL_TYPE: 'Role type',
  PLAID_DELETED_ITEM: 'The account was deleted',
  PLAID_PROCESSING_ITEM_CREATION: 'We are processing your request, please wait.',
  EMAIL_NOTIFICATIONS_CONFIG: 'Email Notifications',
  EMAIL_NOTIFICATIONS_TITLE: 'General settings for GoZelle email notifications',
  SUPPORT_EMAIL: "Support Email",
  PAYMENT_ID: 'Payment Id',
  STATUS: "Status",
  EMPTY_TEXT: 'The field is empty',
  INVOICES: 'Invoices',
  FILTER: 'Filter',
  DATE_RANGE: 'Date range',
  SEARCH_PAYMENT: 'Search for Name, Email, Payment Id, Zelle Reference',
  FILL_DATE: 'You must select both date',
  MESSAGE_NEW_TEXT: 'Text for a new message',
  MESSAGE_PROCESS_TEXT: 'Text for a payment in process',
  MESSAGE_APPROVE_TEXT: 'Text for an approved payment',
  MESSAGE_DECLINED_TEXT: 'Text for a declined payment',
  SPECIFIC_DATE: 'Specific date',
  ENTERPRISE: "Enterprise",
  USERS: "Users",
  NEW_ENTERPRISE: 'New enterprise',
  SEL_COUNTRY: "Country",
  SEL_HOUR: "Timezone",
  SEL_STATE_COMPANY: 'States',
  SEL_COUNTRY_EMPTY: "You must select a country",
  SEL_HOUR_EMPTY: "You must select a timezone",
  SEL_LANGUAGE: "You must select a language",
  SEL_BILLING: "Billing system",
  SEL_BILLING_EMPTY: "You must select a billing system",
  ACCESS_COMPANY: "Company access",
  ACCESS_COMPANY_EMPTY: "You must select an accesss",
  PUBLIC_NAME: 'URL identifier',
  PUBLIC_NAME_EMPTY: 'The URL identifier is empty',
  COMPANY_LOGO: 'Company logo',
  NEW_USER: 'New user',
  USERNAME: "Name and last name",
  NEW_USERNAME: 'Enter your name and last name',
  USER_PICTURE: 'User picture',
  NO_USERS_REGISTERED: 'There are no registered users',
  USER: 'User',
  ACCEPT: 'Accept',
  ROLE: 'Role',
  ADD_EMAIL: 'Add email',
  EMAIL_ALREADY_ADDED: 'The email has been already added',
  EMPTY_FIELDS: 'A field is empty',
  ZELLE_RECIPIENT_NAME: 'Zelle account holder',
  GOZELLE_UPDATE: 'The info has been updated',
  PRINCIPAL_COLOR: 'Principal color',
  COMPANY_NAME: 'Company name',
  VIEW_DETAILS: 'View details',
  CONFIRMATION_SCREEN: 'Confirmation screen',
  TAXES_SETUP_DATA: 'Tax setup data',
  ORG_ID: 'Organization ID',
  TAX_ID: 'Tax ID',
  TAX_AUTHORITY_ID: 'Tax authority ID',
  TAX_AUTHORITY_NAME: 'Tax authority name',
  TAX_EXEMPTION_ID: 'Tax exemption ID',
  TAX_EXEMPTION_CODE: 'Tax exemption code',
  ZELLE_ACCOUNT_DATA: 'Zelle account',
  HOLDER: 'Titular',
  ADD_HOLDER: 'Add holder',
  CUSTOM_FIELDS: 'Custom fields',
  FIELD_TYPE: 'Field type',
  FIELD_VALUES: 'Field value',
  NEW_FIELD: 'New custom field',
  IS_MANDATORY: 'Is mandatory',
  API_FIELD_NAME: 'API field name',
  LABEL_NAME: 'Label',
  FIELD_TYPE_NAME: 'Text',
  FIELD_TYPE_SELECT: 'Pick list',
  FIELD_LIST_VALUES: 'List value',
  LABEL_NAME_EMPTY: 'The label is empty',
  API_FIELD_NAME_EMPTY: 'The API field name is empty',
  FIELD_LIST_VALUES_EMPTY: 'You must add at least one value',
  VALUE_LIST: 'Value',
  TEXT_LIST: 'Text',
  ENTITY: 'Entity',
  CLIENT: 'Client',
  SUBSCRIPTION: 'Subscription',
  CUSTOMER_TYPE: 'Customer type',
  BUSINESS: 'Business',
  INDIVIDUAL: 'Individual',
  SELECT_CUSTOMER_TYPE: 'You must select a customer type',
  MAP_ZH_ERROR: 'You need configure a map',
  ALL_STATUS: 'All',
  REPORT_ERROR: 'An error ocurred while creating a report',
  NEW_CLIENT: 'New client',
  FULLNAME: 'Full name',
  PHONE: 'Phone',
  CARD_RIF: 'Identification',
  IS_CLIENTE: 'New cliente?',
  PAYMENT_TYPE: 'Payment type',
  PAYMENT_DATE: 'Paymente date',
  OBSERVATION: 'Observation',
  REGISTERED: 'Registered',
  ADD_USER_ZOHO: 'Add client to GoPay',
  FISCAL_ADDRESS: 'Fiscal Address',
  INSTALATION_ADDRESS: 'Instalation Address',
  SUBJECT: 'Subject',
  SEND: 'Send',
  CONTRACTOR: 'Contractor',
  NEW_CONTRACTOR: 'New contractor',
  CONTACT_NAME: 'Contact name',
  COMMENTS: 'Comments',
  ADDRESS: 'Address',
  PROGRAMMING_PAUSE: 'Programming pause system',
  REPORTS: 'Reports',
  INIT_DATE: 'Init date',
  END_DATE: 'End date',
  GENERATE: 'Generate',
  LIST: 'List',
  HOLIDAYS: 'Holidays',
  WEEKEND: 'Weekend',
  EMAIL_TEXT_NOTIFICATION: 'Indicate an email to receive daily reports',
  ENTER_NOTIFICATIONS_DATA: 'Enter the requested information to receive the reports',
  ENTER_NAME: 'Enter your full name please',
  ENTER_EMAIL: 'Enter your email please',
  ENTER_DATES: 'Please make sure you have entered both initial and end date for the report',
  INVALID_PERIOD_REPORT: 'End date cannot be older than start date',
  ENTER_DATES_DATA: 'Enter the requested information to record suspension exception dates',
  ENTER_DAY_NAME: 'Please enter a name for the date',
  ENTER_DATE: 'Please, enter the date',
  REQUEST_DATA: 'Request Data',
};

const langServer = {
	es: spanish,
	en: english,
	default: spanish
};

/**
 * 
 * @param {string} message Message
 */
export default function lang(message) {
  let selectedLang;

  if(message === undefined || message === null || message === "") {
    selectedLang = langServer['default']['EMPTY_INIT'];
    return selectedLang;
  }
  else {
    const sessionToken = window.sessionStorage.getItem('GoSuiteToken');
  
    if(sessionToken === '' || sessionToken === null || sessionToken === undefined) {
      selectedLang = langServer['default'][message];
      return selectedLang;
    }
    else {
      const tokeLang = jwt_decode(sessionToken);
      selectedLang = langServer[tokeLang.lang][message];
      return selectedLang;
    }
  }
}
