/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import { useLocation, NavLink as NavLinkRRD, Link } from "react-router-dom";
import classnames from "classnames";
import { PropTypes } from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav } from "reactstrap";
import lang from "lang";
import { AuthContext } from "./../../context/AuthContext";
import { getTokenData } from "utils/token";

function Sidebar({ toggleSidenav, sidenavOpen, logo, access }) {
  const {signOut} = React.useContext(AuthContext);
  const [goMapToggle, setGoMapToggle] = useState(false);
  const [goZelleToggle, setZelleToggle] = useState(false);
  const [goPayToggle, setGoPayToggle] = useState(false);
  const [billing, setBilling] = useState(false);
  const [role, setRole] = useState(0);
  const location = useLocation();

  useEffect(() => {
    setBilling(getTokenData('GoSuiteToken', 'billing'));
    setRole(getTokenData('GoSuiteToken', 'role'));
  }, []);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };

  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };

  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  }
  else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  
  return (
    <Navbar
      className={"sidenav navbar-vertical navbar-expand-xs navbar-light bg-white fixed-left"}
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      <PerfectScrollbar>
        <div className="scrollbar-inner">
          <div className="sidenav-header d-flex align-items-center">
            {logo ? (
              <NavbarBrand {...navbarBrandProps}>
                <img
                  alt={logo.imgAlt}
                  className="navbar-brand-img"
                  src={logo.imgSrc}
                />
              </NavbarBrand>
            ) : null}
            <div className="ml-auto">
              <div
                className={classnames("sidenav-toggler d-none d-xl-block", {
                  active: sidenavOpen,
                })}
                onClick={toggleSidenav}
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                </div>
              </div>
            </div>
          </div>

          <div className="navbar-inner">
            <Collapse navbar isOpen={true}>
              <Nav navbar>
              {
                (access.includes(4)) ? <> 
                {
                  ([1,2,5].includes(role)) ? <>
                    <NavItem>
                      <NavLink
                        data-toggle="collapse"
                        aria-expanded={goPayToggle}
                        onClick={() => {
                          setGoPayToggle(!goPayToggle)
                        }}
                      >
                        <i className="far fa-window-maximize" />
                        <span className="nav-link-text">GoPay</span>
                      </NavLink>

                      <Collapse isOpen={goPayToggle}>
                        <Nav className="nav-sm flex-column">
                          <NavItem className={activeRoute(`/admin/gopay/client`)}>
                            <NavLink
                              to="/admin/gopay/client"
                              onClick={closeSidenav}
                              tag={NavLinkRRD}
                            >
                              <span className="sidenav-mini-icon">
                                <i className="fas fa-users"></i>
                              </span>
                              <span className="sidenav-normal"> {lang("CLIENT")} </span>
                            </NavLink>
                          </NavItem>

                          <NavItem className={activeRoute(`/admin/gopay/email`)}>
                            <NavLink
                              to="/admin/gopay/email"
                              onClick={closeSidenav}
                              tag={NavLinkRRD}
                            >
                              <span className="sidenav-mini-icon">
                                <i className="far fa-envelope"></i>
                              </span>
                              <span className="sidenav-normal"> {lang("EMAIL")} </span>
                            </NavLink>
                          </NavItem>

                          {
                            ([1,2,3].includes(role)) ? <>
                              <NavItem className={activeRoute(`/admin/gopay/contractor`)}>
                                <NavLink
                                  to="/admin/gopay/contractor"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="fas fa-user-cog"></i>
                                  </span>
                                  <span className="sidenav-normal"> {lang("CONTRACTOR")} </span>
                                </NavLink>
                              </NavItem>
                            </>: <></>
                          }

                          {
                            ([1,2].includes(role)) ? <>
                              <NavItem className={activeRoute(`/admin/gopay/pause`)}>
                                <NavLink
                                  to="/admin/gopay/pause"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="fas fa-user-cog"></i>
                                  </span>
                                  <span className="sidenav-normal"> {lang("PROGRAMMING_PAUSE")} </span>
                                </NavLink>
                              </NavItem>
                            </>: <></>
                          }
                        </Nav>
                      </Collapse>
                    </NavItem>
                  </> : <></>
                }
                </> : <></>
              }

              {
                ([1,2,3,4].includes(role)) ? <>
                {
                  (access.includes(2)) ? 
                  <>
                    <NavItem>
                      <NavLink
                        data-toggle="collapse"
                        aria-expanded={goMapToggle}
                        onClick={() => {
                          setGoMapToggle(!goMapToggle)
                        }}
                      >
                        <i className="fas fa-globe-americas" />
                        <span className="nav-link-text">GoMap</span>
                      </NavLink>

                      <Collapse isOpen={goMapToggle}>
                        <Nav className="nav-sm flex-column">
                          {
                            (billing === true) ? <>
                            {
                              ([1,2].includes(role)) ? 
                              <>
                                <NavItem className={activeRoute(`/admin/gomap/dashboard`)}>
                                  <NavLink
                                    to="/admin/gomap/dashboard"
                                    onClick={closeSidenav}
                                    tag={NavLinkRRD}
                                  >
                                    <span className="sidenav-mini-icon">
                                      <i className="fas fa-desktop"></i>
                                    </span>
                                    <span className="sidenav-normal"> {lang("DASHBOARD")} </span>
                                  </NavLink>
                                </NavItem>
                              </> : <></>
                            } 
                            </> : <></>
                          }

                          {
                            ([1,2,3,4].includes(role)) ? <> 
                              <NavItem className={activeRoute(`/admin/gomap/map`)}>
                                <NavLink
                                  to="/admin/gomap/map"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="fas fa-map"></i>
                                  </span>
                                  <span className="sidenav-normal"> {lang("MAP")} </span>
                                </NavLink>
                              </NavItem>
                            </> : <></>
                          }

                          {
                            ([1,2].includes(role)) ? <> 
                              <NavItem className={activeRoute(`/admin/gomap/layer`)}>
                                <NavLink
                                  to="/admin/gomap/layer"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="fas fa-layer-group"></i>
                                  </span>
                                  <span className="sidenav-normal"> {lang("LAYERS")} </span>
                                </NavLink>
                              </NavItem>
                            </> : <></>
                          }
                          {
                            ([1,2,3,4].includes(role)) ? <> 
                              <NavItem className={activeRoute(`/admin/gomap/state`)}>
                                <NavLink
                                  to="/admin/gomap/state"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="fas fa-map-marker-alt"></i>
                                  </span>
                                  <span className="sidenav-normal"> {lang("STATE")} </span>
                                </NavLink>
                              </NavItem>
                            </> : <></>
                          }

                          {
                            ([1,2,3,4].includes(role)) ? <> 
                              <NavItem className={activeRoute(`/admin/gomap/city`)}>
                                <NavLink
                                  to="/admin/gomap/city"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="fas fa-map-marker-alt"></i>
                                  </span>
                                  <span className="sidenav-normal"> {lang("CITY")} </span>
                                </NavLink>
                              </NavItem>
                            </> : <></>
                          }

                          {
                            ([1,2,3].includes(role)) ? <> 
                              <NavItem className={activeRoute(`/admin/gomap/services`)}>
                                <NavLink
                                  to="/admin/gomap/services"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="fas fa-list"></i>
                                  </span>
                                  <span className="sidenav-normal"> {lang("SERVICES")} </span>
                                </NavLink>
                              </NavItem>
                            </> : <></>
                          }

                          {
                            ([1,2,3].includes(role)) ? <> 
                              <NavItem className={activeRoute(`/admin/gomap/draw`)}>
                                <NavLink
                                  to="/admin/gomap/draw"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="fas fa-draw-polygon"></i>
                                  </span>
                                  <span className="sidenav-normal"> {lang("DRAW_TYPE")} </span>
                                </NavLink>
                              </NavItem>
                            </> : <></>
                          }

                          {
                            ([1,2,3].includes(role)) ? <> 
                              <NavItem className={activeRoute(`/admin/gomap/personalization`)}>
                                <NavLink
                                  to="/admin/gomap/personalization"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="fas fa-palette"></i>
                                  </span>
                                  <span className="sidenav-normal"> {lang("PERSONALIZATION")} </span>
                                </NavLink>
                              </NavItem>
                            </> : <></>
                          }

                          {
                            ([1,2,3,4].includes(role)) ? <> 
                              <NavItem className={activeRoute(`/admin/gomap/settings`)}>
                                <NavLink
                                  to="/admin/gomap/settings"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="fas fa-cogs"></i>
                                  </span>
                                  <span className="sidenav-normal"> {lang("SETTINGS")} </span>
                                </NavLink>
                              </NavItem>
                            </> : <></>
                          }
                        </Nav>
                      </Collapse>
                    </NavItem>
                  </> : 
                  <> </>
                }

                {
                  (access.includes(3)) ? <>
                    <NavItem>
                      <NavLink
                        data-toggle="collapse"
                        aria-expanded={goZelleToggle}
                        onClick={() => {
                          setZelleToggle(!goZelleToggle)
                        }}
                      >
                        <i className="fas fa-money-check-alt" />
                        <span className="nav-link-text">GoZelle</span>
                      </NavLink>
                      
                      <Collapse isOpen={goZelleToggle}>
                        <Nav className="nav-sm flex-column">
                          {
                            ([1,2,3].includes(role)) ? <> 
                              <NavItem className={activeRoute(`/admin/gozelle/payments`)}>
                                <NavLink
                                  to="/admin/gozelle/payments"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="fas fa-hand-holding-usd"></i>
                                  </span>
                                  <span className="sidenav-normal"> {lang("PAYMENTS")} </span>
                                </NavLink>
                              </NavItem>
                            </> : <></>
                          }

                          {
                            ([1,2,3].includes(role)) ? <> 
                              <NavItem className={activeRoute(`/admin/gozelle/bank`)}>
                                <NavLink
                                  to="/admin/gozelle/bank"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="fas fa-university"></i>
                                  </span>
                                  <span className="sidenav-normal"> {lang("BANK_ACCOUNT")} </span>
                                </NavLink>
                              </NavItem>
                            </> : <></>
                          }

                          {
                            ([1,2,3,4].includes(role)) ? <> 
                              <NavItem className={activeRoute(`/admin/gozelle/personalization`)}>
                                <NavLink
                                  to="/admin/gozelle/personalization"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="fas fa-palette"></i>
                                  </span>
                                  <span className="sidenav-normal"> {lang("PERSONALIZATION")} </span>
                                </NavLink>
                              </NavItem>
                            </> : <></>
                          }

                          {
                            ([1,2,3,4].includes(role)) ? <> 
                              <NavItem className={activeRoute(`/admin/gozelle/settings`)}>
                                <NavLink
                                  to="/admin/gozelle/settings"
                                  onClick={closeSidenav}
                                  tag={NavLinkRRD}
                                >
                                  <span className="sidenav-mini-icon">
                                    <i className="fas fa-cogs"></i>
                                  </span>
                                  <span className="sidenav-normal"> {lang("SETTINGS")} </span>
                                </NavLink>
                              </NavItem>
                            </> : <></>
                          }
                        </Nav>
                      </Collapse>
                    </NavItem>
                  </>:
                  <></>
                }
                </> : <>
                
                </>
              }
              </Nav>
              

              <hr className="my-3" />
              <h6 className="navbar-heading p-0 text-muted">
                <span className="docs-normal">{lang("SETTINGS")}</span>
                <span className="docs-mini">
                  <i className="fas fa-cogs" />
                </span>
              </h6>

              <Nav className="mb-md-3" navbar>
                {
                  ([1].includes(role)) ? <> 
                    <NavItem>
                      <NavLink
                        to="/admin/enterprise"
                        tag={Link}>
                        <i className="far fa-building" />
                        <span className="nav-link-text">{lang("ENTERPRISE")}</span>
                      </NavLink>
                    </NavItem>
                  </> : <></>
                }
                <NavItem>
                  <NavLink
                    to="/admin/profile"
                    tag={Link}>
                    <i className="ni ni-palette" />
                    <span className="nav-link-text">{lang("PROFILE")}</span>
                  </NavLink>
                </NavItem>
                
                <NavItem>
                  <NavLink
                    to="/admin/security"
                    tag={Link}>
                    <i className="fas fa-unlock-alt" />
                    <span className="nav-link-text">{lang("SECURITY")}</span>
                  </NavLink>
                </NavItem>

                <NavItem style={{cursor: 'pointer'}}>
                  <NavLink
                    onClick={() => signOut()}>
                    <i className="fas fa-sign-out-alt" />
                    <span className="nav-link-text">{lang("LOGOUT")}</span>
                  </NavLink>
                </NavItem>

              </Nav>
            </Collapse>
          </div>
        </div>
      </PerfectScrollbar>
    </Navbar>
  );
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false,
};

Sidebar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  })
};

export default Sidebar;
