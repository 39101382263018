import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

export async function getCompanyInfoData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.company, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getCompanyEmailData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.company_email, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateCompanyData(jsn) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.put(CONS.company, jsn,  {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setPictureCompany(comImg) {
	const token = getToken('GoSuiteToken');

  const formData = new FormData();
  formData.append('file', comImg.file, comImg.file.name);

	try {
		const res = await axios.put(CONS.company_image, formData,  {
      headers: {
        'token': token,
				'Content-type': 'multipart/form-data'
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateEmailCompanyData(email) {
	const token = getToken('GoSuiteToken');

	const jsn = {
		"email": email
	}

	try {
		const res = await axios.put(CONS.company_email, jsn,  {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updatePasswordData(jsn) {
	const token = getToken('GoSuiteToken');
	
	try {
		const res = await axios.put(CONS.company_pass, jsn,  {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}

}

export async function updateAPI_KEYData(jsn) {
	const token = getToken('GoSuiteToken');
	
	try {
		const res = await axios.put(CONS.company_apikey, jsn,  {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}

}

export async function updateMapHelData(jsn) {
	const token = getToken('GoSuiteToken');
	
	try {
		const res = await axios.put(CONS.company_mapHelp, jsn,  {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateInfoWindowData(jsn) {
	const token = getToken('GoSuiteToken');
	
	try {
		const res = await axios.put(CONS.company_info, jsn,  {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateCollapseData(jsn) {
	const token = getToken('GoSuiteToken');
	
	try {
		const res = await axios.put(CONS.company_collapse, jsn,  {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateURLVersionData(data) {
	const token = getToken('GoSuiteToken');
	
	try {
		const res = await axios.put(CONS.company_url, {"integration": data},  {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getCompanyModules() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.company_modules, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getCompanyBillingSystemData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.company_billing_system, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getCompanyBillingData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.company_billing, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getCompanyUserInfoData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.company_user, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getGoZelleZohoConfig() {
  const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.gozelle_company_zoho, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getGoZelleChargebeeConfig() {
  const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.gozelle_company_chargebee, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getCompanyCustomFieldData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.custom_field, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function addCustomFieldsData(data) {
	const token = getToken('GoSuiteToken');
	
	try {
		const res = await axios.post(CONS.custom_field, {"custom_field":data},  {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getCompanyMapData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.company_map, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(err) {
    return err;
  }
}

export async function getCompanyPaymentTypeData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.company_payment_type, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setNewCliendData(jsn) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.post(CONS.company_client, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getCompanyAllClient() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.company_client, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getClientInfoData(id) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.company_client}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function addClientAtZohoData(jsn) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.put(CONS.company_client, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function sendEmailMessageData(jsn) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.post(CONS.gopay_email, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setNewContractorData(jsn) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.post(CONS.contractor, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getCompanyAllContractorData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.contractor, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getContractorData(id) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.contractor}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateNewContractorData(jsn) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.put(CONS.contractor, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}