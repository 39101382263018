/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "components/Headers/Header";
import NotificationAlert from "react-notification-alert";
import lang from 'lang';
import Compressor from 'compressorjs';
import { getColor, getTokenData } from "utils/token";
import { getCompanyInfoData, updateMapHelData, updateInfoWindowData, updateCollapseData } from "api/company";
import { getBrandData, updateBrandData, setPictureBrand } from "api/brand";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Personalization = () => {
  const notificationAlertRef = React.useRef(null);
  const [role, setRole] = useState(0);
  const [loading, setLoading] = useState({load: true, picture_load: true, picture: false, save: false, helpMap: false, info_window: false, collapse_load: false });
  const [brand, setBrand] = useState({title: '', text:'', picture: null});
  const [user, setUser] = useState({apikey:'', title: '', message: '',  info_window: '', info_message: '', col_show:'', col_hide: '', billing: 0 });
  const [value, setValue] = useState('');
  const [picture, setPicture] = useState('');
  const [file, setFile] = useState({});

  useEffect(() => {
    setRole(getTokenData('GoSuiteToken', 'role'));
    funct();
  },[]);
  
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  
  const funct = async () => {
    await getBrand();
    await getCompnayInfo();
    setLoading({...loading, load:false, picture_load: false});
  }

  const getBrand = async () => {
    const res = await getBrandData();
    
    setBrand({
      title: res.data.title,
      picture: res.data.picture,
      text: res.data.text
    });

    setPicture(res.data.picture);
    setLoading({...loading, load:false});
  }

  const getCompnayInfo = async () => {
    const res = await getCompanyInfoData();
    
    setUser({
      apikey: res.data.apikey,
      title: res.data.title,
      info_window: res.data.info_title, 
      info_message: res.data.info_message,
      col_show: res.data.CollapseShow, 
      col_hide: res.data.CollapseHide,
      billing: res.data.billing
    });

    setValue(res.data.message);
  }

  const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			reader.onload = (e) => {
				setPicture(e.target.result);
			};

			reader.readAsDataURL(event.target.files[0]);
			setFile(event.target.files[0]);
		}
	}

	const renderImageToSend = async (f) => {
		return new Compressor(f, {quality: 0.6});
	}

  const savePicture = async () => {
    setLoading({...loading, picture: true});

    let fileCompress = null;
    
    if(file && Object.keys(file).length === 0 && file.constructor === Object) {
      notifyAlert('danger', lang("EMPTY_IMAGE"));
    }
    else {
      fileCompress = await renderImageToSend(file);
            
      const res = await setPictureBrand(fileCompress);

      if(res.success === true) { 
        console.log(res.data);
        notifyAlert('success', res.data);
      }
      else {
        notifyAlert('danger', res.error.message);
      }
    }
    
    setLoading({...loading, picture: false});
  }

  const saveData = async () => {
    setLoading({...loading, save: true});
    const title = String(brand.title).trim();
    const text = String(brand.text).trim();

    if(title === "") {
      notifyAlert('danger', lang("MESSAGE"), lang("EMPTY_TITLE"));
    }
    else if(text === "") {
      notifyAlert('danger', lang("MESSAGE"), lang("EMPTY_CONFIRM_TEXT"));
    }
    else {
      const jsn = {
        'title': title,
        'text': text,
      }

      const res = await updateBrandData(jsn);

      if(res.success === true) {
        notifyAlert('success', lang("MESSAGE"), lang("TITLE_UPDATE"));
      }
      else {
        notifyAlert('danger', lang("MESSAGE"), res.error.message);
      }
    }

    setLoading({...loading, save: false});
  }

  const updateMapHelp = async() => {
    setLoading({...loading, helpMap: true});
    const {title} = user;
    
    if(title === "") {
      notifyAlert("defalut", lang("MESSAGE"), lang("TITLE_EMPTY"));
    }
    else if(value === '') {
      notifyAlert("defalut", lang("MESSAGE"), lang("MESSAGE_EMPTY"));
    }
    else {
      const jsn = {
        "title": title,
        "message": value
      }

      const res = await updateMapHelData(jsn);

      if(res.success === true) {
        notifyAlert("success", lang("MESSAGE"), res.data);
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
    }

    setLoading({...loading, helpMap: false});
  }

  const updateInfoWindowMap = async () => {
    setLoading({...loading, info_window: true});
    const { info_window, info_message } = user;

    if(String(info_window).trim() === '') {
      notifyAlert("defalut", lang("MESSAGE"), lang("TITLE_EMPTY"));
    }
    else if(String(info_message).trim() === '') {
      notifyAlert("defalut", lang("MESSAGE"), lang("MESSAGE_EMPTY"));
    }
    else {
      const jsn = {
        "info_window": info_window, 
        "info_message": info_message
      }

      const res = await updateInfoWindowData(jsn);

      if(res.success === true) {
        notifyAlert("success", lang("MESSAGE"), res.data);
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
      
    }
    setLoading({...loading, info_window: false});
  }

  const updateCollapseMessage = async () => {
    setLoading({...loading, collapse_load: true});
    const { col_show, col_hide } = user;

    if(String(col_show).trim() === '') {
      notifyAlert("default", lang("MESSAGE"), lang("TITLE_EMPTY"));
    }
    else if(String(col_hide).trim() === '') {
      notifyAlert("default", lang("MESSAGE"), lang("MESSAGE_EMPTY"));
    }
    else {
      const jsn = {
        "col_show": col_show, 
        "col_hide": col_hide
      }

      const res = await updateCollapseData(jsn);

      if(res.success === true) {
        notifyAlert("success", lang("MESSAGE"), res.data);
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
    }
    setLoading({...loading, collapse_load: false});
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3>{lang("PERSONALIZATION")}</h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <div className="tabInside">
                    <label 
                      style={{
                        fontSize: '1.06rem',
                        color: '#32325d',
                        fontWeight: 'bold',
                      }}
                    className="navbar-heading">{lang("LOGO_APP")}</label>
                  
                    <Row>
                      <Col md="12">
                        <ul style={{
                          padding: '0',
                          listStyle: 'none',
                          display: 'inline-flex'
                        }}>
                          {
                            (loading.picture_load) ? <Spinner style={{
                              color: getColor(1)
                            }} /> : 
                            <>
                              <li>
                                {
                                  (picture === null) ? <>
                                    <img 
                                      style={{
                                        width: "200px",
                                        height: "200px",
                                        objectFit: 'scale-down',
                                        marginRight: '25px'
                                      }} 
                                      src={require('assets/img/noLogo3.png').default} alt="..."/>
                                  </>:
                                  <>
                                    <img 
                                      style={{
                                        width: "200px",
                                        height: "200px",
                                        objectFit: 'scale-down',
                                        marginRight: '25px'
                                      }} 
                                      src={picture}
                                      alt="..." /> 
                                  </>
                                }
                              </li>
                              <li>
                                {
                                  (loading.picture) ? <div className="text-center" style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                                    <Spinner style={{
                                      color: getColor(1)
                                    }} />
                                  </div> : <>
                                  {
                                    ([1,2,3].includes(role)) ? <>
                                      <ul style={{
                                        padding: '0',
                                        listStyle: 'none',
                                        display: 'inline-flex',
                                        position: 'relative',
                                        top: '50%',
                                        transform: 'translateY(-50%)'
                                      }}>
                                        <li>
                                          <label className="custom-file-upload" style={{marginTop: '0px'}}>
                                            <input 
                                              type="file"
                                              accept="image/*"
                                              onChange={onImageChange}/>
                                            {lang("EDIT")}
                                          </label>
                                        </li>
                                        <li>
                                          <Button
                                            style={{
                                              backgroundColor: '#6c757d',
                                              color: '#fff',
                                              border: 0
                                            }}
                                            onClick={() => savePicture()}> {lang("SAVE")}
                                          </Button>
                                        </li>
                                      </ul>
                                    </> : <></>
                                  }
                                  </>
                                }
                              </li>
                            </>
                          }
                        </ul>
                      </Col>
                    </Row>
                    
                    <Row>
                      <Col md="12">
                        <div>
                          <FormGroup>
                            <label className="form-control-label"> {lang("TITLE")} </label>
                            <Input
                              disabled={![1,2,3].includes(role)}
                              value={brand.title}
                              className="form-control"
                              placeholder={lang("TITLE")}
                              onChange={val => setBrand({...brand, title: val.target.value })}/>
                          </FormGroup>

                          <FormGroup>
                            <label className="form-control-label">{lang("POSITION_CHECK")}</label>
                            <Input 
                              disabled={![1,2,3].includes(role)}
                              value={brand.text}
                              placeholder={lang("POSITION_CHECK")}
                              onChange={val => setBrand({...brand, text: val.target.value })} />
                          </FormGroup>

                          {
                            ([1,2,3].includes(role)) ? <>
                              <div className="float-right">
                                {
                                  (loading.save) ? <div className="text-center">
                                    <Spinner style={{color: getColor(1) }} />
                                  </div> :
                                  <>
                                    <Button
                                      style={{
                                        backgroundColor: getColor(1),
                                        color: getColor(2),
                                        border: 0
                                      }}
                                      onClick={() => saveData()}
                                      > {lang("SAVE")}
                                    </Button>
                                  </>
                                }
                              </div>                            
                            </> : <></> 
                          }
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <label 
                          style={{
                            fontSize: '1.06rem',
                            color: '#32325d',
                            fontWeight: 'bold'
                          }}
                          className="navbar-heading">{lang("MAP_HELP")}</label>

                        <FormGroup>
                          <label className="form-control-label">{lang("TITLE")}</label>
                          <Input 
                            disabled={![1,2,3].includes(role)}
                            value={user.title}
                            placeholder={lang("TITLE")} 
                            onChange={val => setUser({...user, title: val.target.value})}/>
                        </FormGroup>

                        <FormGroup>
                          <label className="form-control-label">{lang("MESSAGE")}</label>
                          <ReactQuill 
                            readOnly={![1,2,3].includes(role)}
                            theme="snow"
                            value={value} 
                            onChange={setValue}/>
                        </FormGroup>
                        
                        {
                          ([1,2,3].includes(role)) ? <>
                            <div className="float-right">
                              {
                                (loading.helpMap) ? <div>
                                  <Spinner style={{
                                    color: getColor(1)
                                  }} />
                                </div> :
                                <>
                                  <Button
                                    style={{
                                      backgroundColor: getColor(1),
                                      color: getColor(2),
                                      border: 0
                                    }}
                                    onClick={() => updateMapHelp()}
                                    > {lang("SAVE")}
                                  </Button>
                                </>
                              }
                            </div>                          
                          </> : <></>
                        }
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <label 
                          style={{
                            fontSize: '1.06rem',
                            color: '#32325d',
                            fontWeight: 'bold'
                          }}
                          className="navbar-heading">{lang("TEXT_COLLAPSE")}</label>

                        <FormGroup>
                          <label className="form-control-label">{lang("COLLAPSE_SHOW")}</label>
                          <Input 
                            disabled={![1,2,3].includes(role)}
                            value={user.col_show}
                            placeholder={lang("INFO_MESSAGE")} 
                            onChange={val => setUser({...user, col_show: val.target.value})}/>
                        </FormGroup>

                        <FormGroup>
                          <label className="form-control-label">{lang("COLLAPSE_HIDE")}</label>
                          <Input 
                            disabled={![1,2,3].includes(role)}
                            value={user.col_hide}
                            placeholder={lang("INFO_MESSAGE")} 
                            onChange={val => setUser({...user, col_hide: val.target.value})}/>
                        </FormGroup>
                
                        {
                          ([1,2,3].includes(role)) ? <>
                            <div className="float-right">
                              {
                                (loading.collapse_load) ? <div>
                                  <Spinner style={{
                                    color: getColor(1)
                                  }} />
                                </div> :
                                <>
                                  <Button
                                    style={{
                                      backgroundColor: getColor(1),
                                      color: getColor(2),
                                      border: 0
                                    }}
                                    onClick={() => updateCollapseMessage()}
                                    > {lang("SAVE")}
                                  </Button>
                                </>
                              }
                            </div>
                          </> : <></>
                        }
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <label
                          style={{
                            fontSize: '1.06rem',
                            color: '#32325d',
                            fontWeight: 'bold'
                          }}
                          className="navbar-heading">InfoWindows GoMap</label>
                        <FormGroup>
                          <label className="form-control-label">{lang("TITLE")}</label>
                          <Input 
                            disabled={![1,2,3].includes(role)}
                            value={user.info_window}
                            placeholder={lang("INFO_TITLE")} 
                            onChange={val => setUser({...user, info_window: val.target.value})}/>
                        </FormGroup>

                        <FormGroup>
                          <label className="form-control-label">{lang("MESSAGE")}</label>
                          <Input 
                            disabled={![1,2,3].includes(role)}
                            value={user.info_message}
                            placeholder={lang("INFO_MESSAGE")} 
                            onChange={val => setUser({...user, info_message: val.target.value})}/>
                        </FormGroup>
                        
                        {
                          ([1,2,3].includes(role)) ? <>
                            <div className="float-right">
                              {
                                (loading.info_window) ? <div>
                                  <Spinner style={{
                                    color: getColor(1)
                                  }} />
                                </div> :
                                <>
                                  <Button
                                    style={{
                                      backgroundColor: getColor(1),
                                      color: getColor(2),
                                      border: 0
                                    }}
                                    onClick={() => updateInfoWindowMap()}
                                    > {lang("SAVE")}
                                  </Button>
                                </>
                              }
                            </div>
                          </> : <></>
                        }
                      </Col>
                    </Row>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default Personalization;