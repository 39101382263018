import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

export async function getBrandData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.brand, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateBrandData(jsn) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.put(CONS.brand, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setPictureBrand(comImg) {
	const token = getToken('GoSuiteToken');

  const formData = new FormData();
  formData.append('file', comImg.file, comImg.file.name);

	try {
		const res = await axios.put(CONS.brand_image, formData,  {
      headers: {
        'token': token,
					'Content-type': 'multipart/form-data'
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}


