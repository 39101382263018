import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

//PAUSE
export async function getExportData(initDate, endDate) {

  const token = getToken('GoSuiteToken');

  const body = {
    "initDate": initDate,
    "endDate": endDate
  }

	try {
		const response = await axios.post(CONS.get_export_data, body, {
      headers: {
        'token': token
      }
    });

		return response.data;
	}
	catch(error) {
		return error;
	}
}

export async function getNotificationsList() {

	const token = getToken('GoSuiteToken');

	try {

		const response = await axios.get(CONS.notifications_list, {
      headers: {
        'token': token
      }
    });

		return response.data;
	}
	catch(error) {
		return error;
	}
}

export async function insertNotificationsUser(notificationName, notificationEmail) {

	const token = getToken('GoSuiteToken');

  const body = {
    "notificationName": notificationName,
    "notificationEmail": notificationEmail
  };

	try {
		const response = await axios.post(CONS.notifications_add, body, {
      headers: {
        'token': token
      }
    });

		return response.data;
	}
	catch(error) {
		return error;
	}
}

export async function deleteNotificationEmail(id) {

  const token = getToken('GoSuiteToken');

	try {
		const response = await axios.delete(`${CONS.notifications_delete}/${id}`, {
      headers: {
        'token': token
      }
    });

		return response.data;
	}
	catch(error) {
		return error;
	}
}

export async function getDatesList() {

	const token = getToken('GoSuiteToken');

	try {

		const response = await axios.get(CONS.dates_list, {
      headers: {
        'token': token
      }
    });
    
		return response.data;
	}
	catch(error) {
		return error;
	}
}

export async function insertDate(dayName, dayDate) {

	const token = getToken('GoSuiteToken');

  const body = {
    "dayName": dayName,
    "dayDate": dayDate
  };

	try {
		const response = await axios.post(CONS.dates_add, body, {
      headers: {
        'token': token
      }
    });

		return response.data;
	}
	catch(error) {
		return error;
	}
}

export async function deleteDate(id) {

  const token = getToken('GoSuiteToken');

	try {
		const response = await axios.delete(`${CONS.dates_delete}/${id}`, {
      headers: {
        'token': token
      }
    });

		return response.data;
	}
	catch(error) {
		return error;
	}

}