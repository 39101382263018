/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import { Spinner, Card, Container, CardHeader, Row, Button, Col, CardBody, FormGroup, Input } from "reactstrap";
import Header from "components/Headers/Header";
import lang from 'lang';
import { getInfoLayerData, updateLayerData } from 'api/layer';
import { getColor } from "utils/token";
import NotificationAlert from "react-notification-alert";

const EditState = () => {
  const notificationAlertRef = React.useRef(null);
  const {id} = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [name, setName] = useState('');
  const [active, setActive] = useState('');
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');
  const [color, setColor] = useState('');
  const [btnStyle, setBtnStyle] = useState({});
  const [checkURL, setCheckURL] = useState(false);
  const [newUrl, setNewUrl] = useState('');
  const [coord, setCoord] = useState(false);

  useEffect(() => {
    getInfoLayer();
  },[]);
  
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const setButtonBorder = (num) => {    
    if(parseInt(num) < 0) {
      setBtnStyle({...btnStyle, border: '0'})
    }
    else if(parseInt(num) > 10) {
      setBtnStyle({...btnStyle, border: '10'})
    }
    else {
      setBtnStyle({...btnStyle, border: num})
    }
  }

  const setButtonBorderRadius = (num) => {
    if(parseInt(num) < 0) {
      setBtnStyle({...btnStyle, radius_border: '0'})
    }
    else if(parseInt(num) > 50) {
      setBtnStyle({...btnStyle, radius_border: '50'})
    }
    else {
      setBtnStyle({...btnStyle, radius_border: num})
    }
  }

  const getInfoLayer = async () => {
    setLoadingPage(true);
    const res = await getInfoLayerData(id);
    const _style = (Object.keys(data).length === null) ? {} : JSON.parse(res.data.style);
    const integration = (res.data.integrate === 1) ? true : false;
    const coord = (res.data.coord === 1) ? true : false;
    const _msg = (res.data.Message === null) ? '' : res.data.Message;
    setData(res.data);
    setName(res.data.name);
    setActive(res.data.active);
    setDescription(res.data.description);
    setMessage(_msg);
    setColor(res.data.color);
    setBtnStyle(_style);    
    setCheckURL(integration);
    setNewUrl(res.data.URL);
    setCoord(coord);
    setLoadingPage(false);
  }

  const updateLayer = async () => {
    setLoading(true);
  
    if(name === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_NAME_LAYER"));
    }
    else if(color === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_COLOR_LAYER"));
    }
    else if(checkURL === false && String(newUrl).trim() === '') {
      notifyAlert("default", lang("MESSAGE"), lang("CHOOSE_LAYER_URL"));
    }
    /*
    else if(btnStyle.text === undefined || btnStyle.text === "") {
      setMsg(lang().RESPONSE.BTN_EMPTY_TEXT);
      setModal(true);
    }
    else if(btnStyle.border === "") {
      setMsg(lang().RESPONSE.BTN_EMPTY_BORDER);
      setModal(true);
    }
    else if(btnStyle.radius_border === "") {
      setMsg(lang().RESPONSE.BTN_EMPTY_RADIUS_BORDER);
      setModal(true);
    }
    else if(btnStyle.background_color === "") {
      setMsg(lang().RESPONSE.BTN_EMPTY_BACKGROUND_COLOR);
      setModal(true);
    }
    else if(btnStyle.font_color === "") {
      setMsg(lang().RESPONSE.BTN_EMPTY_FONT_COLOR);
      setModal(true);
    }*/
    else {
      const res = await updateLayerData(id, name, active, description, color, btnStyle, checkURL, newUrl, coord, message);

      if(res.success === true) {
        notifyAlert("success", lang("MESSAGE"), res.data);
        window.location.href = '/admin/gomap/layer';
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
    }

    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("LAYERS")} </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  {
                    (loadingPage) ? <div style={{
                      textAlign: 'center'
                    }}>
                      <Spinner style={{
                        color: getColor(1)
                      }} />
                    </div> : 
                    <>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("NAME")} </label>
                            <Input
                              value={name}
                              placeholder={lang("NAME")}
                              className="form-control"
                              onChange={val => setName(val.target.value)}/>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("ACTIVE")} </label>
                            <select
                              className="form-control"
                              placeholder={lang("ACTIVE")}
                              value={active}
                              onChange={val => setActive(val.target.value)}
                            >
                              <option value="1">{lang("YES")}</option>
                              <option value="0">{lang("NO")}</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
    
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label"> {lang("DESCRIPTION")} </label>
                            <textarea
                              id='txtArea'
                              className="form-control"
                              value={description}
                              onChange={val => setActive(val.target.value)}>
                            </textarea>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label"> {lang("MESSAGE")} </label>
                            <textarea
                              id='txtArea'
                              className="form-control"
                              value={message}
                              onChange={val => setMessage(val.target.value)}>
                            </textarea>
                          </FormGroup>
                        </Col>
                      </Row>
    
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("COLOR_LAYER")} </label> <br />
                            <input 
                              value={color}
                              className="form-control"
                              style={{
                                width: '100px',
                                display: 'inline-block'
                              }}
                              onChange={val => setColor(val.target.value)}
                            />
                            <input
                              type="color"
                              style={{
                                width: '100px',
                                display: 'inline-block',
                                marginLeft:'5px',
                                top: '2px',
                                position: 'relative'
                              }}
                              className="form-control"
                              value={color}
                              onChange={val => setColor(val.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
    
                      <Row>
                        {
                          <>
                            <Col lg="6">
                              <FormGroup>
                                <label>{lang("BUTTON_STYLE")}</label> <br />
                                
                                <ul id="ulBtnStyle">
                                  <li>
                                    <label className="form-control-label"> {lang("TEXT_BUTTON")} </label> <br />
                                    <input 
                                      value={btnStyle.text}
                                      className="form-control"
                                      onChange={val => setBtnStyle({...btnStyle, text: val.target.value})}
                                    />
                                  </li>
                                  <li>
                                    <Row>
                                      <Col md='6'>
                                        <label className="form-control-label">{lang("BORDER")}</label> <br />
                                        <input 
                                          value={btnStyle.border}
                                          type="number"
                                          min="0"
                                          max="10"
                                          className="form-control"
                                          onChange={val => setButtonBorder(val.target.value)}
                                        />
                                      </Col>
                                      <Col md='6'>
                                        <label className="form-control-label">{lang("BORDER_RADIUS")}</label> <br />
                                        <input 
                                          value={btnStyle.radius_border}
                                          type="number"
                                          min="0"
                                          max="50"
                                          className="form-control"
                                          onChange={val => setButtonBorderRadius(val.target.value)}
                                        />
                                      </Col>
                                    </Row>
                                  </li>

                                  <li>
                                    <Row>
                                      <Col md='12'>
                                        <label className="form-control-label">{lang("PADDING")}</label> <br />
                                      </Col>
                                      <Col md='3'>
                                        <input 
                                          value={btnStyle.padding_left}
                                          type="number"
                                          min="0"
                                          max="100"
                                          className="form-control"
                                          onChange={val => setBtnStyle({...btnStyle, padding_left: val.target.value})}
                                        />
                                      </Col>

                                      <Col md='3'>
                                        <input 
                                          value={btnStyle.padding_top}
                                          type="number"
                                          min="0"
                                          max="100"
                                          className="form-control"
                                          onChange={val => setBtnStyle({...btnStyle, padding_top: val.target.value})}
                                        />
                                      </Col>

                                      
                                      <Col md='3'>
                                        <input 
                                          value={btnStyle.padding_right}
                                          type="number"
                                          min="0"
                                          max="100"
                                          className="form-control"
                                          onChange={val => setBtnStyle({...btnStyle, padding_right: val.target.value})}
                                        />
                                      </Col>

                                      <Col md='3'>
                                        <input 
                                          value={btnStyle.padding_bottom}
                                          type="number"
                                          min="0"
                                          max="100"
                                          className="form-control"
                                          onChange={val => setBtnStyle({...btnStyle, padding_bottom: val.target.value})}
                                        />
                                      </Col>
                                    </Row>
                                  </li>

                                  <li>
                                    <label className="form-control-label">{lang("BACKGROUND_COLOR")}</label> <br />
                                    <input 
                                      value={btnStyle.background_color}
                                      className="form-control"
                                      style={{
                                        width: '100px',
                                        display: 'inline-block'
                                      }}
                                      onChange={val => setBtnStyle({...btnStyle, background_color: val.target.value})}
                                    />
                                    <input
                                      type="color"
                                      style={{
                                        width: '100px',
                                        display: 'inline-block',
                                        marginLeft:'5px',
                                        top: '2px',
                                        position: 'relative'
                                      }}
                                      className="form-control"
                                      value={btnStyle.background_color}
                                      onChange={val => setBtnStyle({...btnStyle, background_color: val.target.value})}
                                    />
                                  </li>
                                  <li>
                                    <Row>
                                      <Col md='12'>
                                        <label className="form-control-label">{lang("FONT_SIZE")}</label> <br />
                                        <input 
                                          value={btnStyle.font_size}
                                          type="number"
                                          min="0"
                                          max="100"
                                          className="form-control"
                                          onChange={val => setBtnStyle({...btnStyle, font_size: val.target.value})}
                                        />
                                      </Col>
                                    </Row>
                                  </li>
                                  <li>
                                    <label className="form-control-label">{lang("FONT_COLOR")}</label> <br />
                                    <input 
                                      value={btnStyle.font_color}
                                      className="form-control"
                                      style={{
                                        width: '100px',
                                        display: 'inline-block'
                                      }}
                                      onChange={val => setBtnStyle({...btnStyle, font_color: val.target.value})}
                                    />
                                    <input
                                      type="color"
                                      style={{
                                        width: '100px',
                                        display: 'inline-block',
                                        marginLeft:'5px',
                                        top: '2px',
                                        position: 'relative'
                                      }}
                                      className="form-control"
                                      value={btnStyle.font_color}
                                      onChange={val => setBtnStyle({...btnStyle, font_color: val.target.value})}
                                    />
                                  </li>
                                </ul>
                              </FormGroup>
                            </Col>
                            <Col lg='6' className="align-items-center align-self-center">
                              <div style={{
                                textAlign: 'center'
                              }}>
                                <button
                                  style={{
                                    paddingLeft: `${btnStyle.padding_left}px`,
                                    paddingTop: `${btnStyle.padding_top}px`,
                                    paddingRight: `${btnStyle.padding_right}px`,
                                    paddingBottom: `${btnStyle.padding_bottom}px`,
                                    borderWidth: `${btnStyle.border}px`,
                                    backgroundColor: btnStyle.background_color,
                                    borderRadius: `${btnStyle.radius_border}px`,
                                    color: btnStyle.font_color,
                                    fontSize: `${btnStyle.font_size}px`
                                  }}>{btnStyle.text}</button>
                              </div>
    
                            </Col>
                          </>
                        }
                      </Row>

                      <hr />
                      <Row>
                        <Col>
                          <label> {lang("BUTTON_ACTION")}</label> <br />

                          <FormGroup>
                            <input 
                              checked={checkURL}
                              style={{
                                position: 'relative',
                                top: '2px',
                                right: '5px',
                              }}
                              type='checkbox' 
                              onChange={val => setCheckURL(val.target.checked)} />
                              <label className="form-control-label"> {lang("INTEGRATION_ROUTE")} </label> 
                          </FormGroup>

                          <FormGroup>
                            <label className="form-control-label">{lang("NEW_ROUTE")}</label> <br />
                            <Input
                              value={newUrl}
                              disabled={checkURL}
                              className="form-control"
                              onChange={val => setNewUrl(val.target.value)}/>
                          </FormGroup>

                          <FormGroup>
                            <input 
                              checked={coord}
                              style={{
                                position: 'relative',
                                top: '2px',
                                right: '5px',
                              }}
                              type='checkbox'
                              onChange={val => setCoord(val.target.checked)}/>
                            <label className="form-control-label"> {lang("SEND_COORD")} </label> 
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" >
                          <br />
                          <div className="float-right">
                            {
                              (loading) ? <> 
                                <Spinner style={{ color: getColor(1) }} />
                              </>:
                              <>
                                <Button color="gray" to="/admin/gomap/layer" tag={Link}> {lang("BACK")} </Button>  
                              {
                                (Object.keys(data).length === 0) ? <></> :
                                <> 
                                  <Button
                                    color="primary"
                                    style={{
                                      backgroundColor: getColor(1),
                                      color: getColor(2),
                                      border: 0
                                    }}
                                    onClick={() => updateLayer()}
                                    > {lang("SAVE")}
                                  </Button>
                                </>
                              }
                              </>                          
                            }
                          </div>
                        </Col>
                      </Row>                    
                    </>  
                  }

                </div>
              </CardBody>
            </Card>
 
          </div>
        </Row>    

      </Container>

    </>
  )
}

export default EditState;