import React, {useState} from 'react';
import { Card, Container, CardHeader, Row, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Header from "components/Headers/Header";
import lang from 'lang';
import Users from './components/Users';
import EditEnterprise from './components/EditEnterprise';

export default function EnterpriseDetail() {
  const [activeTab, setActiveTab] = useState('1');
  
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("ENTERPRISE")} </h3>
                  </div>
                </Row>
              </CardHeader>
              
              <CardBody>
                <div className="pl-lg-4">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={(activeTab === '1') ? 'active' : ''}
                        onClick={() => { toggle('1'); }}
                        >
                        {lang("ENTERPRISE")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={(activeTab === '2') ? 'active' : ''}
                        onClick={() => { toggle('2'); }}
                        >
                        {lang("USERS")}
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <EditEnterprise />
                    </TabPane>
                    <TabPane tabId="2">
                      <Users />
                    </TabPane>
                  </TabContent>

                </div>
              </CardBody>
            
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}