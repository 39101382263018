/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-dupe-keys */
import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Header from "components/Headers/Header";
import {Spinner, Card, Container, CardHeader, Row, Button, Col, CardBody, FormGroup, Input } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import lang from 'lang';
import { getAllCompanyStateData, getCitiesByStateData } from "api/places";
import { getCompanyPaymentTypeData, setNewCliendData } from "api/company";
import { getColor } from "utils/token";

const NewClient = () => {
  const notificationAlertRef = React.useRef(null);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [cardMask, setCarMask] = useState('');
  const [cardId, setCardId] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [comments, setComments] = useState('');
  const [arrState, setArrState] = useState([]);
  const [arrCities, setArrCities] = useState([]);
  const [arrPayment, setArrPayment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addressFiscal, setAddressFiscal] = useState('');
  const [addressInstalation, setAddressInstalation] = useState('');
  const [customerType, setCustomerType] = useState('');

  useEffect(() => {
    getAllState();
    getCompanyPaymentType();
  },[]);
 
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  
  const getAllState = async () => {
    const res = await getAllCompanyStateData();
    setArrState(res.data);
  }

  const getCitiesByState = async (id) => {
    const res = await getCitiesByStateData(id);
    setArrCities(res.data);
    setState(id);
  }

  const getCompanyPaymentType = async () => {
    const res = await getCompanyPaymentTypeData();
    setArrPayment(res.data);
  }
  
  const createNewClient = async () => {
    setLoading(true);

    if(name.trim() === '') {
      notifyAlert("default", lang("MESSAGE"), 'Ingresar nombre');
    }
    else if(lastName.trim() === '') {
      notifyAlert("default", lang("MESSAGE"), "Ingresar apellido");
    }
    else if(email.trim() === '') {
      notifyAlert("default", lang("MESSAGE"), "Ingresar e-mail");
    }
    else if (!/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/.test(email)) {
      notifyAlert("default", lang("MESSAGE"), "El formato de correo no es válido");
    }
    else if(phone.trim() === '') {
      notifyAlert("default", lang("MESSAGE"), "Ingresar e-mail");
    }
    else if(cardMask === '') {
      notifyAlert("default", lang("MESSAGE"), "Debe seleccionar el tipo de identificacion");
    }
    else if(cardId.trim() === '') {
      notifyAlert("default", lang("MESSAGE"), "Ingresar e-mail");
    }
    else if(state === '') {
      notifyAlert("default", lang("MESSAGE"), "Debe seleccionar estado");
    }
    else if(city === '') {
      notifyAlert("default", lang("MESSAGE"), "Debe seleccionar ciudad");
    }
    else if(paymentType === '') {
      notifyAlert("default", lang("MESSAGE"), "Debe seleccionar tipo de pago");
    }
    else if(paymentDate === '') {
      notifyAlert("default", lang("MESSAGE"), "Ingresar fecha de pago");
    }
    else if(customerType === '') {
      notifyAlert("default", lang("MESSAGE"), "Debe seleccionar tipo de cliente");
    }
    else if(addressFiscal === '') {
      notifyAlert("default", lang("MESSAGE"), "Debe ingresar direccion fiscal");
    }
    else if(addressInstalation === '') {
      notifyAlert("default", lang("MESSAGE"), "Debe ingresar la direccion de instalacion");
    }
    else {
      const jsn = {
        "name": name,
        "fullname": name,
        "lastName": lastName,
        "email": email,
        "phone": phone,
        "cardMask": cardMask,
        "cardId": cardId,
        "state": state,
        "city": city,
        "paymentType": paymentType,
        "paymentDate": paymentDate,
        "addressFiscal": addressFiscal,
        "addressInstalation": addressInstalation,
        "comments": comments,
        "customerType": customerType,
        "metadata": {
          "state": getText(state, 1),
          "city": getText(city, 2),
          "payment": getText(paymentType, 3)
        }
      }

      console.log(jsn);

      const res = await setNewCliendData(jsn);

      if(res.success === true) {

        if(res.data.sys === true) {
          notifyAlert("success", lang("MESSAGE"), res.data.message);
          window.location.href = '/admin/gopay/client';
        }
        else {
          notifyAlert("warning", lang("MESSAGE"), res.data.message);
          window.location.href = '/admin/gopay/client';
        }
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
    }

    setLoading(false);
  }

  const getText = (val, opc) => {
    var value = null;
    
    switch(opc) {
      case 1: 
        for(var ds of arrState) {
          if(ds.id === Number(val)) {
            value = ds.name;
            break;
          } 
        }
        return value;
      
      case 2: 
        for(var dc of arrCities) {
          if(dc.id === Number(val)) {
            value = dc.name;
            break;
          } 
        }
        return value;

      case 3: 
        for(var dp of arrPayment) {
          if(dp.id === Number(val)) {
            value = dp.name;
            break;
          } 
        }
        return value;

      default:
        value = null;
        return value;
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("CLIENT")} </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("NAME")} </label>
                        <Input 
                          value={name}
                          placeholder={lang("NAME")}
                          onChange={(val) => setName(val.target.value)} 
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("LASTNAME")} </label>
                        <Input 
                          value={lastName}
                          placeholder={lang("LASTNAME")}
                          onChange={(val) => setLastName(val.target.value)} 
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("EMAIL")} </label>
                        <Input 
                          value={email}
                          placeholder={lang("EMAIL")}
                          onChange={(val) => setEmail(val.target.value)} 
                          />
                      </FormGroup>
                    </Col>
                    
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("PHONE")} </label>
                        <Input 
                          value={phone}
                          placeholder={lang("PHONE")}
                          onChange={(val) => setPhone(val.target.value)} 
                          />
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("CARD_RIF")} </label>
                        <br />
                        <div style={{
                          display: 'flex'
                        }}>
                          <select
                            style={{
                              width: '30%'
                            }}
                            className="form-control"
                            value={cardMask}
                            onChange={val => setCarMask(val.target.value)}
                          >
                            <option value="">{lang("SELECT")}</option>
                            <option value="V">V</option>
                            <option value="E">E</option>
                            <option value="J">J</option>
                            <option value="G">G</option>
                          </select>
                          <Input 
                            value={cardId}
                            placeholder={lang("CARD_RIF")}
                            onChange={(val) => setCardId(val.target.value)} 
                            />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("SEL_STATE")} </label>
                        <select
                          className="form-control"
                          value={state}
                          onChange={val => getCitiesByState(val.target.value)}
                        >
                          <option>{lang("SELECT")}</option>
                          {
                            arrState.map( (s, i) => (
                              <option 
                              key={i}
                              value={s.id}>{s.name}</option>
                              ))
                            }
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("CITY_ONE")} </label>
                        <select
                          className="form-control"
                          value={city}
                          onChange={val => setCity(val.target.value)}
                        >
                          <option>{lang("SELECT")}</option>
                          {
                            arrCities.map( (s, i) => (
                              <option 
                              key={i}
                              value={s.id}>{s.name}</option>
                              ))
                            }
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("PAYMENT_TYPE")} </label>
                        <select
                          className="form-control"
                          value={paymentType}
                          onChange={val => setPaymentType(val.target.value)}
                        >
                          <option>{lang("SELECT")}</option>
                          {
                            arrPayment.map( (s, i) => (
                              <option 
                                key={i}
                                value={s.id}>{s.name}</option>
                            ))
                          }
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("PAYMENT_DATE")} </label>
                        <Input 
                          type='date'
                          value={paymentDate}
                          placeholder={lang("PAYMENT_DATE")}
                          onChange={(val) => setPaymentDate(val.target.value)} 
                          />
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("CUSTOMER_TYPE")} </label>
                        <select
                          className="form-control"
                          value={customerType}
                          onChange={val => setCustomerType(val.target.value)}
                        >
                          <option value="">{lang("SELECT")}</option>
                          <option value="individual">{lang("INDIVIDUAL")}</option>
                          <option value="business">{lang("BUSINESS")}</option>
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> {lang("FISCAL_ADDRESS")} </label>
                        <textarea 
                          value={addressFiscal}
                          className="form-control"
                          onChange={(val) => setAddressFiscal(val.target.value)} ></textarea>
                      </FormGroup>
                    </Col>

                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> {lang("INSTALATION_ADDRESS")} </label>
                        <textarea 
                          value={addressInstalation}
                          className="form-control"
                          onChange={(val) => setAddressInstalation(val.target.value)} ></textarea>
                      </FormGroup>
                    </Col>

                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> {lang("OBSERVATION")} </label>
                        <textarea 
                          value={comments}
                          className="form-control"
                          onChange={(val) => setComments(val.target.value)} ></textarea>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                      {
                        (loading) ? <Spinner style={{color: getColor(1)}}/> :
                        <div>
                          <Button color="gray" to="/admin/gopay/client" tag={Link}> {lang("BACK")} </Button>
                          <Button
                            style={{
                              backgroundColor: getColor(1),
                              color: getColor(2),
                              border: 0
                            }}
                            onClick={() => createNewClient()}
                            > {lang("SAVE")}
                          </Button>
                        </div>
                      }
                      </div>
                    </Col>
                  </Row>

                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default NewClient;