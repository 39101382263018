import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Header from "components/Headers/Header";
import {Spinner, Card, Container, CardHeader, Row, Button, Col, CardBody, FormGroup, Input } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import lang from 'lang';
import { sendEmailMessageData } from "api/company";
import { getColor } from "utils/token";

const Email = () => {
  const notificationAlertRef = React.useRef(null);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const sendMessage = async () => {
    setLoading(true);

    if(subject.trim() === '') {
      notifyAlert("default", lang("MESSAGE"), 'Ingresar asusnto');
    }
    else if(message.trim() === '') {
      notifyAlert("default", lang("MESSAGE"), "Ingresar mensaje");
    }
    else {
      const jsn = {
        "subject": subject,
        "message": message
      }

      const res = await sendEmailMessageData(jsn);

      if(res.success === true) {
        setSubject('');
        setMessage('');
        notifyAlert("success", lang("MESSAGE"), 'Mensaje enviado');
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
    }

    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("EMAIL")} </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> {lang("SUBJECT")} </label>
                        <Input 
                          value={subject}
                          placeholder={lang("SUBJECT")}
                          onChange={(val) => setSubject(val.target.value)} 
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> {lang("MESSAGE")} </label>
                        <textarea 
                          value={message}
                          className="form-control"
                          placeholder={lang("MESSAGE")}
                          onChange={(val) => setMessage(val.target.value)} ></textarea>
                      </FormGroup>
                    </Col>

                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                      {
                        (loading) ? <Spinner style={{color: getColor(1)}}/> :
                        <div>
                          <Button color="gray" to="/admin/gopay/client" tag={Link}> {lang("BACK")} </Button>
                          <Button
                            style={{
                              backgroundColor: getColor(1),
                              color: getColor(2),
                              border: 0
                            }}
                            onClick={() => sendMessage()}
                            > {lang("SEND")}
                          </Button>
                        </div>
                      }
                      </div>
                    </Col>
                  </Row>

                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default Email;