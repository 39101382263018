/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import {Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap";
import Header from "components/Headers/Header";
import NotificationAlert from "react-notification-alert";
import lang from 'lang';
import { getServicesData, updateServicesData, deleteServicesData } from "api/services";
import { getColor, getTokenData } from "utils/token";

const EditServices = () => {
  const notificationAlertRef = React.useRef(null);
  const {id} = useParams();
  const [role, setRole] = useState(0);
  const [data, setData] = useState({});
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [description, setDescription] = useState('');
  const [active, setActive] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  
  useEffect( ()=> {
    setRole(getTokenData('GoSuiteToken', 'role'));
    getServices();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  
  const getServices = async () => {
    const res = await getServicesData(id);

    if(res.success === true) {
      setData(res.data);
      setName(res.data.name);
      setCode(res.data.code);
      setDescription(res.data.description);
      setActive(res.data.active);
    }
    else {
      setData({});
      notifyAlert("danger", lang("MESSAGE"), res.error.message);
    }
  }
  
  const toggleDelete = () => setModalDelete(!modalDelete);

  const updateServices = async () => {
    setLoading(true);
    const _name = name.trim();
    const _code = code.trim();
    
    if(_name === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_NAME_SERVICES"));
    }
    else if(_code === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_CODE_SERVICES"));
    }
    else {
      const res = await updateServicesData(id, _name, _code, description, active);

      if(res.success === true) {
        window.location.href = '/admin/gomap/services';
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
    }

    setLoading(false);
  }

  const deleteServices = async () => {
    const res = await deleteServicesData(id);
    
    if(res.success === true) {
      setModalDelete(false);
      window.location.href = '/admin/gomap/services'
    }
    else {      
      setModalDelete(false);
      notifyAlert("danger", lang("MESSAGE"), res.error.message);
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("SERVICES")} </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("NAME")} </label>
                        <Input
                          disabled={(![1,2].includes(role))}
                          value={name}
                          className="form-control"
                          onChange={val => setName(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("CODE")} </label>
                        <Input
                          disabled={(![1,2].includes(role))}
                          value={code}
                          className="form-control"
                          onChange={val => setCode(val.target.value)}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> {lang("DESCRIPTION")}{' '}{lang("OPTIONAL")} </label>
                        <textarea
                          disabled={(![1,2].includes(role))}
                          id='txtArea'
                          className="form-control"
                          value={description}
                          onChange={val => setDescription(val.target.value)}>
                        </textarea>
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("ACTIVE")} </label>
                        <select
                          disabled={(![1,2].includes(role))}
                          className="form-control"
                          value={active}
                          onChange={val => setActive(val.target.value)}
                        >
                          <option value="1">{lang("YES")}</option>
                          <option value="0">{lang("NO")}</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        {
                          (loading) ?
                          <>
                            <Spinner style={{color: getColor(1)}} />
                          </>:
                          <>
                            <Button color="gray" to="/admin/gomap/services" tag={Link}> {lang("BACK")} </Button>

                            {
                              (Object.keys(data).length === 0) ? <></> :
                                <> 
                                  {
                                    ([1,2].includes(role)) ? <> 
                                      <Button color="danger"
                                        onClick={() => toggleDelete()}> {lang("DELETE")} </Button>
                                      <Button
                                        style={{
                                          backgroundColor: getColor(1),
                                          color: getColor(2),
                                          border: 0
                                        }}
                                        onClick={() => updateServices()}
                                        > {lang("SAVE")}
                                      </Button>                                    
                                    </> : <></>
                                  }
                                </>
                              }                          
                          </>
                        }
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
 
          </div>
        </Row>
	     
        <Modal isOpen={modalDelete} toggle={toggleDelete}>
          <ModalHeader toggle={() => setModalDelete()}> {lang("MESSAGE")} </ModalHeader>
          <ModalBody>
            {lang("SERVICES_TXT_DELETE")}	
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModalDelete()}>{lang("CANCEL")}</Button>
            <Button color="danger" 
              onClick={() => deleteServices()}> {lang("DELETE")} </Button>
          </ModalFooter>
        </Modal>

      </Container>

    </>
  )
}

export default EditServices;