import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

//BANK
export async function getBankAccountsData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.bank_accounts, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getGoZelleConfigData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.gozelle_config, { 
      headers: {
        'token': token
      }
    });

    return res.data;
  }
  catch(err) {

  }
}

export async function setAccountActiveData(id, value) {
  const token = getToken('GoSuiteToken');

  const jsn = {
    "id": id,
    "value": value
  }

	try {
		const res = await axios.put(CONS.bank_accounts, jsn,  {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

//PLAID API
export async function createLinkToken() {
  const token = getToken('GoSuiteToken');
  try {
    const res = await axios.get(CONS.createLinkToken, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setPictureGoZelleCustomize(comImg) {
	const token = getToken('GoSuiteToken');

  const formData = new FormData();
  formData.append('file', comImg.file, comImg.file.name);

	try {
		const res = await axios.put(CONS.gozelle_image, formData,  {
      headers: {
        'token': token,
				'Content-type': 'multipart/form-data'
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function exchangePublicToken(institution_id, name, public_token, metadata_accounts) {
  const token = getToken('GoSuiteToken');

  const body = {
    name: name,
    institution_id: institution_id,
    public_token: public_token,
    metadata_accounts: metadata_accounts
  }
  
  try {
    const res = await axios.post(CONS.exchangePublicToken, body, { 
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(error) {
    return error;
  }
}

//PLAID LINK - UPDATE MODE
export async function updateLinkToken(id) {
  const token = getToken('GoSuiteToken');
  
  const body = {
    BankCredentialID: id
  }

  try {
    const res = await axios.post(CONS.updateLinkToken, body, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(error) {
    return error;
  }
}

export async function updatePlaidItem(id) {
  const token = getToken('GoSuiteToken');

  const body = {
    BankCredentialID: id
  }

  try {
    const res = await axios.put(CONS.updatePlaidItem, body, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(error) {
    return error;
  }
}

export async function removePlaidItem(id) {
  const token = getToken('GoSuiteToken');
  console.log(token);

  try {
    const res = await axios.delete(`${CONS.removePlaidItem}/${id}`, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(error) {
    return error;
  }
}

export async function accountUpdate(id, active) {
  const token = getToken('goSuiteToken');
  
  const body = {
    BankCredentialID: id,
    active: active
  }
  
  try {
    const res = await axios.put(CONS.accountUpdate, body, {
      headers: {
        'token': token
      }
    });
    return res.data;
  }
  catch(error) {
    return error;
  }
}

export async function getCompanyNotificationsConfig() { 
  const token = getToken('GoSuiteToken'); 
  try {
		const res = await axios.get(CONS.notificationsConfig, {
      headers: {
        'token': token
      }
    });
  
		return res.data;
	}
	catch(err) {
		return err;
	}
} 

export async function updateCompanyNotificationsConfig(config) {
  const token = getToken('GoSuiteToken');  
  
  const body = {
    "supportEmail": config.supportEmail,      
    "messageNewText": config.messageNewText,    
    "messageInProcessText": config.messageInProcessText,    
    "messageApproveText": config.messageApproveText,    
    "messageDeclinedText": config.messageDeclinedText     
  }

  try {
		const res = await axios.put(CONS.notificationsUpdate, body, {
      headers: {
        'token': token
      }
    });
  
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setGoZelleConfigData(jsn) {
  const token = getToken('GoSuiteToken');  
  
  try {
		const res = await axios.put(CONS.gozelle_config, jsn, {
      headers: {
        'token': token
      }
    });
  
		return res.data;
	}
	catch(err) {
		return err;
	}
}

