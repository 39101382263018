import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

export async function setMapAreaData(jsn) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.post(CONS.map, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getAllMapAreaData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.map_area, { 
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getAllMapAreaByIdCityData(id) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.map_area}/city/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getMapAreaByIdData(id) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.map}/company/area/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateMapAreaData(id, jsn) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.put(`${CONS.map_area}/${id}`, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function deleteMapAreaData(id) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.delete(`${CONS.map_area}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getAreaByTextData(txt) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.map_area}/search/${txt}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getAllMapAreaPageData(page) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.map_area}/page/${page}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getAllMapAreaRoutesData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.map_routes, { 
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}