/* global */
import Welcome from "views/welcome/Welcome";
import Security from "views/security/Security";
import Profile from "views/profile/Profile";
import Enterprise from "views/admin/enterprise/Enterprise";
import NewEnterprise from "views/admin/enterprise/NewEnterprise";
import EnterpriseDetail from "views/admin/enterprise/EnterpriseDetail";

/* GoPay */
import Client from "views/gopay/client/Client";
import NewClient from "views/gopay/client/NewClient";
import EditClient from "views/gopay/client/EditClient";
import Email from "views/gopay/email/Email";
import Contractor from "views/gopay/contractor/Contractor";
import NewContractor from "views/gopay/contractor/NewContractor";
import EditContractor from "views/gopay/contractor/EditContractor";

/* GoMap */
import Dashboard from "views/gomap/dashboard/Dashboard";
import Map from "views/gomap/map/Map";
import NewMap from "views/gomap/map/NewMap";
import EditMap from "views/gomap/map/EditMap";
import State from "views/gomap/state/State";
import NewState from "views/gomap/state/NewState";
import EditState from "views/gomap/state/EditState";
import City from "views/gomap/city/City";
import NewCity from "views/gomap/city/NewCity";
import EditCity from "views/gomap/city/EditCity";
import Layer from "views/gomap/layers/Layers";
import EditLayers from "views/gomap/layers/EditLayers";
import Services from "views/gomap/services/Services";
import NewServices from "views/gomap/services/NewServices";
import EditServices from "views/gomap/services/EditServices";
import Draw from "views/gomap/draw/Draw";
import NewDraw from "views/gomap/draw/NewDraw";
import EditDraw from "views/gomap/draw/EditDraw"
import GoMapPersonalization from 'views/gomap/personalization/Personalization';
import GoMapSettings from "views/gomap/settings/Settings";

/* GoZelle */
import Payments from "views/gozelle/payments/Payments";
import PaymentsDetails from "views/gozelle/payments/PaymentsDetails";
import Bank from 'views/gozelle/bank/Bank';
import Personalization from 'views/gozelle/personalization/Personalization';
import GoZelleSettings from "views/gozelle/settings/Settings";
import Pause from "views/gopay/pause/Pause";

export const routes = [
  /** ADMIN **/
  {
    path: "/",
    name: "Init",
    layout: "/admin",
    component: Welcome,
    access: 0
  },
  {
    path: "/profile",
    name:"Profile",
    component: Profile,
    layout: "/admin",
    access: 0
  },
  {
    path: "/security",
    name:"Security",
    component: Security,
    layout: "/admin",
    access: 0
  },
  {
    path: "/enterprise",
    name:"Enterprise",
    component: Enterprise,
    layout: "/admin",
    access: 1
  },
  {
    path: "/enterprise/new",
    name:"Enterprise",
    component: NewEnterprise,
    layout: "/admin",
    access: 1
  },
  {
    path: "/enterprise/:id",
    name:"Enterprise",
    component: EnterpriseDetail,
    layout: "/admin",
    access: 1
  },
  {
    path: "/gomap/dashboard",
    name: "Map",
    layout: "/admin",
    component: Dashboard,
    access: 2
  },
  {
    path: "/gomap/map",
    name: "Map",
    layout: "/admin",
    component: Map,
    access: 2
  },
  {
    path: "/gomap/map/new",
    name: "Map",
    component: NewMap,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gomap/map/:id",
    name: "Map",
    component: EditMap,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gomap/layer",
    name: "Layer",
    component: Layer,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gomap/layer/:id",
    name: "Layer",
    component: EditLayers,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gomap/state",
    name: "State",
    component: State,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gomap/state/new",
    name: "State",
    component: NewState,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gomap/state/:id",
    name: "State",
    component: EditState,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gomap/city",
    name: "City",
    component: City,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gomap/city/new",
    name: "City",
    component: NewCity,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gomap/city/:id",
    name: "City",
    component: EditCity,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gomap/services",
    name: "Services",
    component: Services,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gomap/services/new",
    name: "Services",
    component: NewServices,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gomap/services/:id",
    name: "Services",
    component: EditServices,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gomap/draw",
    name: "Draw",
    component: Draw,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gomap/draw/new",
    name: "Draw",
    component: NewDraw,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gomap/draw/:id",
    name: "Draw",
    component: EditDraw,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gomap/draw",
    name: "Draw",
    component: Draw,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gomap/personalization",
    name:"personalization",
    component: GoMapPersonalization,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gomap/settings",
    name:"Settings",
    component: GoMapSettings,
    layout: "/admin",
    access: 2
  },
  {
    path: "/gozelle/bank",
    name:"Bank",
    component: Bank,
    layout: "/admin",
    access: 3
  },
  {
    path: "/gozelle/personalization",
    name:"personalization",
    component: Personalization,
    layout: "/admin",
    access: 3
  },
  {
    path: "/gozelle/settings",
    name:"Settings",
    component: GoZelleSettings,
    layout: "/admin",
    access: 3
  },
  {
    path: "/gozelle/payments",
    name:"Payments",
    component: Payments,
    layout: "/admin",
    access: 3
  },
  {
    path: "/gozelle/payments/:id",
    name:"Payments",
    component: PaymentsDetails,
    layout: "/admin",
    access: 3
  },
  {
    path: "/gopay/client",
    name:"Payments",
    component: Client,
    layout: "/admin",
    access: 4
  },
  {
    path: "/gopay/client/new",
    name:"Payments",
    component: NewClient,
    layout: "/admin",
    access: 4
  },
  {
    path: "/gopay/client/:id",
    name:"Payments",
    component: EditClient,
    layout: "/admin",
    access: 4
  },
  {
    path: "/gopay/email",
    name:"Payments",
    component: Email,
    layout: "/admin",
    access: 4
  },
  {
    path: "/gopay/contractor",
    name:"Payments",
    component: Contractor,
    layout: "/admin",
    access: 4
  },
  {
    path: "/gopay/contractor/new",
    name:"Payments",
    component: NewContractor,
    layout: "/admin",
    access: 4
  },
  {
    path: "/gopay/contractor/:id",
    name:"Payments",
    component: EditContractor,
    layout: "/admin",
    access: 4
  },
  {
    path: "/gopay/pause",
    name:"Payments",
    component: Pause,
    layout: "/admin",
    access: 4
  },
];
