/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { Row, Button, Input, Col, FormGroup } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import lang from 'lang';
import { getColor } from "utils/token";
import { getGoZelleChargebeeConfig } from "../../../../api/company.js";

const Standard = () => {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({load: true, goZelle: false });
  const [apikey, setApiKey] = useState('');
  const [scriptVersion, setScriptVersion] = useState('');
  const [integration, setIntegration] = useState({domain_url:'', is_taxable: false});
  const [aTag, setATag] = useState({text: 'Presionar'});
  const [publicName, setPublicName] = useState('');

  useEffect(() => {
    getGoZelleCompanyBilling();
    setLoading({...loading, load:false});
  },[]);
  
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const getGoZelleCompanyBilling = async () => {
    const res = await getGoZelleChargebeeConfig();
    console.log("goZelle config ===>", res);
    let config_integration = JSON.parse(res.data.integration)[0];
    setApiKey(res.data.api_key);
    setIntegration(config_integration);
    setPublicName(res.data.public_name);
    setScriptVersion(res.data.script_version);
  }

  const copyClipboard = async (site) => { 
    var copyText = `<script async src="https://cdn.zelle.cloud/scripts/${scriptVersion}" data-gz-key="${publicName}" data-gz-env="develop"></script>`;
    const elem = document.createElement('textarea');
    elem.value = copyText;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
    notifyAlert("default", lang("MESSAGE"), lang("COPY_CLIPBOARD"));
  }

  const copyClipboardATag = async () => {
    const copyText = `<a href="javascript:void(0)" id="goZelle">${aTag.text}</a>`;
    const elem = document.createElement('textarea');
    elem.value = copyText;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
    notifyAlert("default", lang("MESSAGE"), lang("COPY_CLIPBOARD"));
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <div className="tabInside">
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label">GoZelle Api key</label>
              <Input 
                readOnly={true}
                value={apikey}/>
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <label className="form-control-label">{lang("DOMAIN")}</label>
              <Input 
                value={integration.chargebee_site}
                readOnly={true} />

              <code style={{
                fontSize: '14px',
                marginTop: '2px',
                color: '#cb1616'
              }}>
                {`<script async src="https://cdn.zelle.cloud/scripts/${scriptVersion}" data-gz-key="${publicName}" data-gz-env="develop"></script>`} 
              </code>
            </FormGroup>
            <div className="float-right">
              <Button
                style={{
                  backgroundColor: getColor(1),
                  color: getColor(2),
                  border: 0
                }}
                onClick={() => copyClipboard()}
                > {lang("COPY")}
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md='12'>
            <label
              style={{
                fontSize: '1.06rem',
                color: '#32325d',
                fontWeight: 'bold'
              }}
              className="navbar-heading">{lang("GEN_SUB_BTN")}</label>
          </Col>
          
          <Col md='6'>
            <FormGroup>
              <label className="form-control-label">{lang("TEXT")}</label>
              <Input 
                value={aTag.text}
                onChange={val => setATag({...aTag, text: val.target.value})}
              />
            </FormGroup>
          </Col>
          
          <Col md='6' className="align-items-center align-self-center">
            <div style={{
              textAlign: 'center'
            }}>
                <code style={{color: '#cb1616'}}> {`<a href="javascript:void(0)" `} </code><br/>
                <code style={{color: '#cb1616'}}> {`id="goZelle"> ${aTag.text} </a>`} </code><br/>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="float-right">
              <br />
              <Button
                style={{
                  backgroundColor: getColor(1),
                  color: getColor(2),
                  border: 0
                }}
                onClick={() => copyClipboardATag()}
                > {lang("COPY")}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      
    </>
  )
}

export default Standard;