/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Header from "components/Headers/Header";
import {Spinner, Card, Container, CardHeader, Row, Button, Col, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import lang from 'lang';
import { getAllCompanyStateData, setCityData } from "api/places";
import { getColor } from "utils/token";

var map, marker;

const NewCity = () => {
  const notificationAlertRef = React.useRef(null);
  const [name, setName] = useState('');
  const [selState, setSelState] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [zoom, setZoom] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllState();
    initializeMap();
  },[]);
 
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  
  const getAllState = async () => {
    const res = await getAllCompanyStateData();
    setData(res.data);
  }

  const initializeMap = async (lat, lng) => {
    const _lat = (lat === undefined) ? 6.873422608947892 : lat;
    const _lng = (lng === undefined) ? -66.34430341114883 : lng;

    try {
      map = new google.maps.Map(document.getElementById('map'), {
        zoom: 6,
        center: new google.maps.LatLng(_lat, _lng),
        zoomControl: true,
        mapTypeControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false
      });
    }
    catch(err) {
      console.log(err);
    }

    google.maps.event.addListener(map, 'click', function(event) {
      setMarkerLatLng(event);
    });

    google.maps.event.addListener(map, 'zoom_changed', function() {
      var z = map.getZoom();
      setZoom(z);
    });
  }

  const getPositionByState = (val) => {
    const _state = data.find( d => {
      return d.id === Number(val)
    })

    setSelState(val);
    setZoom(_state.zoom);
    setLatitude(_state.latitude);
    setLongitude(_state.longitude);
    initializeMap(_state.lat, _state.lng);
    setMarkerLatLngDym(_state.lat, _state.lng)
  }

  const setMarkerLatLng = (pos) => {
    if(marker !== undefined) {
      marker.setMap(null);
    }

    setLatitude(pos.latLng.lat());
    setLongitude(pos.latLng.lng());

    const latLng = {
      lat: pos.latLng.lat(),
      lng: pos.latLng.lng()
    }

    marker = new google.maps.Marker({
      position: latLng,
      map,
    });
  }

  const setMarkerLatLngDym = (lat, lng) => {
    if(marker !== undefined) {
      marker.setMap(null);
    }
    
    setLatitude(lat);
    setLongitude(lng);

    const latLng = {
      lat: lat,
      lng: lng
    }

    marker = new google.maps.Marker({
      position: latLng,
      map,
    });
  }
  
  const addCity = async () => {
    setLoading(true);

    if(name.trim() === '') {
      notifyAlert("default", lang("MESSAGE"), lang("CITY_NAME"));
    }
    else if(selState === '') {
      notifyAlert("default", lang("MESSAGE"), lang("STATE_EMPTY"));
    }
    else {
      const res = await setCityData(name, selState, latitude, longitude, zoom);

      if(res.success === true) {
        notifyAlert("success", lang("MESSAGE"), res.data);
        window.location.href = '/admin/gomap/city';
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
    }

    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

            <CardHeader className="border-0">
              <Row>
                <div className="col mb-0 float-left">
                  <h3> {lang("CITY")} </h3>
                </div>
              </Row>
            </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("NAME")} </label>
                        <Input 
                          value={name}
                          placeholder={lang("NAME")}
                          onChange={(val) => setName(val.target.value)} 
                        />
                      </FormGroup>

                      <FormGroup>
                        <label className="form-control-label"> {lang("SEL_STATE")} </label>
                        <select
                          className="form-control"
                          value={selState}
                          onChange={val => getPositionByState(val.target.value)}
                        >
                          <option>{lang("SELECT")}</option>
                          {
                            data.map( (s, i) => (
                              <option 
                                key={i}
                                value={s.id}>{s.name}</option>
                            ))
                          }
                        </select>
                      </FormGroup>

                      <FormGroup>
                        <label className="form-control-label"> {lang("LATITUDE")} </label>
                        <Input 
                          readOnly={true}
                          value={latitude}
                          placeholder={lang("LATITUDE")} />
                      </FormGroup>

                      <FormGroup>
                        <label className="form-control-label"> {lang("LONGITUDE")} </label>
                        <Input 
                          readOnly={true}
                          value={longitude}
                          placeholder={lang("LONGITUDE")} />
                      </FormGroup>

                      <FormGroup>
                        <label className="form-control-label"> {lang("ZOOM")} </label>
                        <Input 
                          readOnly={true}
                          value={zoom}
                          placeholder={lang("ZOOM")}
                          onChange={(val) => setLongitude(val.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <label className="form-control-label"> {lang("MAP")} </label>
                      <div id="map" style={{
                        height: '400px',
                        width: '100%',
                        border: '1px solid #444'
                      }}></div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg='12'>
                      <span  style={{fontSize: '13px' }}
                        className="text-muted">{lang("CITY_MSG")}</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                      {
                        (loading) ? <Spinner style={{color: getColor(1)}}/> :
                        <div>
                          <Button color="gray" to="/admin/gomap/city" tag={Link}> {lang("BACK")} </Button>
                          <Button
                            style={{
                              backgroundColor: getColor(1),
                              color: getColor(2),
                              border: 0
                            }}
                            onClick={() => addCity()}
                            > {lang("SAVE")}
                          </Button>
                        </div>
                      }
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>

      </Container>

    </>
  )
}

export default NewCity;