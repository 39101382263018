/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Spinner, Card, Table, Badge, Button, Input, InputGroupAddon, InputGroup, InputGroupText} from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import lang from 'lang';
import {getRoles, getUsersData, registerNewUser, updateUsersData } from 'api/admin';
import { getColor } from "utils/token";
import NoLogo from './../../../../assets/img/noLogo2.png';
import NotificationAlert from "react-notification-alert";
import { useParams } from "react-router-dom";

export default function Users() {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [dataSearch, setDataSearch] = useState([]);
  const [search, setSearch] = useState('');
  const [toggle, setToggle] = useState(false);
  const [toggleDetail, setToggleDetail] = useState(false);
  const [detailInfo, setDetailInfo] = useState({});
  const [roles, setRoles] = useState([]);
  const [userName, setUserName] = useState('');
  const [userRole, setUserRole] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [btnLoad, setBtnLoad] = useState(false);
  const {id: company_id} = useParams();
 
  const notificationAlertRef = React.useRef(null);
    
  useEffect( () => {
    getUsers();
    getUserRoles();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const getUsers = async () => {
    setLoading(true);
    const res = await getUsersData(company_id);
    console.log("COMPANY_USERS ===>", res);

    if(res.success === true) {
      setError(false);
      setData(res.data);
      setDataSearch(res.data);
    }
    else {
      setError(true);
      setData([]);
      setDataSearch([]);
    }
    setLoading(false);
  }

  const handleSearch = (text) => {
    if (text) {
      const newData = data.filter( (item) => {
        const itemData = item.UserName
          ? item.UserName.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setSearch(text);
      setDataSearch(newData);
    } 
    else {
      setSearch(text);
      setDataSearch(data);
    }   
  }

  const checkBlock = (i) => {
    return (i === 0) ? <Badge className='badgePadding' color="danger">{lang("NO")}</Badge>:
    <Badge className='badgePadding' color="success">{lang("YES")}</Badge>;
  }

  const handleToggle = () => setToggle(!toggle);

  const handleToggleDetail = () => {
    setToggleDetail(!toggleDetail);
    setDetailInfo({});
  }

  const getUserRoles = async () => {
    const data = await getRoles();
    console.log("ROLES_DATA ===>", data);
    setRoles(data.data);
  }

  const registerUser = async () => {
    setBtnLoad(true);
    
    let jsn = {
      "username": userName,
      "company_rol": userRole,
      "email": userEmail,
      "company_id": company_id
    }

    const response = await registerNewUser(jsn);
    setUserName('');
    setUserRole('');
    setUserEmail('');
    setToggle(!toggle);
    setBtnLoad(false);

    if(response.success) {
      notifyAlert("success", lang("MESSAGE"), response.data);
      getUsers();
    }
    else {
      notifyAlert("danger", lang("MESSAGE"), response.error.message);
    }
    setBtnLoad(false);
  }

  const showUserDetails = (user) => {
    setDetailInfo(user);
    console.log("USER_DATA ===>", user);
    setToggleDetail(true);
  }

  /*
  const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			reader.onload = (e) => {
				setPicture(e.target.result);
			};

			reader.readAsDataURL(event.target.files[0]);
			setFile(event.target.files[0]);
		}
	}

const savePicture = async () => {
    setLoading({...loading, picture: true});

    let fileCompress = null;
    
    if(picture === '') {
      notifyAlert('danger', lang("EMPTY_IMAGE"));
    }
    else {
      fileCompress = await renderImageToSend(file);
            
      const res = await setPictureGoZelleCustomize(fileCompress);

      if(res.success === true) { 
        console.log(res.data);
        notifyAlert('success', res.data);
      }
      else {
        notifyAlert('danger', res.error.message);
      }
    }
    
    setLoading({...loading, picture: false});
    console.log("SAVE DATA");
  }
  */

  const updateUserData = async (userData) => {
    let jsn = {
      "CompanyUsersID": userData.CompanyUsersID,
      "CompanyRolID": userData.CompanyRolID,
      "UserName": userData.UserName,
      "Email": userData.Email,
      "IsActive": userData.IsActive
    }

    const response = await updateUsersData(jsn);
    console.log("UPDATE_RESPONSE ===>", response);

    if(response.success) {
      notifyAlert("success", lang("MESSAGE"), response.data);
      getUsers();
      handleToggleDetail();
    }
    else {
      notifyAlert("danger", lang("MESSAGE"), response.error.message);
    }
    
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <div style={{padding: '10px'}}>
        <Card style={{boxShadow: '0 0 0 0'}}>

          <div style={{marginBottom: '10px'}}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText><i className="fas fa-search"></i></InputGroupText>
              </InputGroupAddon>
              <Input
                value={search}
                style={{paddingLeft: '5px', borderRadius: '0 0.375rem 0.375rem 0'}}
                placeholder={lang("SEARCH")}
                onChange={(val) =>  handleSearch(val.target.value)}
              />
              <div className="col">
                <Button 
                  className="float-right btn-sm"
                  style={{
                    backgroundColor: getColor(1),
                    color: getColor(2),
                    border: 0,
                    marginTop: '10px'
                  }}
                  onClick={handleToggle}
                >
                  {lang("NEW_USER")}
                </Button>
              </div>
            </InputGroup>
          </div>

          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr className = "text-center">
                <th scope="col"> {lang("USER_PICTURE")} </th>
                <th scope="col"> {lang("NAME")} </th>
                <th scope="col"> {lang("EMAIL")} </th>
                <th scope="col"> {lang("ROLE")} </th>
                <th scope="col"> {lang("ACTIVE")} </th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {
                (loading) ?
                <tr>
                  <td colSpan={10} className='text-center'>
                    <Spinner style={{color: getColor(1)}} />
                  </td>
                </tr>:
                (error) ? 
                <tr>
                  <td colSpan={10} className='text-muted text-center'>
                    <label>{lang("ERROR_LOAD")}</label>
                    <br />
                    <Button 
                      color="secondary"
                      onClick={() => getUsers()}>{lang("LOAD")}</Button>
                  </td>
                </tr>:
                (data.length === 0) ? 
                <tr>
                  <td colSpan={10} className='text-muted text-center'> {lang("NO_USERS_REGISTERED")} </td>
                </tr>
                :
                dataSearch.map( (d,i) => (
                  <tr className = "text-center" key={i}>
                    <td>
                      {
                        (d.Picture === null) ? <>
                          <img 
                            style={{
                              width: '90px',
                              height: '90px',
                              objectFit: 'scale-down',
                              borderRadius: '50%'
                            }} 
                            alt={d.UserName} src={NoLogo} />
                        </>: <>
                          <img 
                            style={{
                              width: '90px',
                              height: '90px',
                              objectFit: 'scale-down',
                              borderRadius: '50%'
                            }} 
                            alt={d.UserName} src={d.Picture} />
                        </>
                      }
                    </td>
                    <td>
                      {d.UserName}
                    </td>
                    <td>
                      {d.Email}
                    </td>
                    <td>
                      {d.Role}
                    </td>
                    <td>
                      {checkBlock(d.IsActive)}
                    </td>
                    <td>
                      <Button 
                        color="secondary"
                        onClick={() => showUserDetails(d)} 
                      > 
                        {lang('EDIT')} 
                      </Button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Card>
      </div>

      {/*Modal for User Registration*/}
      <Modal isOpen={toggle}>
        <ModalHeader>{lang("NEW_USER")}</ModalHeader>
      
          <ModalBody>
        
            <FormGroup>
              <label className="form-control-label">{lang("USERNAME")}</label>
              <Input 
                placeholder={lang("NEW_USERNAME")}
                onChange={val => setUserName(val.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">{lang("ROL_TYPE")}</label>
              <select
                className="form-control"
                value={userRole}
                onChange={val => setUserRole(val.target.value)}
              >
                <option>{lang("SELECT")}</option>
                  {
                    roles.map((role, i) => (
                      <option 
                        key={i}
                        value={role.CompanyRolID}
                      >
                        {role.Name}
                      </option>
                    ))
                  }
              </select>
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">{lang("EMAIL")}</label>
              <Input 
                placeholder={lang("EMAIL")}
                onChange={val => setUserEmail(val.target.value)}
              />
            </FormGroup>

          </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={handleToggle}>{lang("CANCEL")}</Button>
          <Button 
            onClick={registerUser} 
            style={{
              backgroundColor: getColor(1),
              color: getColor(2),
              border: 0,
              marginTop: '10px',
              width: '100px'
            }}
          >
            {(btnLoad) ? <Spinner className="spinner-border-sm spinner" style={{fontSize: '10px'}}/> : lang("SAVE")}
          </Button>
        </ModalFooter>
      </Modal>
      {/*Modal for User Registration*/}


      {/*Modal to edit user data*/}
      <Modal isOpen={toggleDetail}>
        <ModalHeader>{lang("USER")}</ModalHeader>
      
          <ModalBody>
            {/*
            <FormGroup>
              <label className="form-control-label">{lang("USER_PICTURE")}</label>

              <Row>
                <Col md="12">
                  <ul style={{
                    padding: '0',
                    listStyle: 'none',
                    display: 'inline-flex'
                  }}>
                    <li>
                      {
                        (detailInfo.Picture === null) ? 
                          <img 
                            style={{
                              width: '90px',
                              height: '90px',
                              objectFit: 'scale-down',
                              borderRadius: '50%',
                            }} 
                            alt={detailInfo.UserName} 
                            src={NoLogo} 
                          />  
                        :
                        <img 
                          style={{
                            width: '90px',
                            height: '90px',
                            objectFit: 'scale-down',
                            borderRadius: '50%',
                          }} 
                          alt={detailInfo.UserName} 
                          src={detailInfo.Picture} 
                        />
                      }
                    </li>
                    
                    <li>
                      { 
                        <ul style={{
                          padding: '0',
                          listStyle: 'none',
                          display: 'inline-flex',
                          position: 'relative',
                          top: '50%',
                          transform: 'translateY(-50%)'
                        }}>
                          <li>
                            <label className="custom-file-upload" style={{marginTop: '0px'}}>
                              <input 
                                type="file"
                                accept="image/*"
                              />
                              {lang("EDIT")}
                            </label>
                          </li>

                          <li>
                            <Button
                              style={{
                                backgroundColor: '#6c757d',
                                color: '#fff',
                                border: 0
                              }}
                            > {lang("SAVE")}
                            </Button>
                          </li>
                        </ul>
                      }
                    </li>
                  </ul>
                </Col>
              </Row>
            </FormGroup>
            */}
            <FormGroup>
              <label className="form-control-label">{lang("NAME")}</label>
              <Input 
                value={detailInfo.UserName}
                onChange= {(e) => setDetailInfo({...detailInfo, UserName: e.target.value})}
              />
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">{lang("EMAIL")}</label>
              <Input 
                value={detailInfo.Email}
                onChange={(e) => setDetailInfo({...detailInfo, Email: e.target.value})}
              />
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">{lang("ROLE")}</label>
              <select
                className="form-control"
                value={detailInfo.CompanyRolID}
                onChange={(e) => setDetailInfo({...detailInfo, CompanyRolID: e.target.value})}
              >
                <option>{lang("SELECT")}</option>
                  {
                    roles.map((role, i) => (
                      <option 
                        key={i}
                        value={role.CompanyRolID}
                      >
                        {role.Name}
                      </option>
                    ))
                  }
              </select>
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">{lang("ACTIVE")}</label>
              <select
                className="form-control"
                value={detailInfo.IsActive}
                onChange={(e) => setDetailInfo({...detailInfo, IsActive: e.target.value})}
              >
                <option value="">{lang("SELECT")}</option>
                <option value="1">{lang("YES")}</option>
                <option value="0">{lang("NO")}</option>
              </select>
            </FormGroup>
          </ModalBody>

        <ModalFooter>
          <Button 
            onClick={handleToggleDetail} 
            className="secondary"
            style={{
              marginTop: '10px',
              width: '100px',
              border: 0
            }}
          >
            {lang("CANCEL")}
          </Button>

          <Button 
            onClick={() => updateUserData(detailInfo)} 
            style={{
              backgroundColor: getColor(1),
              color: getColor(2),
              border: 0,
              marginTop: '10px',
              width: '100px'
            }}
          >
            {lang("ACCEPT")}
          </Button>
        </ModalFooter>
      </Modal>
      {/*Modal to edit user data*/}
    </>
  )
}
