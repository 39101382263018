import React from "react";
import ReactDOM from "react-dom";
//import * as Sentry from "@sentry/react";
//import { BrowserTracing } from "@sentry/tracing";
//import { getEnviroment } from './utils/enviroment';

import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";
import "assets/css/global.css";
import App from './App';

/*
Sentry.init({
  dsn: "https://3647109910c7429484068c793e8dae4d@o1137737.ingest.sentry.io/6190940",
  integrations: [new BrowserTracing()],
  environment: getEnviroment(),
  tracesSampleRate: 1.0
});
*/

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
