import React from 'react';
import { Tooltip } from 'reactstrap';
import lang from 'lang';

export default function Title({active, title, toggle}) {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const toggleToolTip = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <div>
        <ul style={{
          padding: '0',
          listStyle: 'none',
          display: 'flex',
          marginTop: '15px'
        }}>
          {
            (!active) ? 
              <li>
                <i style={{color: 'red', marginLeft: '10px'}} href="#" id="TooltipExample" className="fas fa-exclamation-triangle"></i>
                <Tooltip placement="top" isOpen={tooltipOpen} target="TooltipExample" toggle={toggleToolTip}>
                  {lang("LOST_ACCOUNT")}
                </Tooltip>
              </li>  
              :<></>
          }
          {
            (!active) ? 
            <li style={{
              marginLeft: '5px',
              fontSize: '19px',
              fontWeight: '600',
              lineHeight: '1.3',
              cursor: 'pointer',
            }}>{title}</li>
            :
            <li style={{
              marginLeft: '5px',
              fontSize: '19px',
              fontWeight: '600',
              lineHeight: '1.3',
              cursor: 'pointer',
            }} onClick={toggle}>{title}</li>
          }
        </ul>
      </div>
    </>
  )
}

