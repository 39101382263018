import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

export async function getDrawTypeData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.draw, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setDrawTypeData(jsn) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.post(CONS.draw, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getDrawTypeByIdData(id) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.draw}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updDrawTypeData(id, jsn) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.put(`${CONS.draw}/${id}`, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function deleteDrawTypeData(id) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.delete(`${CONS.draw}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}