import React from 'react';
import { Link } from "react-router-dom";
import {Card, Container, Row, Button} from "reactstrap";
import Header from "components/Headers/Header";
import { getColor } from 'utils/token';

export default function NotFound() {

  return (
    <>
      <Header />
      
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card>
              <div style={{
                textAlign: 'center'
              }}>
                <img alt="...." src={require('../../assets/img/404.jpg').default} 
                style={{
                  padding: '10px',
                  width: '70%',
                }}/>
              </div>
              <div style={{
                textAlign: 'center',
                marginBottom: '30px'
              }}>
                <Button style={{
                  backgroundColor: getColor(1),
                  color: getColor(2)
                }}
                color="primary" to="/admin/" tag={Link}> Back to home </Button>  
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
