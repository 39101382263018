import React, {useEffect, useState} from 'react';
import { Spinner, Card, Container, Row, CardHeader, CardBody } from "reactstrap";
import Header from "components/Headers/Header";
import lang from 'lang';
import { getColor } from "utils/token";
import { getCompanyBillingSystemData } from "api/company";
import Standard from './components/standard';
import Enterprise from './components/enterprise';

const Settings = () => {
  const [loading, setLoading] = useState(true);
  const [billing_system, setBillingSystem] = useState(0);

  useEffect(() => {
    getCompanyBillingSystem();
  },[]);
  

  const getCompanyBillingSystem = async () => {
    const res = await getCompanyBillingSystemData();
    setBillingSystem(res.data.billing);
    setLoading(false);
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3>{lang("SETTINGS")}</h3>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {
                  (loading) ? 
                  <div className="text-center">
                    <Spinner style={{
                      color: getColor(1)
                    }} />
                  </div>
                  :
                  (billing_system === 2) ? <> <Enterprise /> </> : <Standard />
                }
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

    </>
  )
}

export default Settings;