import React, {useState} from 'react';
import { Link } from "react-router-dom";
import {Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup } from "reactstrap";
import Header from "components/Headers/Header";
import lang from 'lang';
import NotificationAlert from "react-notification-alert";
import { setServicesData } from "api/services";
import { getColor } from "utils/token";

const NewServices = () => {
  const notificationAlertRef = React.useRef(null);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [description, setDescription] = useState('');
  const [active, setActive] = useState('');

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const addServices = async () => {
    const _name = name.trim();
    const _code = code.trim();

    if(_name === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_NAME_SERVICES"));
    }
    else if(_code === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_CODE_SERVICES"));
    }
    else {
      const res = await setServicesData(_name, _code, description, active);

      if(res.success === true) {
        notifyAlert("success", lang("MESSAGE"), res.data);
        window.location.href = '/admin/gomap/services';
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
    }
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

            <CardHeader className="border-0">
              <Row>
                <div className="col mb-0 float-left">
                  <h3> {lang("SERVICES")} </h3>
                </div>
              </Row>
            </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("NAME")} </label>
                        <Input
                          value={name}
                          className="form-control"
                          onChange={val => setName(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("CODE")} </label>
                        <Input
                          value={code}
                          className="form-control"
                          onChange={val => setCode(val.target.value)}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> {lang("DESCRIPTION")}{' '}{lang("OPTIONAL")} </label>
                        <textarea
                          id='txtArea'
                          className="form-control"
                          value={description}
                          onChange={val => setDescription(val.target.value)}>
                        </textarea>
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("ACTIVE")} </label>
                        <select
                          className="form-control"
                          value={active}
                          onChange={val => setActive(val.target.value)}
                        >
                          <option value="1">{lang("YES")}</option>
                          <option value="0">{lang("NO")}</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        <Button color="gray" to="/admin/gomap/services" tag={Link}> {lang("BACK")}</Button>

                        <Button
                          color="primary"
                          style={{
                            backgroundColor: getColor(1),
                            color: getColor(2),
                            border: 0
                          }}
                          onClick={() => addServices()}
                          > {lang("SAVE")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default NewServices;