/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Spinner, Row, Button, Input, Col, FormGroup,Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import lang from 'lang';
import { getColor } from "utils/token";
import { getCompanyBillingData, updateURLVersionData, getCompanyCustomFieldData, addCustomFieldsData } from "api/company";
import { getAllActiveServicesData } from 'api/services';

const Standard = () => {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({load: true, save: false, goPay: false, version: false});
  const [apikey, setApiKey] = useState('');
  const [scriptVersion, setScriptVersion] = useState('');
  const [integration, setIntegration] = useState({});
  const [services, setServices] = useState([]);
  const [aTag, setATag] = useState({services: '', plan: '', days: '0', text: 'Presionar'});
  const [show, setShow] = useState(false);
  const [customFieldsData, setCustomFieldsData] = useState([]);
	const [customFields, setCustomFields] = useState({
		type: 'input',
		label: '',
		is_mandatory: 'false',
		API_name: '',
		entity: 'client'
	});
  const [arrFields, setArrFields] = useState([]);
	const [url_data, setUrlData] = useState(false);

  useEffect(() => {
    getCompanyBilling();
    urlParams();
    getServicesInfo();
    getCompanyCustomField();
    setLoading({...loading, load:false});
  },[]);
  
  const toggleShow = () => setShow(!show);
  
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const urlParams = () => {
		const URL = window.location.href;
		const local = String(URL).includes('localhost');
		const dev = String(URL).includes('dev');

		if(local === true) {
			setUrlData(true);
		}
		else if(dev === true) {
			setUrlData(true);
		}
		else {
			setUrlData(false);
		}
  }


  const getCompanyBilling = async () => {
    const res = await getCompanyBillingData();
    console.log(res.data);
    setApiKey(res.data.api_key);
    setScriptVersion(res.data.script);
    setIntegration(JSON.parse(res.data.integration));
  }

  const getServicesInfo = async () => {
    const res = await getAllActiveServicesData();
    setServices(res.data);
  }

  const getCompanyCustomField = async () => {
    const res = await getCompanyCustomFieldData();
    setCustomFieldsData(JSON.parse(res.data.CustomFields));
  }

  const createInput = async () => {
    var _arrFields = [...arrFields];
    _arrFields.push({
      "value": "",
      "text": ""
    });
    setArrFields(_arrFields);
  }

  const deleteRow = (index) => {
    var _arrFields = [...arrFields];
    _arrFields.splice(index, 1);
    setArrFields(_arrFields);
  }

  const handleChange = (index, key, value) => {
    var _arrFields = [...arrFields];
    const data = _arrFields[index];
    data[key] = value;
    _arrFields[index] = data;
    setArrFields(_arrFields);
  }

  const copyClipboard = async (site) => { 
		var copyText;

		if(url_data === true) {
			copyText = `<script async src="https://cdn.gosuite.app/script/${scriptVersion}" data-gm-key="${apikey}" data-gm-site="${integration.domain_url}"></script>`;
		}
		else {
			copyText = `<script async src="https://gosuitedevstorage.blob.core.windows.net/script/${scriptVersion}" data-gm-key="${apikey}" data-gm-site="${integration.domain_url}"></script>`;
		}
		
		const elem = document.createElement('textarea');
		elem.value = copyText;
		document.body.appendChild(elem);
		elem.select();
		document.execCommand('copy');
		document.body.removeChild(elem);
		
		notifyAlert("default", lang("MESSAGE"), lang("COPY_CLIPBOARD"));
  }

  const copyClipboardATag = async () => {
    if(String(aTag.services).trim() === '') {
      notifyAlert("default", lang("MESSAGE"), lang("ATAG_SERVICES"));
    }
    else if(String(aTag.plan).trim() === '') {
      notifyAlert("default", lang("MESSAGE"), lang("ATAG_PLAN"));
    }
    else if(String(aTag.text).trim() === '') {
      notifyAlert("default", lang("MESSAGE"), lang("ATAG_TEXT"));
    }
    else {
      const copyText = `<a href="javascript:void(0)" data-gm-services="${aTag.services}" data-gm-plan="${aTag.plan}" ${(aTag.days !== "") ? `data-gm-indays="${aTag.days}"` : 'data-gm-indays="0"'}>${aTag.text}</a>`;
    
      const elem = document.createElement('textarea');
      elem.value = copyText;
      document.body.appendChild(elem);
      elem.select();
      document.execCommand('copy');
      document.body.removeChild(elem);
      notifyAlert("default", lang("MESSAGE"), lang("COPY_CLIPBOARD"));
    }
  }

  const updateURLVersion = async () => {
    if(integration.url_version === "") {
      notifyAlert("default", lang("MESSAGE"), lang("URL_VERSION"));
    }
    else {
      const res = await updateURLVersionData(integration);

      if(res.success === true) {
        notifyAlert("success", lang("MESSAGE"), res.data);
      }
      else {
        notifyAlert("default", lang("MESSAGE"), res.error.message);
      }
    }
  }

  const getFieldType = (type) => {
    switch(type) {
      case 'input':
        return lang("FIELD_TYPE_NAME");
      
      case 'select':
        return lang("FIELD_TYPE_SELECT");

      default: 
        return 'Error'
    }

  }

  const getEntity = (entity) => {
		switch(entity) {
			case 'client':
				return lang("CLIENT");
				
			case 'subscription':
				return lang("SUBSCRIPTION");

			default: 
				return 'Error'
		}
	}

  const saveCustomFields = async () => {
    if(customFields.label === "") {
			notifyAlert("warning", lang("MESSAGE"), lang("LABEL_NAME_EMPTY"));
		}
		else if(customFields.API_name === "") {
			notifyAlert("warning", lang("MESSAGE"), lang("API_FIELD_NAME_EMPTY"));
		}
		else if(customFields.type === "select" && arrFields.length === 0) {
			notifyAlert("warning", lang("MESSAGE"), lang("FIELD_LIST_VALUES_EMPTY"));
		}
		else {
			var _arrCustomFields = [...customFieldsData];

	    const jsn = {
				"type": customFields.type,
				"label_name": customFields.label,
				"is_mandatory": customFields.is_mandatory,
				"api_name": customFields.API_name,
				"entity": customFields.entity,
				"fields": arrFields
			}

			const	find = getDataFromArray(customFields.API_name)

			if(find === undefined) {
				_arrCustomFields.push(jsn);
			}
			else {
				_arrCustomFields[find] = jsn;
			}

			const sendData = JSON.stringify(_arrCustomFields);

			const res = await addCustomFieldsData(sendData);
			
			if(res.success === true) {
				notifyAlert("success", lang("MESSAGE"), res.data);
				getCompanyCustomField();
				setShow(!show);
				setCustomFields({
					type: 'input',
					label: '',
					is_mandatory: 'false',
					API_name: '',
					entity: ""
				});
				setArrFields([]);
			}
			else {
				notifyAlert("danger", lang("MESSAGE"), res.error.message);
			}
		}
  }

  const editData = (value) => {
    console.log(value);
    const data = [...customFieldsData];
    console.log(data[value]);
    const cf = data[value];

    setCustomFields({
			type: cf.type,
			label: cf.label_name,
			is_mandatory: cf.is_mandatory,
			entity: cf.entity,
			API_name: cf.api_name,
    });
    setArrFields(cf.fields);

    setShow(!show);
  }

  const deleteData = async (value) => {
		var _arrCustomFields = [...customFieldsData];

		_arrCustomFields.splice(value, 1);

		const sendData = JSON.stringify(_arrCustomFields);

		const res = await addCustomFieldsData(sendData);
		
		if(res.success === true) {
			notifyAlert("success", lang("MESSAGE"), res.data);
			getCompanyCustomField();
		}
		else {
			notifyAlert("danger", lang("MESSAGE"), res.error.message);
		}
	}

  const getDataFromArray = (value) => {
		for (var i = 0; i < customFieldsData.length; i++){
			if (customFieldsData[i].api_name === value){
				return i; 
			}
		}
	}

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <div className="tabInside">
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label">GoMap Api key</label>
              <Input 
                readOnly={true}
                value={apikey}/>
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <label className="form-control-label">{lang("DOMAIN")}</label>
              <Input 
                value={integration.site}
                readOnly={true} />
                {
                (url_data) ? <>
                  <code style={{
                    fontSize: '13px',
                    marginTop: '2px',
                    color: '#cb1616'
                  }}>
                    {`<script async src="https://gosuitedevstorage.blob.core.windows.net/script/${scriptVersion}" data-gm-key="${apikey}" data-gm-site="${integration.domain_url}"></script>`} 
                  </code><br /><br />
                  <div className="float-right">
                    <Button
                      style={{
                        backgroundColor: getColor(1),
                        color: getColor(2),
                        border: 0
                      }}
                      onClick={() => copyClipboard(integration.domain_url)}
                      > {lang("COPY")}
                    </Button>
                  </div>
                </> : <>
                  <code style={{
                    fontSize: '13px',
                    marginTop: '2px',
                    color: '#cb1616'
                  }}>
                    {`<script async src="https://cdn.gosuite.app/script/${scriptVersion}" data-gm-key="${apikey}" data-gm-site="${integration.domain_url}"></script>`} 
                  </code><br /><br />
                  <div className="float-right">
                    <Button
                      style={{
                        backgroundColor: getColor(1),
                        color: getColor(2),
                        border: 0
                      }}
                      onClick={() => copyClipboard(integration.domain_url)}
                      > {lang("COPY")}
                    </Button>
                  </div>
                </>
              }

            </FormGroup>
            <div className="float-right">
              <Button
                style={{
                  backgroundColor: getColor(1),
                  color: getColor(2),
                  border: 0
                }}
                onClick={() => copyClipboard(integration.domain)}
                > {lang("COPY")}
              </Button>
            </div>
          </Col>
        </Row>

 

        <Row>
          <Col md="12">
            <FormGroup>
              <label className="form-control-label">{lang("URL_VERSION")}</label>
              <select
                value={integration.url_version}
                className="form-control"
                onChange={val => setIntegration({...integration, url_version: val.target.value})}>
                <option value="">{lang("SELECT")}</option>
                <option value="1">V1.0</option>
                <option value="2">V2.0</option>
              </select>
            </FormGroup>
            
            <div className="float-right">
              {
                (loading.version) ? <div>
                  <Spinner style={{
                    color: getColor(1)
                  }} />
                </div> :
                <>
                  <Button
                    style={{
                      backgroundColor: getColor(1),
                      color: getColor(2),
                      border: 0
                    }}
                    onClick={() => updateURLVersion()}
                    > {lang("SAVE")}
                  </Button>
                </>
              }
            </div>
          </Col>
        </Row>
        
        <Row>
          <Col md='12'>
            <label
              style={{
                fontSize: '1.06rem',
                color: '#32325d',
                fontWeight: 'bold'
              }}
              className="navbar-heading">{lang("GEN_SUB_BTN")}</label>
          </Col>
          
          <Col md='6'>
            <FormGroup>
              <label className="form-control-label">{lang("SERVICE")}</label>
              <select
                value={aTag.services}
                className="form-control"
                onChange={val => setATag({...aTag, services: val.target.value})}>
                <option value="">{lang("SELECT")}</option>
                {
                  services.map( (s,i) => (
                    <option 
                      key={i}
                      value={s.code}>{s.name}-{s.code}</option>
                  ))
                }
              </select>
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">{lang("PLAN")}</label>
              <Input 
                value={aTag.plan}
                onChange={val => setATag({...aTag, plan: val.target.value})}
              />
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">{lang("QUANTITY_DAYS")} {lang("OPTIONAL")}</label>
              <Input 
                value={aTag.days}
                type="number"
                min="0"
                onChange={val => setATag({...aTag, days: val.target.value})}
              />
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">{lang("TEXT")}</label>
              <Input 
                value={aTag.text}
                onChange={val => setATag({...aTag, text: val.target.value})}
              />
            </FormGroup>
          </Col>
          
          <Col md='6' className="align-items-center align-self-center">
            <div style={{
              textAlign: 'center'
            }}>
              <code> {`<a href="javascript:void(0)" `} </code><br />
              <code> {`data-gm-services="${(aTag.services).toLowerCase()}"`} </code><br />
              <code> {`data-gm-plan="${aTag.plan}"`} </code><br />
              <code> {`data-gm-indays="${aTag.days}">${aTag.text}</a>`} </code><br />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="float-right">
              <br />
              <Button
                style={{
                  backgroundColor: getColor(1),
                  color: getColor(2),
                  border: 0
                }}
                onClick={() => copyClipboardATag()}
                > {lang("COPY")}
              </Button>
            </div>
          </Col>
        </Row>

        <Row style={{marginTop: '25px'}}>
          <Col md='12'>
            <label
              style={{
                fontSize: '1.06rem',
                color: '#32325d',
                fontWeight: 'bold'
              }}
              className="navbar-heading">{lang("CUSTOM_FIELDS")}</label>
            <Button 
              className="float-right btn-sm"
              style={{
                backgroundColor: getColor(1),
                color: getColor(2),
                border: 0
              }}
              onClick={() => setShow(!show)}
            >{lang("NEW_FIELD")}</Button>
          </Col>
        </Row>

        <Row>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col"> {lang("NAME")} </th>
                <th scope="col"> {lang("ENTITY")} </th>
                <th scope="col"> {lang("FIELD_TYPE")} </th>
                <th scope="col"> {lang("FIELD_VALUES")} </th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
            {
              customFieldsData.map( (c, i) => (
                <tr key={i}>
                  <td>{c.label_name}</td>
                  <td>{getEntity(c.entity)}</td>
                  <td>{getFieldType(c.type)}</td>
                  <td>
                    {(c.fields.length === 0) ? <> - </> : <>
                      <ul style={{
                        padding: '0',
                        listStyle: 'decimal'
                      }}>
                      {
                        (c.fields).map((f, ix) => (
                          <li key={ix}> {f.text} </li>
                        ))
                        }
                      </ul>
                    </>}
                  </td>
                  <td>
                    <Button
                      onClick={() => editData(i)}>{lang("EDIT")}</Button>
                    <Button
                      onClick={() => deleteData(i)}>{lang("DELETE")}</Button>
                  </td>
                </tr>
              ))
            }
            </tbody>
          </Table>
        </Row>
      </div>

      <Modal isOpen={show} toggle={toggleShow}>
        <ModalHeader toggle={() => setShow()}> {lang("NEW_FIELD")} </ModalHeader>
        <ModalBody>
          <div>
            <FormGroup>
              <label className="form-control-label">{lang("FIELD_TYPE")}</label>
              <select
                value={customFields.type}
                className="form-control"
                onChange={(val) => setCustomFields({...customFields, type: val.target.value})}>
                <option value="input">{lang("FIELD_TYPE_NAME")}</option>
                <option value="select">{lang("FIELD_TYPE_SELECT")}</option>
              </select>
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">{lang("LABEL_NAME")}</label>
              <Input
                value={customFields.label}
                onChange={(val) => setCustomFields({...customFields, label: val.target.value})} />
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">{lang("IS_MANDATORY")}</label>
              <select
                value={customFields.is_mandatory}
                className="form-control"
                onChange={(val) => setCustomFields({...customFields, is_mandatory: val.target.value})}>
                <option value="false">{lang("NO")}</option>
                <option value="true">{lang("YES")}</option>
              </select>
            </FormGroup>

            <FormGroup>
              <label className="form-control-label">{lang("API_FIELD_NAME")}</label>
              <Input
                value={customFields.API_name}
                className="form-control"
                onChange={(val) => setCustomFields({...customFields, API_name: val.target.value})} />
            </FormGroup>

            {
              (customFields.type === "select") ? 
              <>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}>
                  <label className="form-control-label">{lang("FIELD_LIST_VALUES")}</label>
                  <i className="fas fa-plus-circle" 
                  onClick={() => createInput()}/>
                </div>

                <ul style={{
                  display: 'block',
                  listStyle: 'none',
                  padding: '0'
                }}
                id="fields">
                  {
                    arrFields.map( (f, i) => (
                      <li key={i} style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '15px'
                      }}>
                      <div>
                          <label className="form-control-label">{lang("VALUE_LIST")}</label>
                          <input 
                            value={f.value}
                            className="form-control"
                            onChange={(val) => handleChange(i, "value", val.target.value)} />
                        </div>
                        <div>
                          <label className="form-control-label">{lang("TEXT_LIST")}</label>
                          <input 
                            value={f.text}
                            className="form-control"
                            onChange={(val) => handleChange(i, "text", val.target.value)} />
                        </div>
                        <div>
                          <i style={{marginTop: '44px'}} className="fas fa-trash" onClick={() => deleteRow(i)}/>
                        </div>
                      </li>
                    ))
                  }
                </ul>
              </>
              :<> </> 
            }

          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShow(!show)}>{lang( "CANCEL")}</Button>
          <Button
            style={{
              backgroundColor: getColor(1),
              color: getColor(2),
              border: 0
            }} onClick={() => saveCustomFields()}> {lang("SAVE")} </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default Standard;