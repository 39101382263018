/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import {Card, Input, Container, CardHeader, Row, Button, Col, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "components/Headers/Header";
import NotificationAlert from "react-notification-alert";
import lang from 'lang';
import { getAllStateData, setStateData } from "api/places";
import { getColor } from "utils/token";

var map, marker;

const NewState = () => {
  const notificationAlertRef = React.useRef(null);
  const [selState, setSelState] = useState('');
  const [active, setActive] = useState('');
  const [data, setData] = useState([]);
  const [latitude, setLatitude] = useState('0');
  const [longitude, setLongitude] = useState('0');
  const [zoom, setZoom] = useState('6');

  useEffect(() => {
    getAllState();
    initializeMap();
  },[]);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const getAllState = async () => {
    const res = await getAllStateData();
    setData(res.data);
  }

  const getLatLngFromState = (val) => {
    const _state = data.find( d => {
      return d.id === Number(val)
    })

    setSelState(val);
    initializeMap(_state.lat, _state.lng);
    setMarkerLatLngDym(_state.lat, _state.lng)
  }

  const initializeMap = async (lat, lng) => {
    const _lat = (lat === undefined) ? 6.873422608947892 : lat;
    const _lng = (lng === undefined) ? -66.34430341114883 : lng;

    try {
      map = new google.maps.Map(document.getElementById('map'), {
        zoom: 6,
        center: new google.maps.LatLng(_lat, _lng),
        zoomControl: true,
        mapTypeControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false
      });
    }
    catch(err) {
      console.log(err);
    }

    google.maps.event.addListener(map, 'click', function(event) {
      setMarkerLatLng(event);
    });

    google.maps.event.addListener(map, 'zoom_changed', function() {
      var z = map.getZoom();
      setZoom(z);
    });
  }

  const setMarkerLatLng = (pos) => {
    if(marker !== undefined) {
      marker.setMap(null);
    }
    
    setLatitude(pos.latLng.lat());
    setLongitude(pos.latLng.lng());

    const latLng = {
      lat: pos.latLng.lat(),
      lng: pos.latLng.lng()
    }

    marker = new google.maps.Marker({
      position: latLng,
      map,
    });
  }

  const setMarkerLatLngDym = (lat, lng) => {
    if(marker !== undefined) {
      marker.setMap(null);
    }
    
    setLatitude(lat);
    setLongitude(lng);

    const latLng = {
      lat: lat,
      lng: lng
    }

    marker = new google.maps.Marker({
      position: latLng,
      map,
    });
  }

  const addState = async () => {
    if(selState === '') {
      notifyAlert("default", lang("MESSAGE"), lang("STATE_EMPTY"));
    }
    else {
      const res = await setStateData(selState, active, latitude, longitude, zoom);

      if(res.success === true) {
        notifyAlert("success", lang("MESSAGE"), res.data);
        window.location.href = '/admin/gomap/state';
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
    }

  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

            <CardHeader className="border-0">
              <Row>
                <div className="col mb-0 float-left">
                  <h3> {lang("STATE")} </h3>
                </div>
              </Row>
            </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("SEL_STATE")} </label>
                        <select
                          className="form-control"
                          value={selState}
                          onChange={val => getLatLngFromState(val.target.value)}
                        >
                          <option>{lang("SELECT")}</option>
                          {
                            data.map( (s, i) => (
                              <option 
                                key={i}
                                value={s.id}>{s.name}</option>
                            ))
                          }
                        </select>
                      </FormGroup>

                      <FormGroup>
                        <label className="form-control-label"> {lang("ACTIVE")} </label>
                        <select
                          className="form-control"
                          value={active}
                          onChange={val => setActive(val.target.value)}
                        >
                          <option value="1">{lang("YES")}</option>
                          <option value="0">{lang("NO")}</option>
                        </select>
                      </FormGroup>
                      
                      <FormGroup>
                        <label className="form-control-label"> {lang("LATITUDE")} </label>
                        <Input
                          value={latitude}
                          readOnly={true}
                          placeholder={lang("LATITUDE")}
                          className="form-control"/>
                      </FormGroup>

                      <FormGroup>
                        <label className="form-control-label"> {lang("LONGITUDE")} </label>
                        <Input
                          value={longitude}
                          readOnly={true}
                          placeholder={lang("LONGITUDE")}
                          className="form-control"/>
                      </FormGroup>

                      <FormGroup>
                        <label className="form-control-label"> {lang("ZOOM")} </label>
                        <Input
                          value={zoom}
                          readOnly={true}
                          placeholder={lang("ZOOM")}
                          className="form-control"/>
                      </FormGroup>

                    </Col>

                    <Col lg="6">
                      <label className="form-control-label"> {lang("MAP")} </label>
                      <div id="map" style={{
                        height: '400px',
                        width: '100%',
                        border: '1px solid #444'
                      }}></div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                        <Button color="gray" to="/admin/gomap/state" tag={Link}> {lang("BACK")} </Button>

                        <Button
                          color="primary"
                          style={{
                            backgroundColor: getColor(1),
                            color: getColor(2),
                            border: 0
                          }}
                          onClick={() => addState()}
                          > {lang("SAVE")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
 
          </div>
        </Row>    
      </Container>
    </>
  )
}

export default NewState;