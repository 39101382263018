import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Header from "components/Headers/Header";
import {Spinner, Card, CardHeader, Table, Container, Row, Badge, Button, Input, InputGroupAddon, InputGroup, InputGroupText} from "reactstrap";
import lang from 'lang';
import {getCompanyCitiesData} from 'api/places';
import { getColor, getTokenData } from "utils/token";

const City = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [dataSearch, setDataSearch] = useState([]);
  const [search, setSearch] = useState('');
  const [role, setRole] = useState(0);
  
  useEffect( () => {
    setRole(getTokenData('GoSuiteToken', 'role'));
    getCities()
  }, []);

  const getCities = async () => {
    setLoading(true);
    const res = await getCompanyCitiesData();

    if(res.success === true) {
      setError(false);
      setData(res.data);
      setDataSearch(res.data);
    }
    else {
      setError(true);
      setData([]);
      setDataSearch([]);
    }
    setLoading(false);
  }

  const handleSearch = (text) => {
    if (text) {
      const newData = data.filter( (item) => {
        const itemData = item.name
          ? item.name.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setSearch(text);
      setDataSearch(newData);
    } 
    else {
      setSearch(text);
      setDataSearch(data);
    }   
  }

  const checkBlock = (i) => {
    return (i === 0) ? <Badge className='badgePadding' color="danger">{lang("NO")}</Badge>:
    <Badge className='badgePadding' color="success">{lang("YES")}</Badge>;
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("CITY")} </h3>
                  </div>
                  <div className="col">
                    {
                      ([1,2,3].includes(role)) ? <>
                        <Button 
                          to={`/admin/gomap/city/new`} 
                          tag={Link}
                          className="float-right btn-sm"
                          style={{
                            backgroundColor: getColor(1),
                            color: getColor(2),
                            border: 0
                          }}
                        >{lang("NEW_CITY")}</Button> 
                      </> :<></>
                    }
                  </div>
                </Row>
              </CardHeader>

              <div className='paddingHorizontal'>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={search}
                    style={{paddingLeft: '5px'}}
                    placeholder={lang("SEARCH")}
                    onChange={(val) =>  handleSearch(val.target.value)}
                    />
                </InputGroup>
              </div>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col"> {lang("NAME")} </th>
                    <th scope="col"> {lang("STATE")} </th>
                    <th scope="col"> {lang("ACTIVE")} </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {
                    (loading) ?
                    <tr>
                      <td colSpan={10} className='text-center'>
                        <Spinner style={{color: getColor(1)}} />
                      </td>
                    </tr>:
                    (error) ? 
                    <tr>
                      <td colSpan={10} className='text-muted text-center'>
                        <label>{lang("ERROR_LOAD")}</label>
                        <br />
                        <Button 
                          color="secondary"
                          onClick={() => getCities()}>{lang("LOAD")}</Button>
                      </td>
                    </tr>:
                    (data.length === 0) ? 
                    <tr>
                      <td colSpan={10} className='text-muted text-center'> {lang("NO_CITY_SEL")} </td>
                    </tr>
                    :
                    dataSearch.map( (d,i) => (
                      <tr key={i}>
                        <td>
                          {d.name}
                        </td>
                        <td>
                          {d.state}
                        </td>
                        <td>
                          {checkBlock(d.active)}
                        </td>
                        <td className="text-right">
                          <Button color="secondary" 
                            to={`/admin/gomap/city/${d.id}`} 
                            tag={Link}
                          > {lang('DETAIL')} </Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default City;