/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Header from "components/Headers/Header";
import { Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import lang from 'lang';
import { getAllCompanyStateData, getCitiesByStateData } from "api/places";
import { getAllServicesData } from "api/services";
import { getAllLayerData } from "api/layer";
import { getDrawTypeData } from "api/draw";
import { setMapAreaData, getAllMapAreaData, getAllMapAreaRoutesData } from "api/map";
import { getColor, getToken } from "utils/token";
import NotificationAlert from "react-notification-alert";

var drawingManager;
var drawings = [];
var selectedShape;
var selectedOption = 0;
var polygons = [];
var radius = 0;
var zones = [];
var map;

const polyOptions = {
  strokeWeight: 0,
  fillOpacity: 0.45,
  editable: false,
  draggable: false
}

const NewMap = () => {
  const notificationAlertRef = React.useRef(null);
  const [stateArr, setStateArr] = useState([]);
  const [citiesArr, setCitiesArr] = useState([]);
  const [servicesArr, setServicesArr] = useState([]);
  const [layerArr, setLayerArr] = useState([]);
  const [drawArr, setDrawArr] = useState([]);
  const [positions, setPositions] = useState([]);
  const [check, setCheck] = useState(false);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [zoom, setZoom] = useState('');
  const [description, setDescription] = useState('');
  const [selState, setSelState] = useState('');
  const [selCity, setSelCity] = useState('');
  const [selServices, setSelServices] = useState('');
  const [layer, setLayer] = useState('');
  const [draw, setDraw] = useState('');
  const [active, setActive] = useState('');
  const [bunisseUnit, setBunisseUnit] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect( () => {
    initialize();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const initialize = async () => {
    initializeMap();
    await getMapZoom();
    await getAllMapArea();
    await getAllState();
    await getAllServices();
    await getAllLayer();
    await getDrawType();
  }
  
  const getMapZoom = async () => {
    const val = map.getZoom();
    setZoom(val)
  }

  const getAllMapArea = async () => {
    const res = await getAllMapAreaRoutesData();
    setPositions(res.data) 
  }

  const getAllState = async () => {
    const res = await getAllCompanyStateData();
    setStateArr(res.data);
  }

  const setResquestCity = async (id) => {
    const res = await getCitiesByStateData(id);
    setSelState(id)
    setCitiesArr(res.data);
  }

  const getAllServices = async () => {
    const res = await getAllServicesData();
    setServicesArr(res.data)
  }

  const getAllLayer = async () => {
    const res = await getAllLayerData();
    setLayerArr(res.data);
  }

  const getDrawType = async () => {
    const res = await getDrawTypeData();
    setDrawArr(res.data);
  }

  const initializeMap = (arrPos) => {
    const latlng = getToken('map');

    const posLatLng = (latlng === "") ? {
      "lat": 6.873422608947892,
      "lng": -66.34430341114883,
      "zoom": 6
    } : 
    {
      "lat": (JSON.parse(latlng)).lat,
      "lng": (JSON.parse(latlng)).lng,
      "zoom": (JSON.parse(latlng)).zoom
    }

    try {
      map = new google.maps.Map(document.getElementById('map'), {
        center: new google.maps.LatLng(posLatLng.lat, posLatLng.lng ),
        zoom: posLatLng.zoom,
        zoomControl: true,
        mapTypeControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false
      });
  
     if(arrPos === undefined) {
        zones = []
       
        for(var z of zones) {
          z.setMap(map)
        }
      }
      else {
        for(var data of arrPos){
          var coords = JSON.parse(data.coordinates);
    
          if(data.design === 'polygon') {
            zones.push(new google.maps.Polygon({
              paths: coords,
              strokeColor: data.color,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: data.color,
              fillOpacity: 0.35,
            }));
          }
          else if (data.design === 'point') {
            zones.push(new google.maps.Marker({
              map: map,
              position: coords[0],
              icon: pinSymbol(data.color),
           }));
          }
          else if(data.design === 'circle') {
            zones.push(new google.maps.Circle({
              strokeColor: data.color,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: data.color,
              fillOpacity: 0.35,
              center: {
                lat: coords[0].lat,
                lng: coords[0].lng
              },
              radius: coords[0].radius,
            }));
          }
          else if(data.design === 'polyline') {
            zones.push(new google.maps.Polyline({
              path: coords,
              geodesic: true,
              strokeColor: data.color,
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }));
          }
        }
    
        for(var zn of zones) {
          zn.setMap(map)
        }
      }
  
      drawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: false,
        markerOptions: {
          draggable: false
        },
        polylineOptions: {
          editable: false
        },
        circleOptions: polyOptions,
        polygonOptions: polyOptions,
        map: map
      });
        
      google.maps.event.addListener(drawingManager, 'overlaycomplete', function(e) {
        drawings.push(e);
        drawingManager.setDrawingMode(null);
        const _type = e.type;
        
        if(_type === 'marker') {
          setMarkerLatLng(drawings)
        }
        else if(_type === 'polyline') {
          setPolylineLatLng(e.overlay);
        }
        else if (_type === 'polygon') {
          setSelection(e.overlay);
        }
        else if (_type === 'circle') {
          setCircleLatLng(e.overlay)
        }
      });
  
      google.maps.event.addListener(drawingManager, 'drawingmode_changed', clearSelection);
      google.maps.event.addListener(map, 'click', clearSelection);
      
      google.maps.event.addListener(map, 'zoom_changed', function() {
        var z = map.getZoom();
        setZoom(z);
      });
    }
    catch(err) {
      console.log(err);
    }
  }

  const createMarker = () => {
    deleteAllShape();
    selectedOption = 1;
    document.getElementById("point").disabled = true;
    drawingManager.setDrawingMode(google.maps.drawing.OverlayType.MARKER);
  }
  
  const createPolyline = () => {
    deleteAllShape();
    selectedOption = 2;
    document.getElementById("line").disabled = true;
    drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYLINE);
  }

  const createPolygon = () => {
    deleteAllShape();
    selectedOption = 3;
    document.getElementById("poly").disabled = true;
    drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
  }

  const createCircle = () => {
    deleteAllShape();
    selectedOption = 4;
    document.getElementById("circle").disabled = true;
    drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
  }

  const setMarkerLatLng = (pos) => {
    polygons = [];
  
    polygons.push(
      {
        lat: pos[0].overlay.position.lat(),
        lng: pos[0].overlay.position.lng()
      }
    )
  
    enableBtn();
  }

  const setPolylineLatLng = (polygon) => {
    polygons = [];
    const polygonBounds = polygon.getPath();
  
    for(var i=0 ; i<polygonBounds.length ; i++) {
      polygons.push( {lat: polygonBounds.getAt(i).lat(), lng: polygonBounds.getAt(i).lng()});
    }
    enableBtn();
  }

  const setPolygonCoordinates = (polygon) => {
    polygons = [];
    const polygonBounds = polygon.getPath();
  
    for(var i=0 ; i<polygonBounds.length ; i++) {
      polygons.push( {lat: polygonBounds.getAt(i).lat(), lng: polygonBounds.getAt(i).lng()});
    }   
    enableBtn();
  }
  
  const setCircleLatLng = (pos) => {
    polygons = [];
    radius = pos.radius;
    console.log(radius)
    polygons.push(
      {
        lat: pos.center.lat(),
        lng: pos.center.lng()
      }
    )
    enableBtn();
  }

  const clearSelection = () => {
    if (selectedShape) {
      selectedShape.setEditable(false);
      selectedShape = null;
    }
  }
  
  const setSelection = (shape) => {
    clearSelection();
    selectedShape = shape;
    shape.setEditable(false);
    setPolygonCoordinates(shape);
    enableBtn();
  }
  
  const deleteAllShape = () => {
    for (var i=0; i < drawings.length; i++) {
      drawings[i].overlay.setMap(null);
    }
    drawings = [];
    selectedOption = 0;
    enableBtn();
  }
 
  const enableBtn = () => {
    document.getElementById("point").disabled = false;
    document.getElementById("line").disabled = false;
    document.getElementById("poly").disabled = false;
    document.getElementById("circle").disabled = false;
  }
  
  const getSelectedOption = () => {
    switch(selectedOption) {
      case 1: 
        return "point";
      case 2: 
        return "polyline";
      case 3: 
        return "polygon"
      case 4: 
        return "circle"
      default: 
        return false;
    }
  }
  
  const setShapesSwitch = (val) => {
    console.log(val)
    if(val === true) {
      initializeMap(positions);
    }
    else {
      initializeMap();
    }

    setCheck(val);
  }

  const addNewArea = async () => {
    setLoading(true);

    if(polygons.length === 0) {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_POLYGONS_MAP"));
    }
    else if(name.trim() === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_NAME_MAP"));
    }
    else if(selState === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_STATE_MAP"));
    }
    else if(selCity === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_CITY_MAP"));
    }
    else if(selServices === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_SERVICES_MAP"));
    }
    else if(layer === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_LAYER_MAP"));
    }
    else if(draw === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_DRAW_TYPE_MAP"));
    }
    else {
      const _active = (active === "") ? 1 : active;
      const design = getSelectedOption();

      const jsn = {
        "name": name,
        "code": code,
        "description": description,
        "state": selState,
        "city": selCity,
        "services": selServices,
        "layer": layer,
        "active": _active, 
        "coordinates": polygons,
        "radius": radius,
        "design": design,
        "draw": draw,
        "zoom": zoom,
        "businessUnit": bunisseUnit
      }
      
      const res = await setMapAreaData(jsn);

      if(res.success === true) {
        notifyAlert("success", lang("MESSAGE"), res.data);
        setLoading(false);
        deleteAllShape();
        setName('');
        setCode('');
        setDescription('');
        setSelState('');
        setSelCity('');
        setSelServices('');
        setLayer('');
        setDraw('');
        setActive('');
        setBunisseUnit('');
        getAllMapArea();
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
    }

    setLoading(false);
  }

  function pinSymbol(color) {
    return {
      path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
      fillColor: color,
      fillOpacity: 1,
      strokeColor: '#000',
      strokeWeight: 2,
      scale: 1,
   };
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("MAP")} </h3>
                  </div>

                  <div className="col">
                    <div className="float-right" style={{display: 'block', position: 'relative', top: '0px'}}>
                      <label style={{
                        display: 'inline-flex',
                      }}>{lang("SHOW_SHAPE")}</label>
                      <div 
                        style={{
                          display: 'inline-flex',
                          verticalAlign: 'text-top',
                          marginLeft: '10px',
                          marginTop: '5px'
                        }}
                        className="">
                        <input
                         
                          style={{
                            
                          }}
                          type="checkbox"
                          checked={check} 
                          onChange={(val) => setShapesSwitch(val.target.checked)}/> 
                      </div>
                    </div>
      
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12" style={{
                      textAlign: 'center'
                    }}>
                      <div style={{
                        marginBottom: '10px',
                      }}>
                        
                        <div id="map" style={{
                          height: '600px',
                          width: '100%'
                        }}></div>

                        <Row style={{
                          marginTop: '20px',
                        }}>
                          <Col>
                            <button id="point" 
                              style={{
                                backgroundColor: getColor(1),
                                color: getColor(2),
                                border: 0
                              }}
                              className="btn btn-primary"
                              onClick={() => createMarker()}>{lang("DRAW_POINT")}</button>
                          </Col>
                          <Col>
                            <button id="line" 
                              style={{
                                backgroundColor: getColor(1),
                                color: getColor(2),
                                border: 0
                              }}
                              className="btn btn-primary"
                              onClick={() => createPolyline()}>{lang("DRAW_LINE")}</button>
                          </Col>
                          <Col>
                            <button id="poly" 
                              style={{
                                backgroundColor: getColor(1),
                                color: getColor(2),
                                border: 0
                              }}
                              className="btn btn-primary"
                              onClick={() => createPolygon()}>{lang("DRAW_POLYGON")}</button>
                          </Col>
                          <Col>
                            <button id="circle" 
                              style={{
                                backgroundColor: getColor(1),
                                color: getColor(2),
                                border: 0
                              }}
                              className="btn btn-primary"
                              onClick={() => createCircle()}>{lang("DRAW_CIRCLE")}</button>
                          </Col>
                          <Col>
                            <button id="delete-draw"
                              style={{
                                backgroundColor: getColor(1),
                                color: getColor(2),
                                border: 0
                              }}
                              className="btn btn-primary"
                              onClick={() => deleteAllShape()}
                            >{lang("DELETE_DRAW")}</button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("NAME")} </label>
                        <Input
                          value={name}
                          className="form-control"
                          onChange={val => setName(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="3">
                      <FormGroup>
                        <label className="form-control-label"> {lang("CODE")} </label>
                        <Input
                          value={code}
                          className="form-control"
                          onChange={val => setCode(val.target.value)}/>
                      </FormGroup>
                    </Col>

                    <Col lg="3">
                      <FormGroup>
                        <label className="form-control-label"> {lang("ZOOM")} </label>
                        <Input
                          value={zoom}
                          readOnly={true}
                          className="form-control" />
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label">{lang("DESCRIPTION")}{' '}{lang( "OPTIONAL")}</label>
                        <textarea
                          id='txtArea'
                          className="form-control"
                          value={description}
                          onChange={val => setDescription(val.target.value)}>
                        </textarea>
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("STATE")} </label>
                        <select
                          className="form-control"
                          value={selState}
                          onChange={val => setResquestCity(val.target.value)}
                        >
                          <option value="">{lang("SELECT")}</option>
                          {
                            stateArr.map( (s,i) => (
                              <option key={i} value={s.id}>{s.name}</option>
                            ))
                          }
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("CITY")} </label>
                        <select
                          className="form-control"
                          value={selCity}
                          onChange={val => setSelCity(val.target.value)}
                        >
                          <option value="">{lang("SELECT")}</option>
                          {
                            citiesArr.map( (c,i) => (
                              <option key={i} value={c.id}>{c.name}</option>
                            ))
                          }
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("SERVICES_TYPE")} </label>
                        <select
                          className="form-control"
                          value={selServices}
                          onChange={val => setSelServices(val.target.value)}
                        >
                          <option value="">{lang("SELECT")}</option>
                          {
                            servicesArr.map( (s,i) => (
                              <option key={i} value={s.id}>{s.name} - {s.code}</option>
                            ))
                          }
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("LAYERS")} </label>
                        <select
                          className="form-control"
                          value={layer}
                          onChange={val => setLayer(val.target.value)}
                        >
                          <option value="">{lang("SELECT")}</option>
                          {
                            layerArr.map( (l, i) => (
                              <option key={i} value={l.id}>{l.name}</option>
                            ))
                          }
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("DRAW_TYPE")} </label>
                        <select
                          className="form-control"
                          value={draw}
                          onChange={val => setDraw(val.target.value)}
                        >
                          <option value="">{lang("SELECT")}</option>
                          {
                            drawArr.map( (d, i) => (
                              <option key={i} value={d.id}>{d.name}</option>
                            ))
                          }
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("ACTIVE")} </label>
                        <select
                          className="form-control"
                          value={active}
                          onChange={val => setActive(val.target.value)}
                        >
                          <option value="1">{lang("YES")}</option>
                          <option value="0">{lang("NO")}</option>
                        </select>
                      </FormGroup>
                    </Col>

                  </Row>

                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("BUSINESS_UNIT")}{' '}{lang("OPTIONAL")} </label>
                        <Input
                          value={bunisseUnit}
                          className="form-control"
                          onChange={val => setBunisseUnit(val.target.value)}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col lg="12">
                      <br />
                      <div className="float-right">
                        {
                          (loading) ? <>
                            <Spinner style={{color: getColor(1)}}/>
                          </> :
                          <>
                            <Button color="gray" to="/admin/gomap/map" tag={Link}> {lang("BACK")} </Button>

                            <Button
                              color="primary"
                              style={{
                                backgroundColor: getColor(1),
                                color: getColor(2),
                                border: 0
                              }}
                              onClick={() => addNewArea()}
                              > {lang("SAVE")}
                            </Button>
                          </>
                        }
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default NewMap;