/* eslint-disable jsx-a11y/iframe-has-title */
import React, {useState, useEffect} from 'react';
import Header from "components/Headers/Header";
import { Container, Row, Card, CardHeader, CardBody, Spinner } from "reactstrap";
import lang from 'lang';
import { getColor } from "utils/token";
import { getCompanyMapData } from 'api/company';

export default function Dashboard () {
  const [loading, setLoading] = useState(true);
  const [map, setMap] = useState('');
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  useEffect(() => {
    getCompanyMap();
  }, []);
  
  const getCompanyMap = async () => {
    const res = await getCompanyMapData();
  
    if(res.success === true){
      setError(false);
      setMap(res.data.map);
    }
    else {
      setErrorMsg(res.error.message);
      setError(true);
    }

    setLoading(false);
  }
  
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("DASHBOARD2")} </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody style= {{paddingTop: '0', marginTop: '-20px'}}>
                {
                  (loading) ? <>
                    <div style={{textAlign: 'center'}}>
                      <Spinner style={{color: getColor(1)}} />
                    </div>
                  </> : 
                  <>
                    {
                      (error) ? <> 
                        <div style={{textAlign: 'center'}}>
                          <label> {errorMsg} </label>
                        </div>
                      </> :
                        (map === null) ? <> 
                          <div style={{textAlign: 'center'}}>
                            <label> {lang("MAP_ZH_ERROR")}</label>  
                          </div>
                        </> : <>
                          <div>
                            <iframe 
                              style={{
                                border: '0px',
                                width: '100%',
                                height: '600px'
                              }}
                              src={map} />
                          </div>
                        </>
                    }
                  </>
                }
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
