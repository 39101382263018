/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import {Spinner, Card, CardHeader, Col, Table, Container, Row, Badge, Button, Input, InputGroupAddon, InputGroup, InputGroupText} from "reactstrap";
import Header from "components/Headers/Header";
import lang from 'lang';
import { getCitiesByStateData, getCoordsByStateData } from 'api/places';
import { getAllMapAreaData, getAllMapAreaByIdCityData, getAreaByTextData, getAllMapAreaPageData, getAllMapAreaRoutesData } from 'api/map';
import { getColor, setToken, getTokenData } from "utils/token";

var map;
var zones = [];
var obj;

const polyOptions = {
  strokeWeight: 0,
  fillOpacity: 0.45,
  editable: false,
  draggable: false
}

const Map = () => {
  const [loadingArrAreas, setLoadingArrAreas] = useState(false);
  const [role, setRole] = useState(0);
  const [arrAreas, setArrAreas] = useState([]);
  const [arrData, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [loadingArrState, setLoadingArrState] = useState(true);
  const [dataSearchState, setDataSearchState] = useState([]);
  const [arrState, setArrState] = useState([]);
  const [searchState, setSearchState] = useState('');
  const [loadingArrCities, setLoadingArrCities] = useState(false);
  const [dataSearchCity, setDataSearchCity] = useState([]);
  const [arrCities, setArrCities] = useState([]);
  const [searchCity, setSearchCity] = useState('');
  const [page, setPage] = useState({currentPage: 1, page: 1, total: 10})
  const [loadingMap, setLoadingMap] = useState(true);

  useEffect( () => {
    setRole(getTokenData('GoSuiteToken', 'role'));
    setToken("map", "");
    initializeMap();
    getCoordsByState();
  }, []);

  const handleSearch = async (val) => {
    setLoadingArrAreas(true);
    const txt = String(val).trim();
    setSearch(txt);

    if(txt === '') {    
      setData(arrAreas);
    }
    else {
      const res = await getAreaByTextData(val);
      setData(res.data);
    }
    
    setLoadingArrAreas(false);
  }

  const handleSearchState = (text) => {
    if (text) {
      const newData = arrState.filter( (item) => {
        const itemData = item.name
          ? item.name.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setSearchState(text);
      setDataSearchState(newData);
    } 
    else {
      setSearchState(text);
      setDataSearchState(arrState);
    }
  }

  const handleSearchCity = (text) => {
    if (text) {
      const newData = arrCities.filter( (item) => {
        const itemData = item.name
          ? item.name.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setSearchCity(text);
      setDataSearchCity(newData);
    } 
    else {
      setSearchCity(text);
      setDataSearchCity(arrCities);
    }   
  }

  const initializeMap = async () => {
    zones = [];
    setLoadingArrAreas(true);
    const res = await getAllMapAreaData();
    const res_map = await getAllMapAreaRoutesData();

    setPage({...page, total: res.data.page.page});
    setArrAreas(res.data.data);
    setData(res.data.data);
    setLoadingArrAreas(false);

    try {
      map = new google.maps.Map(document.getElementById('map'), {
        zoom: 6,
        center: new google.maps.LatLng(6.873422608947892, -66.34430341114883),
        zoomControl: true,
        mapTypeControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false
      });

      map.addListener("click", (event) => {
        const _zoom = map.getZoom();

        const _jsn = {
          "zoom": _zoom,
          "lat": event.latLng.lat(),
          "lng": event.latLng.lng()
        }
        
        setToken("map", JSON.stringify(_jsn));
      });
      
    }
    catch(err) {
      console.log(err);
    }

    const arrData = res_map.data;

    for(var data of arrData){
      var coords = JSON.parse(data.coordinates);

      if(data.design === 'polygon') {
        zones.push(new google.maps.Polygon({
          paths: coords,
          strokeColor: data.color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: data.color,
          fillOpacity: 0.35,
        }));
      }
      else if (data.design === 'point') {
        zones.push(new google.maps.Marker({
          map: map,
          position: coords[0],
          icon: pinSymbol(data.color),
        }));
      }
      else if(data.design === 'circle') {
        zones.push(new google.maps.Circle({
          strokeColor: data.color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: data.color,
          fillOpacity: 0.35,
          center: {
            lat: coords[0].lat,
            lng: coords[0].lng
          },
          radius: coords[0].radius,
        }));
      }
      else if(data.design === 'polyline') {
        zones.push(new google.maps.Polyline({
          path: coords,
          geodesic: true,
          strokeColor: data.color,
          strokeOpacity: 1.0,
          strokeWeight: 2,
        }));
      }
    }

    for(var z of zones) {
      z.setMap(map)
    }
    setLoadingMap(false)
  }

  const getCoordsByState = async () => {
    const res = await getCoordsByStateData();
    setArrState(res.data);
    setDataSearchState(res.data);
    setLoadingArrState(false)
  }

  const getCities = async (id) => {
    setLoadingArrCities(true);
    const res = await getCitiesByStateData(id);

    const dataState = arrState.filter( v => {
      return id === Number(v.id);
    });
    
    setPositionMap(dataState[0].Latitude, dataState[0].Longitude, dataState[0].Zoom);
    setArrCities(res.data);
    setDataSearchCity(res.data);
    setLoadingArrCities(false);
  }
  
  const getZoneByCity = async (id) => {
    zones = [];
    const res = await getAllMapAreaByIdCityData(id);
    setArrAreas(res.data.data);
    const arrData = res.data.data;
    
    const dataCity = arrCities.filter( v => {
      return id === Number(v.id);
    });
    
    try {
      map = new google.maps.Map(document.getElementById('map'), {
        zoom: res.data.city.zoom,
        center: new google.maps.LatLng(res.data.city.lat_city, res.data.city.lng_city),
        zoomControl: true,
        mapTypeControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false
      });
    }
    catch(err) {
      console.log(err);
    }

    for(var data of arrData){
      var coords = JSON.parse(data.coordinates);

      if(data.design === 'polygon') {
        zones.push(new google.maps.Polygon({
          paths: coords,
          strokeColor: data.color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: data.color,
          fillOpacity: 0.35,
        }));
      }
      else if (data.design === 'point') {
        zones.push(new google.maps.Marker({
          map: map,
          position: coords[0],
          icon: pinSymbol(data.color),
       }));
      }
      else if(data.design === 'circle') {
        zones.push(new google.maps.Circle({
          strokeColor: data.color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: data.color,
          fillOpacity: 0.35,
          center: {
            lat: coords[0].lat,
            lng: coords[0].lng
          },
          radius: coords[0].radius,
        }));
      }
      else if(data.design === 'polyline') {
        zones.push(new google.maps.Polyline({
          path: coords,
          geodesic: true,
          strokeColor: data.color,
          strokeOpacity: 1.0,
          strokeWeight: 2,
        }));
      }
    }

    for(var z of zones) {
      z.setMap(map)
    }

    setPositionMap(dataCity[0].lat, dataCity[0].lng, dataCity[0].zoom);
    window.scroll({top: 0, left: 0, behavior: 'smooth' });
  }

  const viewArea = (data) => {
    var zone;

    var bounds = new google.maps.LatLngBounds();
    const coords = JSON.parse(data.coordinates);
    obj = data; 

    for (var i in coords) {
      bounds.extend(coords[i]);
    }

    try {
      map = new google.maps.Map(document.getElementById("map"), {
        zoom: data.zoom,
        center: bounds.getCenter(),
        zoomControl: true,
        mapTypeControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false
      });      
    }
    catch(err) {
      console.log("ERROR VIEW MAP ===> ", err);
    }

    if(data.design === 'polygon') {
      zone = new google.maps.Polygon({
        paths: coords,
        strokeColor: data.color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: data.color,
        fillOpacity: 0.35,
      });
    }
    else if (data.design === 'point') {
      zone = new google.maps.Marker({
        map: map,
        position: coords[0],
        icon: pinSymbol(data.color),
      });
    }
    else if(data.design === 'circle') {
      zone = new google.maps.Circle({
        strokeColor: data.color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: data.color,
        fillOpacity: 0.35,
        center: {
          lat: coords[0].lat,
          lng: coords[0].lng
        },
        radius: coords[0].radius,
      });
    }
    else if(data.design === 'polyline') {
      zone = new google.maps.Polyline({
        path: coords,
        geodesic: true,
        strokeColor: data.color,
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
    }

    zone.setMap(map);
    setPositionMap(bounds.getCenter().lat(), bounds.getCenter().lng(), map.getZoom());
    window.scroll({top: 0, left: 0, behavior: 'smooth' });
  }
  
  function pinSymbol(color) {
    return {
      path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
      fillColor: color,
      fillOpacity: 1,
      strokeColor: '#000',
      strokeWeight: 2,
      scale: 1,
   };
  }

  const checkBlock = (i) => {
    return (i === 0) ? <Badge className='badgePadding' color="danger">{lang("NO")}</Badge>:
    <Badge className='badgePadding' color="success">{lang("YES")}</Badge>;
  }

  const lessPage = async () => {
    const cp = page.currentPage;
    const newCp = cp - 1;

    if(cp > 0) {
      const res = await getAllMapAreaPageData(newCp)
      setPage({...page, currentPage: newCp});
      setArrAreas(res.data);
      setData(res.data);
    }
  }

  const morePage = async () => {
    setLoadingArrAreas(true);
    const cp = page.currentPage;
    const newCp = cp + 1;

    if(cp < newCp) {
      const res = await getAllMapAreaPageData(newCp)
      setPage({...page, currentPage: newCp});
      setArrAreas(res.data);
      setData(res.data);
    }
    setLoadingArrAreas(false);
  }

  const setPositionMap = async (lat, lng, zoom) => {
    const _jsn = {
      "zoom": zoom,
      "lat": lat,
      "lng": lng
    }
    setToken("map", JSON.stringify(_jsn));
  }

  return (
    <>
      <Header />
      
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("MAP")} </h3>
                  </div>
                  <div className="col">
                    {
                      ([1,2,3].includes(role)) ? <>
                        <Button 
                          to={`/admin/gomap/map/new`} 
                          tag={Link}
                          className="float-right btn-sm"
                          style={{
                            backgroundColor: getColor(1),
                            color: getColor(2),
                            border: 0,
                            marginLeft: '10px'
                          }}
                        >{lang("NEW_MAP")}</Button>
                      </> : <></>
                    }
                    
                    <Button 
                      className="float-right btn-sm"
                      style={{
                        backgroundColor: getColor(1),
                        color: getColor(2),
                        border: 0
                      }}
                      onClick={() => initializeMap()}
                    >{lang("RELOAD")}</Button>
                  </div>
                </Row>
              </CardHeader>

              <div>
                {
                  <>
                    <div className="text-center"
                      style={{
                        display: (loadingMap) ? 'block' : 'none',
                        marginBottom: '10px'
                      }}>
                      <Spinner style={{ color: getColor(1) }} />
                    </div>
                    <div id="map" style={{
                      display: (loadingMap) ? 'none': 'block',
                      height: '600px',
                      width: '100%'
                    }}></div>
                  </>
                }
              </div>
            </Card>
          </div>
        </Row>
        
        {/* STATE - CITY */}
        <Row style={{marginTop: '20px'}}>
          <Col lg="6">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("STATE")} </h3>
                  </div>
                </Row>
              </CardHeader>
            
              <div className='paddingHorizontal'>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={searchState}
                    style={{paddingLeft: '5px'}}
                    placeholder={lang("SEARCH")}
                    onChange={(val) =>  handleSearchState(val.target.value)}
                  />
                </InputGroup>
              </div>
              
              <div style={{
                maxHeight: '360px',
                overflow: 'overlay'
              }}>
                <Table className="align-items-center table-flush" responsive >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"> {lang("NAME")} </th>
                      <th scope="col"> {lang("ACTIVE")} </th>
                      <th scope="col" />
                    </tr>
                  </thead>

                  <tbody>
                    {
                      (loadingArrState) ?
                      <tr>
                        <td colSpan={10} className='text-center'>
                          <Spinner style={{ color: getColor(1) }} />
                        </td>
                      </tr>:
                      (dataSearchState.length === 0) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'> {lang("NO_STATE_SEL")} </td>
                      </tr>
                      :
                      dataSearchState.map( (d,i) => (
                        <tr key={i}>
                          <td>
                            {d.name}
                          </td>
                          <td>
                            {checkBlock(d.active)}
                          </td>
                          <td className="text-right">
                            <Button color="secondary" 
                              onClick={() => getCities(d.id)}
                            > {lang("GO")} </Button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </div>

            </Card>
          </Col>

          <Col lg="6">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("CITY")} </h3>
                  </div>
                </Row>
              </CardHeader>

              <div className='paddingHorizontal'>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={searchCity}
                    style={{paddingLeft: '5px'}}
                    placeholder={lang("SEARCH")}
                    onChange={(val) =>  handleSearchCity(val.target.value)}
                    />
                </InputGroup>
              </div>
              
              <div style={{
                maxHeight: '360px',
                overflow: 'overlay'
              }}>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"> {lang("NAME")} </th>
                      <th scope="col"> {lang("ACTIVE")} </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                    
                  <tbody>
                    {
                      (loadingArrCities) ?
                      <tr>
                        <td colSpan={10} className='text-center'>
                          <Spinner style={{ color: getColor(1) }} />
                        </td>
                      </tr>:
                      (dataSearchCity.length === 0) ? 
                      <tr>
                        <td colSpan={10} className='text-muted text-center'> {lang("NO_STATE_SEL")} </td>
                      </tr>
                      :
                      dataSearchCity.map( (d,i) => (
                        <tr key={i}>
                          <td>
                            {d.name}
                          </td>
                          <td>
                            {checkBlock(d.active)}
                          </td>
                          <td className="text-right">
                            <Button color="secondary" onClick={() => getZoneByCity(d.id)}> {lang("GO")} </Button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>

        {/* AREA */}
        <Row style={{marginTop: '20px'}}>
          <Col lg="12">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("AREAS")} </h3>
                  </div>
                </Row>
              </CardHeader>

              <div className='paddingHorizontal'>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><i className="fas fa-search"></i></InputGroupText>
                  </InputGroupAddon>
                  
                  <Input
                    value={search}
                    style={{paddingLeft: '5px'}}
                    placeholder={lang("SEARCH_BY_NAME_CODE")}
                    onChange={(val) =>  handleSearch(val.target.value)}
                  />
                </InputGroup>
              </div>
               
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col"> {lang("NAME")} </th>
                    <th scope="col"> {lang("CODE")} </th>
                    <th scope="col"> {lang("STATE")} </th>
                    <th scope="col"> {lang("CITY")} </th>
                    <th scope="col"> {lang("LAYER")} </th>
                    <th scope="col"> {lang("COLOR")} </th>
                    <th scope="col"> {lang("ACTIVE")} </th>
                    <th scope="col" />
                  </tr>
                </thead>
                
                <tbody>
                  {
                    (loadingArrAreas) ?
                    <tr>
                      <td colSpan={10} className='text-center'>
                        <Spinner style={{ color: getColor(1) }} />
                      </td>
                    </tr>:
                    (arrData.length === 0) ? 
                    <tr>
                      <td colSpan={10} className='text-muted text-center'> {lang("EMPTY_AREAS")} </td>
                    </tr>
                    :
                    arrData.map( (d,i) => (
                      <tr key={i}>
                        <td>
                          {d.name}
                        </td>
                        <td>
                          {d.code}
                        </td>
                        <td>
                          {d.state}
                        </td>
                        <td>
                          {d.city}
                        </td>
                        <td>
                          {d.layer}
                        </td>
                        <td>
                          <div style={{
                            height: '20px',
                            width: '20px',
                            backgroundColor: d.color,
                            border: '1px solid #444444'
                          }}></div>
                        </td>
                        <td>
                          {checkBlock(d.active)}
                        </td>
                        <td className="text-right">
                          <Button color="secondary" onClick={() => viewArea(d)}> {lang("GO")} </Button>
                          <Button color="secondary" 
                            to={`/admin/gomap/map/${d.id}`} 
                            tag={Link}
                          >{lang("DETAIL")}</Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              
              <div>
                <ul id="ulArrow">
                  <li>
                    {
                      (page.currentPage === 1) ? <></> : <>
                        <i onClick={() => lessPage()} className="arrowPage fas fa-chevron-left"></i>
                      </>
                    }
                  </li>
                  <li>
                    <span>{page.currentPage} / {page.total}</span>
                  </li>
                  <li>
                    {
                      (page.currentPage === page.total) ? <> </> : <>
                        <i onClick={() => morePage()} className="arrowPage fas fa-chevron-right"></i>
                      </>
                    }
                  </li>
                </ul>
              </div>
            </Card>
          </Col>
        </Row>        
      </Container>
    </>
  )
}

export default Map;
