/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Spinner, Row, Button, Input, Col, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import lang from 'lang';
import Compressor from 'compressorjs';
import { getColor } from "utils/token";
import { getGoZelleConfigData, setPictureGoZelleCustomize, setGoZelleConfigData } from "api/gozelle";

export default function Customize() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({load: false, picture: false, save: false});
  const [picture, setPicture] = useState('');
  const [file, setFile] = useState({});
  const [window_title, setWindowTitle] = useState('');
  const [email_label, setEmailLabel] = useState('');
  const [fetchButton, setFetchButton] = useState({text: '', color: '', fontColor: ''});
  const [submitButton, setsubmitButton] = useState({text: '', color: '', fontColor: ''});
  const [warning_message, setWarningMessage] = useState('');
  const [invoice_title, setInvoiceTitle] = useState('');
  const [total_label, setTotalLabel] = useState('');
  const [zelle_instructions, setZelleInstructions] = useState('');
  const [email_zelle_label, setEmailZelleLabel] = useState('');
  const [zelle_recipient, setZelleRecipient] = useState('');
  const [email_zelle, setEmailZelle] = useState([]);
  const [zelle_info_message, setZelleInfoMessage] = useState('');
  const [success_message1, setSuccessMessage1] = useState('');
  const [success_message2, setSuccessMessage2] = useState('');
  const [success_message3, setSuccessMessage3] = useState('');
  const [modal, setModal] = useState(false);
  const [holder_add, setHolderAdd] = useState('');
  const [email_add, setEmailAdd] = useState('');
  const [email_error, setEmailError] = useState({add: false, invalid: false});

  useEffect(() => {
    getGoZelleConfig();
  },[]);
  
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  
  const getGoZelleConfig = async () => {
    const res = await getGoZelleConfigData();
    
    const data = res.data;
    setPicture(data.logo_url);
    setWindowTitle(data.window_title);
    setEmailLabel(data.email_label);
    setWarningMessage(data.warning_message);
    setInvoiceTitle(data.invoices_label);
    setTotalLabel(data.total_label);
    setZelleInstructions(data.zelle_instructions);
    setEmailZelleLabel(data.zelle_ref_label);
    setEmailZelle(JSON.parse(data.zelle_recipient_id));
    setZelleInfoMessage(data.zelle_info_message);
    setZelleRecipient(data.zelle_recipient)
    setSuccessMessage1(data.submit_text1);
    setSuccessMessage2(data.submit_text2);
    setSuccessMessage3(data.submit_text3);
    (data.fetchButton === null) ? setFetchButton({text: '', color: '', fontColor: ''}) : setFetchButton(JSON.parse(data.fetch_button));
    (data.submitButton === null) ? setsubmitButton({text: '', color: '', fontColor: ''}) : setsubmitButton(JSON.parse(data.submit_button));    
  }

  const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			reader.onload = (e) => {
				setPicture(e.target.result);
			};

			reader.readAsDataURL(event.target.files[0]);
			setFile(event.target.files[0]);
		}
	}

	const renderImageToSend = async (f) => {
		return new Compressor(f, {quality: 0.6});
	}

  const addEmailArray = () => {
    setEmailError({add:false, invalid:false});
    var arrEmail = email_zelle;
    
    if(!/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/.test(email_add)) {
      setEmailError({...email_error, invalid: true});
    }
    else {
      if(arrEmail.length === 0) {
        arrEmail.push({
          'holder': holder_add,
          'email': email_add 
        });
        setModal(!modal);
      }
      else {
        var index = arrEmail.findIndex(function(item, i){
          return item.email === email_add
        });

        if(index === -1) {
          arrEmail.push({
            'holder': holder_add,
            'email': email_add 
          });
          setModal(!modal);
        }
        else {
          setEmailError({...email_error, add: true});
        }
      }
    }

    setEmailAdd('');
    setHolderAdd('');
    setEmailZelle(arrEmail);
  }

  const deleteOption = (index) => {
    console.log(index);
    var arrEmail = [...email_zelle];
    arrEmail.splice(index, 1);
    setEmailZelle(arrEmail);
  }

  const savePicture = async () => {
    setLoading({...loading, picture: true});

    let fileCompress = null;
    
    if(picture === '') {
      notifyAlert('danger', lang("EMPTY_IMAGE"));
    }
    else {
      fileCompress = await renderImageToSend(file);
            
      const res = await setPictureGoZelleCustomize(fileCompress);

      if(res.success === true) { 
        console.log(res.data);
        notifyAlert('success', res.data);
      }
      else {
        notifyAlert('danger', res.error.message);
      }
    }
    
    setLoading({...loading, picture: false});
  }

  const saveData = async () => {
    setLoading({...loading, save: true});
    if(window_title === "" || email_label === "" || fetchButton.text === "" || fetchButton.color === "" || fetchButton.fontColor === "" || submitButton.text === "" || submitButton.color === "" || submitButton.fontColor === "" || warning_message === '' || invoice_title === '' || total_label === '' || zelle_instructions === '' || email_zelle_label === '' || email_zelle.length === 0 || zelle_info_message === '' || success_message1 === '' || success_message2 === '' || success_message3 === '') {
      notifyAlert('danger', lang("MESSAGE"), lang("EMPTY_FIELDS"));
    }
    else {
      const jsn = {
        "window_title": window_title,
        "email_label": email_label,
        "fetchButton": JSON.stringify({
          "text": fetchButton.text,
          "color": fetchButton.color,
          "fontColor": fetchButton.fontColor
        }),
        "submitButton": JSON.stringify({
          "text": submitButton.text,
          "color": submitButton.color,
          "fontColor": submitButton.fontColor
        }),
        "warning_message": warning_message,
        "invoice_title": invoice_title,
        "total_label": total_label,
        "zelle_instructions": zelle_instructions,
        "zelle_recipient": zelle_recipient,
        "email_zelle_label": email_zelle_label,
        "email_zelle": JSON.stringify(email_zelle),
        "zelle_info_message": zelle_info_message,
        "success_message1": success_message1,
        "success_message2": success_message2,
        "success_message3": success_message3,
      }
      console.log(jsn);

      const res = await setGoZelleConfigData(jsn);
    
      if(res.success === true) {
        notifyAlert("success", lang("MESSAGE"), lang("GOZELLE_UPDATE"));
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
    }
    setLoading({...loading, save: false});
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <div className="tabInside">
        <Row>
          <Col md="12">
            <label className="form-control-label">{lang("LOGO_APP")}</label> <br/>
            <ul style={{
              padding: '0',
              listStyle: 'none',
              display: 'inline-flex'
            }}>
              <li>
                {
                  (picture === null) ? <>
                    <img 
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: 'scale-down',
                        marginRight: '25px'
                      }} 
                      src={require('assets/img/noLogo3.png').default} alt="..."/>
                  </>:
                  <>
                    <img 
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: 'scale-down',
                        marginRight: '25px'
                      }} 
                      src={picture}
                      alt="..." /> 
                  </>
                }
              </li>
              <li>
                {
                  (loading.picture) ? <div className="text-center" style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                    <Spinner 
                      style={{
                        color: getColor(1)
                      }} 
                    />
                  </div> : <>
                    <ul style={{
                      padding: '0',
                      listStyle: 'none',
                      display: 'inline-flex',
                      position: 'relative',
                      top: '50%',
                      transform: 'translateY(-50%)'
                    }}>
                      <li>
                        <label className="custom-file-upload" style={{marginTop: '0px'}}>
                          <input 
                            type="file"
                            accept="image/*"
                            onChange={onImageChange}/>
                          {lang("EDIT")}
                        </label>
                      </li>
                      <li>
                        <Button
                          style={{
                            backgroundColor: '#6c757d',
                            color: '#fff',
                            border: 0
                          }}
                          onClick={() => savePicture()}> {lang("SAVE")}
                        </Button>
                      </li>
                    </ul>
                  </>
                }
              </li>
            </ul>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label"> {lang("WINDOW_TITLE")} </label>
              <Input
                value={window_title}
                className="form-control"
                placeholder={lang("WINDOW_TITLE")}
                onChange={val => setWindowTitle(val.target.value)}
              />
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <label className="form-control-label">{lang("EMAIL_LABEL")}</label>
              <Input 
                value={email_label}
                placeholder={lang("EMAIL_LABEL")}
                onChange={val => setEmailLabel(val.target.value)} 
              />
            </FormGroup>
          </Col>
        </Row>
    
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label"> {lang("FETCH_BUTTON_TEXT")}</label>
              <Input
                value={fetchButton.text}
                className="form-control"
                placeholder={lang("FETCH_BUTTON_TEXT")}
                onChange={val => setFetchButton({...fetchButton, text: val.target.value})}
              />
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <label className="form-control-label"> {lang("FETCH_BUTTON_COLOR")}</label>
              <div style={{
                display: 'flex',

              }}>
                <Input
                  style={{
                    width: '20%'
                  }}
                  type="color"
                  value={fetchButton.color}
                  className="form-control"
                  placeholder={lang("FETCH_BUTTON_COLOR")}
                  onChange={val => setFetchButton({...fetchButton, color: val.target.value})}
                />
                <Input
                  value={fetchButton.color}
                  className="form-control"
                  placeholder={lang("FETCH_BUTTON_COLOR")}
                  onChange={val => setFetchButton({...fetchButton, color: val.target.value})}
                />
              </div>
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <label className="form-control-label"> {lang("FETCH_BUTTON_COLOR_FONT")}</label>
              <div style={{
                display: 'flex',

              }}>
                <Input
                  style={{
                    width: '20%'
                  }}
                  type="color"
                  value={fetchButton.fontColor}
                  className="form-control"
                  placeholder={lang("FETCH_BUTTON_COLOR_FONT")}
                  onChange={val => setFetchButton({...fetchButton, fontColor: val.target.value})}
                />
                <Input
                  value={fetchButton.fontColor}
                  className="form-control"
                  placeholder={lang("FETCH_BUTTON_COLOR_FONT")}
                  onChange={val => setFetchButton({...fetchButton, fontColor: val.target.value})}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <FormGroup>
              <label className="form-control-label">{lang("WARNING_MESSAGE")}</label>
              <textarea
                style={{
                  height: '110px',
                  resize: 'none'
                }}
                value={warning_message} 
                className="form-control"
                placeholder={lang("WARNING_MESSAGE")} 
                onChange={(val) => setWarningMessage(val.target.value)} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label">{lang("INVOICE_TITLE")}</label>
              <Input 
                value={invoice_title}
                placeholder={lang("INVOICE_TITLE")}
                onChange={val => setInvoiceTitle(val.target.value)} 
              />
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <label className="form-control-label">{lang("TOTAL_LABEL")}</label>
              <Input 
                value={total_label}
                placeholder={lang("TOTAL_LABEL")}
                onChange={val => setTotalLabel(val.target.value )} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label"> {lang("ZELLE_INSTRUCTIONS")} </label>
              <Input
                value={zelle_instructions}
                className="form-control"
                placeholder={lang("ZELLE_INSTRUCTIONS")}
                onChange={val => setZelleInstructions(val.target.value)}
              />
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <label className="form-control-label">{lang("EMAIL_ZELLE_LABEL")}</label>
              <Input 
                value={email_zelle_label}
                placeholder={lang("EMAIL_ZELLE_LABEL")}
                onChange={val => setEmailZelleLabel(val.target.value)} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label"> {lang("ZELLE_ACCOUNT_DATA")} </label>
              <i 
                style={{
                  marginTop: '1px',
                  marginLeft: '5px',
                  cursor: 'pointer'
                }}
                className="fas fa-plus-circle"
                onClick={() => setModal(!modal)}/>
              <ul style={{
                padding: '0px',
                listStyle: 'none',
              }}>
                {
                  (email_zelle).map( (e, i) => (
                    <li key={i}>
                      <span>{e.holder} - {e.email}</span>
                      {' '}<i className="fas fa-trash" style={{
                        cursor: 'pointer'
                      }} 
                      onClick={() => deleteOption(i)}/>
                    </li>
                    ))
                  }
              </ul>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <FormGroup>
              <label className="form-control-label">{lang("ZELLE_INFO_MESSAGE")}</label>
              <textarea
                style={{
                  height: '110px',
                  resize: 'none'
                }}
                value={zelle_info_message} 
                className="form-control"
                placeholder={lang("ZELLE_INFO_MESSAGE")} 
                onChange={(val) => setZelleInfoMessage(val.target.value)} />
            </FormGroup>
          </Col>
        </Row>
        
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label"> {lang("SUBMIT_BUTTON_TEXT")}</label>
              <Input
                value={submitButton.text}
                className="form-control"
                placeholder={lang("SUBMIT_BUTTON_TEXT")}
                onChange={val => setsubmitButton({...submitButton, text: val.target.value})}
              />
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <label className="form-control-label"> {lang("SUBMIT_BUTTON_COLOR")}</label>
              <div style={{
                display: 'flex'
              }}>
                <Input
                  style={{
                    width: '20%'
                  }}
                  type="color"
                  value={submitButton.color}
                  className="form-control"
                  placeholder={lang("SUBMIT_BUTTON_COLOR")}
                  onChange={val => setsubmitButton({...submitButton, color: val.target.value})}
                />
                <Input
                  value={submitButton.color}
                  className="form-control"
                  placeholder={lang("SUBMIT_BUTTON_COLOR")}
                  onChange={val => setsubmitButton({...submitButton, color: val.target.value})}
                />
              </div>
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              <label className="form-control-label"> {lang("SUBMIT_BUTTON_COLOR_FONT")}</label>
              <div style={{
                display: 'flex',

              }}>
                <Input
                  style={{
                    width: '20%'
                  }}
                  type="color"
                  value={submitButton.fontColor}
                  className="form-control"
                  placeholder={lang("SUBMIT_BUTTON_COLOR_FONT")}
                  onChange={val => setsubmitButton({...submitButton, fontColor: val.target.value})}
                />
                <Input
                  value={submitButton.fontColor}
                  className="form-control"
                  placeholder={lang("SUBMIT_BUTTON_COLOR_FONT")}
                  onChange={val => setsubmitButton({...submitButton, fontColor: val.target.value})}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        
        <label 
          className="navbar-heading mb-4"
          style={{
            fontSize: '1.06rem',
            color: '#32325d',
            fontWeight: 'bold'
          }}
        >
          {lang("CONFIRMATION_SCREEN")}
        </label>

        <Row>

          <Col md="12">
            <FormGroup>
              <label className="form-control-label">{lang("SUCCESS_MESSAGE1")}</label>
              <textarea
                style={{
                  height: '110px',
                  resize: 'none'
                }}
                value={success_message1} 
                className="form-control"
                placeholder={lang("SUCCESS_MESSAGE1")} 
                onChange={(val) => setSuccessMessage1(val.target.value)} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <FormGroup>
              <label className="form-control-label">{lang("SUCCESS_MESSAGE2")}</label>
              <textarea
                style={{
                  height: '110px',
                  resize: 'none'
                }}
                value={success_message2} 
                className="form-control"
                placeholder={lang("SUCCESS_MESSAGE2")} 
                onChange={(val) => setSuccessMessage2(val.target.value)} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <FormGroup>
              <label className="form-control-label">{lang("SUCCESS_MESSAGE3")}</label>
              <textarea
                style={{
                  height: '110px',
                  resize: 'none'
                }}
                value={success_message3} 
                className="form-control"
                placeholder={lang("SUCCESS_MESSAGE3")} 
                onChange={(val) => setSuccessMessage3(val.target.value)} />
            </FormGroup>
          </Col>
        </Row>

        <Row className="float-right">
          <Col md="12">
          {
            (loading.save) ? <div className="text-center">
              <Spinner style={{
                color: getColor(1)
              }} />
            </div> :
            <>
              <Button
                style={{
                  backgroundColor: getColor(1),
                  color: getColor(2),
                  border: 0
                }}
                onClick={() => saveData()}
                > {lang("SAVE")}
              </Button>
            </>
          }
          </Col>
        </Row>
      </div>

      <Modal isOpen={modal}>
        <ModalHeader>{lang("ADD_EMAIL")}</ModalHeader>
        <ModalBody style={{paddingBottom: '0px'}}>
          <FormGroup>
            <label className="form-control-label"> {lang("HOLDER")} </label>
            <Input
              value={holder_add}
              className="form-control"
              placeholder={lang("ADD_HOLDER")}
              onChange={val => setHolderAdd(val.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label"> {lang("EMAIL")} </label>
            <Input
              value={email_add}
              className="form-control"
              placeholder={lang("ADD_EMAIL")}
              onChange={val => setEmailAdd(val.target.value)}
            />
          </FormGroup>
          {
            (email_error.add) ? 
            <label style={{
              fontSize: '13px',
              color: '#ff8080',
              marginTop: '-13px',
              display: 'block',
              marginLeft: '3px',
            }}>{lang("EMAIL_ALREADY_ADDED")}</label> : <></>
          }
          {
            (email_error.invalid) ? 
            <label style={{
              fontSize: '13px',
              color: '#ff8080',
              marginTop: '-13px',
              display: 'block',
              marginLeft: '3px',
            }}>{lang("EMAIL_INVALID")}</label> : <></>
          }
        </ModalBody>
        <ModalFooter>
          <Button
            style={{
              backgroundColor: getColor(1),
              color: getColor(2),
              border: 0
            }}
            onClick={() => addEmailArray()}
            > {lang("SAVE")}
          </Button>
          {' '}
          <Button onClick={() => setModal(!modal)}>{lang("CANCEL")}</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}