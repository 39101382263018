/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';

import { Spinner, Card, Container, CardHeader, Row, Button, Col, CardBody, FormGroup, Input, Table } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import Header from "components/Headers/Header";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

//import ReactExport from "react-data-export";

import lang from 'lang';
import { getColor } from "utils/token";
import * as pause from "api/pause";

//const ExcelFile = ReactExport.ExcelFile;
//const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

dayjs.extend(utc);


const Pause = () => {
  /*State variables*/
  const notificationAlertRef = React.useRef(null);

  const [load, setLoad] = useState({rep: false, not: false, t1: false, t2: false, dat: false})
/*
  const [initDate, setInitDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [excelData, setExcelData] = useState([]);
*/
  const [notificationName, setNotificationName] = useState('');
  const [notificationEmail, setNotificationEmail] = useState('');
  const [data, setData] = useState([]);

  const [dayName, setDayName] = useState('');
  const [dayDate, setDayDate] = useState('');
  const [dates, setDates] = useState([]);
  
  const [error, setError] = useState(false);

  /*Constants*/
  const regExEmail =  /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/;

  /*
  const dataSet = [
    {
      columns: [
        {title: 'customer_id', style: { font: {sz: 14, bold: true}, fill: {patternType: "solid", fgColor: {rgb: "067FC2"}}}, width: {wpx: 125}},
        {title: 'customer_name', style: { font: {sz: 14, bold: true}, fill: {patternType: "solid", fgColor: {rgb: "067FC2"}}}, width: {wpx: 370}},
        {title: 'subscription_id', style: { font: {sz: 14, bold: true}, fill: {patternType: "solid", fgColor: {rgb: "067FC2"}}}, width: {wpx: 172}},
        {title: 'plan_code', style: { font: {sz: 14, bold: true}, fill: {patternType: "solid", fgColor: {rgb: "067FC2"}}}, width: {wpx: 75}},
        {title: 'plan_name', style: { font: {sz: 14, bold: true}, fill: {patternType: "solid", fgColor: {rgb: "067FC2"}}}, width: {wpx: 345}},
        {title: 'invoice_id', style: { font: {sz: 14, bold: true}, fill: {patternType: "solid", fgColor: {rgb: "067FC2"}}}, width: {wpx: 125}},
        {title: 'invoice_number', style: { font: {sz: 14, bold: true}, fill: {patternType: "solid", fgColor: {rgb: "067FC2"}}}, width: {wpx: 125}},
        {title: 'invoice_date', style: { font: {sz: 14, bold: true}, fill: {patternType: "solid", fgColor: {rgb: "067FC2"}}}, width: {wpx: 125}},
        {title: 'invoice_age', style: { font: {sz: 14, bold: true}, fill: {patternType: "solid", fgColor: {rgb: "067FC2"}}}, width: {wpx: 125}},
        {title: 'balance', style: { font: {sz: 14, bold: true}, fill: {patternType: "solid", fgColor: {rgb: "067FC2"}}}, width: {wpx: 125}},
        {title: 'continuous_service', style: { font: {sz: 14, bold: true}, fill: {patternType: "solid", fgColor: {rgb: "067FC2"}}}, width: {wpx: 125}},
        {title: 'node', style: { font: {sz: 14, bold: true}, fill: {patternType: "solid", fgColor: {rgb: "067FC2"}}}, width: {wpx: 125}},
        {title: 'company_name', style: { font: {sz: 14, bold: true}, fill: {patternType: "solid", fgColor: {rgb: "067FC2"}}}, width: {wpx: 125}},
        {title: 'processed_date', style: { font: {sz: 14, bold: true}, fill: {patternType: "solid", fgColor: {rgb: "067FC2"}}}, width: {wpx: 125}},
        {title: 'success', style: { font: {sz: 14, bold: true}, fill: {patternType: "solid", fgColor: {rgb: "067FC2"}}}, width: {wpx: 125}},
        {title: 'suspension_reason', style: { font: {sz: 14, bold: true}, fill: {patternType: "solid", fgColor: {rgb: "067FC2"}}}, width: {wpx: 125}}
      ],

      data: excelData.map((values) => [
        {value: values.customer_id, style: {font: {sz: 12}}},
        {value: values.customer_name, style: {font: {sz: 12}}},
        {value: values.subscription_id, style: {font: {sz: 12}}},
        {value: values.plan_code, style: {font: {sz: 12}}},
        {value: values.plan_name, style: {font: {sz: 12}}},
        {value: values.invoice_id, style: {font: {sz: 12}}},
        {value: values.invoice_number, style: {font: {sz: 12}}},
        {value: values.invoice_date, style: {font: {sz: 12}}},
        {value: values.invoice_age, style: {font: {sz: 12}}},
        {value: values.balance, style: {font: {sz: 12}}},
        {value: values.continuous_service, style: {font: {sz: 12}}},
        {value: values.node, style: {font: {sz: 12}}},
        {value: values.company_name, style: {font: {sz: 12}}},
        {value: values.processed_date, style: {font: {sz: 12}}},
        {value: values.success, style: {font: {sz: 12}}},
        {value: values.suspension_reason, style: {font: {sz: 12}}}
      ])
    }
  ];
*/
  
  /*Load data for component*/
  useEffect(() => {
    getNotificationsList();
    getDatesList();
  }, []);

  /*Functions*/

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

/*
  const generateExcelReport = async () => {
    setLoad({...load, rep: true});

    if (initDate === '' || endDate === '') {
      notifyAlert("danger", lang("MESSAGE"), lang("ENTER_DATES"));
      setLoad({...load, rep: false});
    }
    else {

      const init_date = dayjs(initDate);

      const end_date = dayjs(endDate);

      const difference = end_date.diff(init_date, 'day', true);

      if (difference < 0) {
        notifyAlert("danger", lang("MESSAGE"), lang("INVALID_PERIOD_REPORT"));
        setLoad({...load, rep: false});
      }
      else {
        const response = await pause.getExportData(initDate, endDate);
        
        if (typeof response.data === 'string') {
          notifyAlert("success", lang("MESSAGE"), response.data);
        }
        else {
          setExcelData(response.data);
        }
        setLoad({...load, rep: false});
      }

    }
  }

  const resetReportData = async () => {
    setTimeout(() => {
      setInitDate('');
      setEndDate('');
      setExcelData([]);
    }, 100);
  }
  */

  const getNotificationsList = async () => {
    const notificationsList = await pause.getNotificationsList();
    setData(notificationsList.data);
    setError(false);
  }

  const getDatesList = async () => {
    const datesList = await pause.getDatesList();
    setDates(datesList.data);
  }

  const saveNotificationEmail = async () => {
    setLoad({...load, not: true});

    if(notificationName.trim() === '' && notificationEmail.trim() === '') {
      notifyAlert("danger", lang("MESSAGE"), lang("ENTER_NOTIFICATIONS_DATA"));
      setLoad({...load, not: false});
    }
    else if(notificationName.trim() === '') {
      notifyAlert('danger', lang('MESSAGE'), lang('ENTER_NAME'));
      setLoad({...load, not: false});
    }
    else if(notificationEmail.trim() === '') {
      notifyAlert('danger', lang('MESSAGE'), lang('ENTER_EMAIL'));
      setLoad({...load, not: false});
    }
    else if(regExEmail.test(notificationEmail) === false) {
      notifyAlert('danger', lang('MESSAGE'), lang('WRONG_EMAIL'));
      setLoad({...load, not: false});
    }
    else {
      const response = await pause.insertNotificationsUser(notificationName, notificationEmail);
      
      if (response.success === false) {
        notifyAlert('danger', lang('MESSAGE'), response.error.message);
      }
      else {
        await getNotificationsList();
        notifyAlert('success', lang('MESSAGE'), response.data);
      }

      setLoad({...load, not: false});
    }
  }

  const deleteNotificationEmail = async (id) => {
    setLoad({...load, t1: true});

    const response = await pause.deleteNotificationEmail(id);
    
    if (response.success === false) {
      notifyAlert('danger', lang('MESSAGE'), response.error.message);
    }
    else {
      await getNotificationsList();
      notifyAlert('success', lang('MESSAGE'), response.data);
    }
    
    setLoad({...load, t1: false});
  }

  const saveDate = async () => {
    setLoad({...load, dat: true});

    if(dayName.trim() === '' && dayDate.trim() === '') {
      notifyAlert("danger", lang("MESSAGE"), lang("ENTER_DATES_DATA"));
      setLoad({...load, dat: false});
    }
    else if(dayName.trim() === '') {
      notifyAlert('danger', lang('MESSAGE'), lang('ENTER_DAY_NAME'));
      setLoad({...load, dat: false});
    }
    else if(dayDate.trim() === '') {
      notifyAlert('danger', lang('MESSAGE'), lang('ENTER_DATE'));
      setLoad({...load, dat: false});
    }
    else {
      const response = await pause.insertDate(dayName, dayDate);
      
      if (response.success === false) {
        notifyAlert('danger', lang('MESSAGE'), response.error.message);
      }
      else {
        await getDatesList();
        notifyAlert('success', lang('MESSAGE'), response.data);
      }

      setLoad({...load, dat: false});
    }

  }

  const deleteDate = async (id) => {
    setLoad({...load, t2: true});

    const response = await pause.deleteDate(id);
    
    if (response.success === false) {
      notifyAlert('danger', lang('MESSAGE'), response.error.message);
    }
    else {
      await getDatesList();
      notifyAlert('success', lang('MESSAGE'), response.data);
    }
    
    setLoad({...load, t2: false});
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("PROGRAMMING_PAUSE")} </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  {
                    /* 
                      <Row>
                        <Col lg="12">
                          <h3>{lang("REPORTS")}</h3>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("INIT_DATE")} </label>
                            <Input 
                              type='date'
                              value={initDate}
                              onChange={(val) => setInitDate(val.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("END_DATE")} </label>
                            <Input 
                              type='date'
                              value={endDate}
                              onChange={(val) => setEndDate(val.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                        
                          <div style={{
                            textAlign: 'right',
                            width: '100%'
                          }}>
                            {(excelData.length === 0) ?
                              <></>
                            :
                            <ExcelFile
                              filename="Subscriptions Report - Excel"
                              element={
                                <Button
                                  onClick={() => resetReportData()}
                                  style={{
                                    backgroundColor: getColor(1),
                                    color: getColor(2),
                                    border: 0
                                  }}
                                >
                                  {lang("GENERATE")}
                                </Button>
                              }
                            >
                              <ExcelSheet dataSet={dataSet} name="prueba"/>
                            </ExcelFile>
                            }
    
                            <Button 
                              className="mx-2"
                              style={{
                                backgroundColor: getColor(1),
                                color: getColor(2),
                                border: 0,
                                width: '200px'
                              }}
                              onClick={() => generateExcelReport()}
                            > 
                              {(load.rep === true) ?
                                <Spinner style={{color: getColor(2)}} size="sm"/>
                                :
                                lang("REQUEST_DATA")
                              }
                            </Button>
    
                          </div>
                        </Col>
                      </Row>
                      <hr />
                    */
                  }

                  {/* Configuraciones */}
                  <Row>
                    <Col lg="12">
                      <h3> {lang("SETTINGS")} </h3>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("NAME")} </label>
                        <Input 
                          type='text' 
                          value={notificationName}
                          onChange={(val) => setNotificationName(val.target.value)}
                        />
                      </FormGroup>

                      <FormGroup>
                        <label className="form-control-label"> {lang("EMAIL")} </label>
                        <Input 
                          type='text' 
                          value={notificationEmail}
                          onChange={(val) => setNotificationEmail(val.target.value)}
                        />
                      </FormGroup>

                      <span style={{
                        fontSize: '13px',
                        color: '#525f7f'
                      }}> 
                        {lang('EMAIL_TEXT_NOTIFICATION')} 
                      </span>
                      
                      <div style={{width: '100%', textAlign: 'right'}}>
                        <Button 
                          style={{
                            backgroundColor: getColor(1),
                            color: getColor(2),
                            border: 0,
                            width: '100px'
                          }}
                          onClick={() => saveNotificationEmail()}
                        > 
                          {
                            (load.not === true) ? 
                              <Spinner style={{color: getColor(2)}} size="sm"/>
                            : 
                            lang("SAVE")
                          }
                          
                        </Button>
                      </div>
                    </Col>

                    <Col lg="6">
                      <label className="form-control-label"> {lang("LIST")} </label>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light text-center">
                          <tr>
                            <th scope="col" colSpan="3"> {lang("EMAIL")} </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (load.t1 === true) ?
                            <tr>
                              <td colSpan={10} className='text-center'>
                                <Spinner
                                  style={{color: getColor(1), marginTop: '17%'}}
                                />
                              </td>
                            </tr>:
                            (error) ? 
                            <tr>
                              <td colSpan={10} className='text-muted text-center'>
                                <label>{lang("ERROR_LOAD")}</label>
                                <br />
                                <Button 
                                  color="secondary"
                                  onClick={() => alert()}>{lang("LOAD")}</Button>
                              </td>
                            </tr>:
                            (data.length === 0) ? 
                            <tr>
                              <td colSpan={10} className='text-muted text-center'> {lang("NO_INFO_YET")} </td>
                            </tr>
                            :
                            data.map( (d,i) => (
                              <tr key={i}>
                                <td>
                                  {d.name}
                                </td>
                                <td>
                                  {d.email}
                                </td>
                                <td className="text-right">
                                  <Button size='sm' color="danger" onClick={() => deleteNotificationEmail(d.id)}>
                                    {lang('DELETE')}
                                  </Button>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <h3> {lang('HOLIDAYS')} </h3>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("NAME")} </label>
                        <Input 
                          type='text'
                          value={dayName}
                          onChange={(val) => setDayName(val.target.value)}
                        />
                      </FormGroup>
                        
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("DATE")} </label>
                        <Input 
                          type='date'
                          value={dayDate}
                          onChange={(val) => setDayDate(val.target.value)}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="12">
                      <div style={{width: '100%', textAlign: 'right'}}>
                        <Button 
                          style={{
                            backgroundColor: getColor(1),
                            color: getColor(2),
                            border: 0,
                            width: '100px'
                          }}
                          onClick={() => saveDate()}
                        > 
                        {
                          (load.dat === true) ?
                          <Spinner style={{color: getColor(2)}} size="sm"/>
                          :
                          lang("SAVE")
                        }
                        
                        </Button>
                      </div>
                    </Col>

                    <Col lg="6">
                      <label className="form-control-label">{lang('WEEKEND')}</label>
                      <div>
                        <select
                          style={{
                            width: '100%',
                            marginBottom: '25px'
                          }}
                          className="form-control"
                        >
                          <option value="">{lang("SELECT")}</option>
                          <option value="1">{lang("YES")}</option>
                          <option value="0">{lang("NO")}</option>
                        </select>
                        </div>
                        <div style={{width: '100%', textAlign: 'right'}}>
                          <Button 
                            style={{
                              backgroundColor: getColor(1),
                              color: getColor(2),
                              border: 0
                            }}
                          > {lang("SAVE")} </Button>
                        </div>
                    </Col>

                    <Col lg="12">
                      <label className="form-control-label"> {lang("LIST")} </label>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col"> {lang("NAME")} </th>
                            <th scope="col"> {lang("DATE")} </th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (load.t2 === true) ?
                            <tr>
                              <td colSpan={10} className='text-center'>
                                <Spinner style={{color: getColor(1)}} />
                              </td>
                            </tr>:
                            (error) ? 
                            <tr>
                              <td colSpan={10} className='text-muted text-center'>
                                <label>{lang("ERROR_LOAD")}</label>
                                <br />
                                <Button 
                                  color="secondary"
                                  onClick={() => alert()}>{lang("LOAD")}</Button>
                              </td>
                            </tr>:
                            (data.length === 0) ? 
                            <tr>
                              <td colSpan={10} className='text-muted text-center'> {lang("NO_INFO_YET")} </td>
                            </tr>
                            :
                            dates.map( (d,i) => (
                              <tr key={i}>
                                <td>
                                  {d.name}
                                </td>
                                <td>
                                  {dayjs(d.date).utc().format('DD-MM-YYYY')}
                                </td>
                                <td className="text-right">
                                  <Button size='sm' color="danger" onClick={() => deleteDate(d.id)}>{lang('DELETE')}</Button>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>

                </div>
              </CardBody>

            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default Pause;