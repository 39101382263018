/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Header from "components/Headers/Header";
import {Spinner, Card, Container, CardHeader, Row, Button, Col, CardBody, FormGroup, Input } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import lang from 'lang';
import { getAllCompanyStateData, getCitiesByStateData } from "api/places";
import { setNewContractorData } from "api/company";
import { getColor } from "utils/token";

export default function NewContractor() {
  const notificationAlertRef = React.useRef(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [cardMask, setCarMask] = useState('');
  const [cardId, setCardId] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [arrState, setArrState] = useState([]);
  const [arrCities, setArrCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contactName, setContactName] = useState('');
  const [address, setAddress] = useState('');
  const [comments, setComments] = useState('');

  useEffect(() => {
    getAllState();
  },[]);
 
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  
  const getAllState = async () => {
    const res = await getAllCompanyStateData();
    setArrState(res.data);
  }

  const getCitiesByState = async (id) => {
    const res = await getCitiesByStateData(id);
    setArrCities(res.data);
    setState(id);
  }
  
  const createContractor = async () => {
    setLoading(true);

    if(name.trim() === '') {
      notifyAlert("default", lang("MESSAGE"), 'Ingresar nombre');
    }
    else if(email.trim() === '') {
      notifyAlert("default", lang("MESSAGE"), "Ingresar e-mail");
    }
    else if (!/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/.test(email)) {
      notifyAlert("default", lang("MESSAGE"), "El formato de correo no es válido");
    }
    else if(phone.trim() === '') {
      notifyAlert("default", lang("MESSAGE"), "Ingresar e-mail");
    }
    else if(cardMask === '') {
      notifyAlert("default", lang("MESSAGE"), "Debe seleccionar el tipo de identificacion");
    }
    else if(cardId.trim() === '') {
      notifyAlert("default", lang("MESSAGE"), "Ingresar e-mail");
    }
    else if(state === '') {
      notifyAlert("default", lang("MESSAGE"), "Debe seleccionar estado");
    }
    else if(city === '') {
      notifyAlert("default", lang("MESSAGE"), "Debe seleccionar ciudad");
    }
    else if(contactName === '') {
      notifyAlert("default", lang("MESSAGE"), "Debe seleccionar estado");
    }
    else {
      const jsn = {
        "name": name,
        "email": email,
        "phone": phone,
        "cardMask": cardMask,
        "cardId": cardId,
        "state": state,
        "city": city,
        "contactName": contactName,
        "address": address,
        "comments": comments,
      }

      console.log(jsn);

      const res = await setNewContractorData(jsn);

      if(res.success === true) {
        notifyAlert("success", lang("MESSAGE"), res.data.message);
        window.location.href = '/admin/gopay/contractor';
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
    }

    setLoading(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("CONTRACTOR")} </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("FULLNAME")} </label>
                        <Input 
                          value={name}
                          placeholder={lang("FULLNAME")}
                          onChange={(val) => setName(val.target.value)} 
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("EMAIL")} </label>
                        <Input 
                          value={email}
                          placeholder={lang("EMAIL")}
                          onChange={(val) => setEmail(val.target.value)} 
                          />
                      </FormGroup>
                    </Col>
                    
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("PHONE")} </label>
                        <Input 
                          value={phone}
                          placeholder={lang("PHONE")}
                          onChange={(val) => setPhone(val.target.value)} 
                          />
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("CARD_RIF")} </label>
                        <br />
                        <div style={{
                          display: 'flex'
                        }}>
                          <select
                            style={{
                              width: '30%'
                            }}
                            className="form-control"
                            value={cardMask}
                            onChange={val => setCarMask(val.target.value)}
                          >
                            <option value="">{lang("SELECT")}</option>
                            <option value="V">V</option>
                            <option value="E">E</option>
                            <option value="J">J</option>
                            <option value="G">G</option>
                          </select>
                          <Input 
                            value={cardId}
                            placeholder={lang("CARD_RIF")}
                            onChange={(val) => setCardId(val.target.value)} 
                            />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("SEL_STATE")} </label>
                        <select
                          className="form-control"
                          value={state}
                          onChange={val => getCitiesByState(val.target.value)}
                        >
                          <option>{lang("SELECT")}</option>
                          {
                            arrState.map( (s, i) => (
                              <option 
                              key={i}
                              value={s.id}>{s.name}</option>
                              ))
                            }
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("CITY_ONE")} </label>
                        <select
                          className="form-control"
                          value={city}
                          onChange={val => setCity(val.target.value)}
                        >
                          <option>{lang("SELECT")}</option>
                          {
                            arrCities.map( (s, i) => (
                              <option 
                              key={i}
                              value={s.id}>{s.name}</option>
                              ))
                            }
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label"> {lang("CONTACT_NAME")} </label>
                        <Input 
                          value={contactName}
                          placeholder={lang("CONTACT_NAME")}
                          onChange={(val) => setContactName(val.target.value)} 
                        />
                      </FormGroup>
                    </Col>
                    
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> {lang("ADDRESS")} </label>
                        <textarea 
                          value={address}
                          className="form-control"
                          onChange={(val) => setAddress(val.target.value)} ></textarea>
                      </FormGroup>
                    </Col>

                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label"> {lang("COMMENTS")} </label>
                        <textarea 
                          value={comments}
                          className="form-control"
                          onChange={(val) => setComments(val.target.value)} ></textarea>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12" >
                      <br />
                      <div className="float-right">
                      {
                        (loading) ? <Spinner style={{color: getColor(1)}}/> :
                        <div>
                          <Button color="gray" to="/admin/gopay/contractor" tag={Link}> {lang("BACK")} </Button>
                          <Button
                            style={{
                              backgroundColor: getColor(1),
                              color: getColor(2),
                              border: 0
                            }}
                            onClick={() => createContractor()}
                            > {lang("SAVE")}
                          </Button>
                        </div>
                      }
                      </div>
                    </Col>
                  </Row>

                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
