/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Card, CardBody, CardHeader, Container, Row, Button, Col, FormGroup, Input, Spinner} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import Header from "components/Headers/Header";
import Compressor from 'compressorjs';
import lang from 'lang';
import { setToken, getColor } from "utils/token";
import { getCompanyUserInfoData, updateCompanyData, setPictureCompany } from "api/company";

const noPicture = require('assets/img/noPicture.png').default;

export default function Profile() {
  const notificationAlertRef = React.useRef(null);
  const [loading, setLoading] = useState({ load: false, email: false, pass: false});
  const [user, setUser] = useState({name:'', email:'', user_rol:'', lang: 'es', picture: null, color: '', sColor:''});
  const [picture, setPicture] = useState('');
  const [file, setFile] = useState({});
  
  useEffect(() => {
    getCompanyUserInfo();
  },[]);
  
  const getCompanyUserInfo = async () => {
    const res = await getCompanyUserInfoData();
    
    setUser({
      name: res.data.name,
      email: res.data.email,
      lang: res.data.lang,
      user_rol: res.data.user_rol
    });

    setPicture(res.data.picture);
    setLoading({...loading, load: false});
  }

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			reader.onload = (e) => {
				setPicture(e.target.result);
			};

			reader.readAsDataURL(event.target.files[0]);
			setFile(event.target.files[0]);
		}
	}

  const renderImageToSend = async (f) => {
		return new Compressor(f, {quality: 0.6});
	}

  const savePicture = async () => {
    setLoading({...loading, picture: true});

    let fileCompress = null;
    
    if(file && Object.keys(file).length === 0 && file.constructor === Object) {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_IMAGE"));
    }
    else {
      fileCompress = await renderImageToSend(file);
      
      const res = await setPictureCompany(fileCompress);

      if(res.success === true) {  
        setToken('GoSuiteToken', res.data.token);
        window.location.reload();
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }

    }
    
    setLoading({...loading, picture: false});
  }

  const saveData = async () => {
    setLoading({...loading, save: true});
    const name = String(user.name).trim();

    if(name === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_NAME"));
    }
    else {
      const jsn = {
        'name': name,
        'lang': user.lang
      }

      const res = await updateCompanyData(jsn);

      if(res.success === true) {
        setToken('GoSuiteToken', res.data.token);
        window.location.reload();
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
    }

    setLoading({...loading, save: false});
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      
      <Header />
      
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("PROFILE")} </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className='paddingHorizontal'>
                {
                  (loading.load) ? <div className="text-center">
                    <Spinner style={{
                      color: getColor(1)
                    }} />
                  </div> :
                  <>
                    <Row>
                      <Col lg="6">
                        <div className="text-center">
                          <img
                            alt="..."
                            id="imgBrand"
                            src={(picture === undefined) ? noPicture : picture}/>
                          <br />
                          {
                            (loading.picture) ? <div className="text-center" style={{marginTop: '5px'}}>
                              <Spinner style={{
                                color: getColor(1)
                              }} />
                            </div> :
                            <> 
                              <label className="custom-file-upload">
                                <input 
                                  type="file"
                                  accept="image/*"
                                  onChange={onImageChange}/>
                                {lang("EDIT")}
                              </label>
                              <Button
                                style={{
                                  backgroundColor: '#6c757d',
                                  color: '#fff',
                                  border: 0
                                }}
                                onClick={() => savePicture()}> {lang("SAVE")}
                              </Button>
                            </> 
                          }
                        </div>
                      </Col>
                      
                      <Col lg="6">
                        <div>
                          <FormGroup>
                            <label className="form-control-label"> {lang("ROL_TYPE")} </label> <br />
                            <input
                              readOnly
                              value={user.user_rol}
                              className="form-control"
                              onChange={val => setUser({...user, user_rol: val.target.value })} />
                          </FormGroup>

                          <FormGroup>
                            <label className="form-control-label"> {lang("NAME")} </label>
                            <Input
                              value={user.name}
                              className="form-control"
                              onChange={val => setUser({...user, name: val.target.value })}/>
                          </FormGroup>

                          <FormGroup>
                            <label className="form-control-label"> {lang("LANGUAGES")} </label>
                            <select
                              className="form-control"
                              value={user.lang}
                              onChange={val => setUser({...user, lang: val.target.value })}
                            >
                              <option value="es">{lang("SPANISH")}</option>
                              <option value="en">{lang("ENGLISH")}</option>
                            </select>
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" >
                        <br />
                        <div className="float-right">
                        {
                          (loading.save) ? <div className="text-center">
                            <Spinner style={{
                              color: getColor(1)
                            }} />
                          </div> :
                          <>
                            <Button
                              style={{
                                backgroundColor: getColor(1),
                                color: getColor(2),
                                border: 0
                              }}
                              onClick={() => saveData()}
                              > {lang("SAVE")}
                            </Button>
                          </>
                        }
                        </div>
                      </Col>
                    </Row>
                  </>
                }
                </div>
              </CardBody>
            </Card>

          </div>
        </Row>
      </Container>
    </>
  )
}
