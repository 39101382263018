import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

export async function getAllServicesData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(CONS.services, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getServicesData(id) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.services}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setServicesData(name, code, description, active) {
	const token = getToken('GoSuiteToken');

	const jsn = {
    "name": name,
    "code": code,
    "description": description,
		"active": (active === '') ? 1 : active
  }

	try {
		const res = await axios.post(CONS.services, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateServicesData(id, name, code, description, active) {
	const token = getToken('GoSuiteToken');

	const jsn = {
    "name": name,
    "code": code,
    "description": description,
		"active": (active === '') ? 1 : active
  }

	try {
		const res = await axios.put(`${CONS.services}/${id}`, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function deleteServicesData(id) {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.delete(`${CONS.services}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getAllActiveServicesData() {
	const token = getToken('GoSuiteToken');

	try {
		const res = await axios.get(`${CONS.services}/active`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}
