/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, {useState, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import Header from "components/Headers/Header";
import {Spinner, Card, Container, CardHeader, Row, Button, Input, Col, CardBody, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import lang from 'lang';
import { getAllCompanyStateData, getCitiesByStateData } from "api/places";
import { getAllServicesData } from "api/services";
import { getAllLayerData } from "api/layer";
import { getDrawTypeData } from "api/draw";
import { getMapAreaByIdData, updateMapAreaData, deleteMapAreaData } from "api/map";
import { getColor, getTokenData } from "utils/token";
import NotificationAlert from "react-notification-alert";

var zones = [];
var map;

const polyOptions = {
  strokeWeight: 0,
  fillOpacity: 0.45,
  editable: false,
  draggable: false
}

const EditMap = () => {
  const notificationAlertRef = React.useRef(null);
  const [role, setRole] = useState(0);
  const [stateArr, setStateArr] = useState([]);
  const [citiesArr, setCitiesArr] = useState([]);
  const [servicesArr, setServicesArr] = useState([]);
  const [layerArr, setLayerArr] = useState([]);
  const [drawArr, setDrawArr] = useState([]);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [zoom, setZoom] = useState('');
  const [description, setDescription] = useState('');
  const [selState, setSelState] = useState('');
  const [selCity, setSelCity] = useState('');
  const [selServices, setSelServices] = useState('');
  const [layer, setLayer] = useState('');
  const [draw, setDraw] = useState('');
  const [active, setActive] = useState('');
  const [bunisseUnit, setBunisseUnit] = useState('');
  const [modal, setModal] = useState(false);
  const [modalDel, setModalDel] = useState(false);
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState({load: true, button: false});
  const {id} = useParams();

  useEffect( () => {
    setRole(getTokenData('GoSuiteToken', 'role'));
    initialize();
  }, []);

  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const initialize = async () => {
    await getMapAreaById();
    await getAllState();
    await getAllServices();
    await getAllLayer();
    await getDrawType();
  }

  const getMapAreaById = async () => {
    const res = await getMapAreaByIdData(id);
    setName(res.data.Name);
    setCode(res.data.Code);
    setZoom(res.data.Zoom);
    setDescription(res.data.Description);
    setSelState(res.data.IDCompanyState);
    setResquestCity(res.data.IDCompanyState);
    setSelCity(res.data.IDCompanyCity);
    setSelServices(res.data.IDCompanyServices);
    setLayer(res.data.IDCompanyMapLayer);
    setDraw(res.data.IDCompanyDrawType);
    setActive(res.data.IsActive);
    setBunisseUnit(res.data.BussinesUnit);

    const pos = JSON.parse(res.data.coordinates);

    setLoading({...loading, load: false});
    initializeMap(pos, res.data.Zoom, res.data.DesignType)
  }

  const getAllState = async () => {
    const res = await getAllCompanyStateData();
    setStateArr(res.data);
  }

  const setResquestCity = async (id) => {
    const res = await getCitiesByStateData(id);
    setSelState(id)
    setCitiesArr(res.data);
  }

  const getAllServices = async () => {
    const res = await getAllServicesData();
    setServicesArr(res.data)
  }

  const getAllLayer = async () => {
    const res = await getAllLayerData();
    setLayerArr(res.data);
  }

  const getDrawType = async () => {
    const res = await getDrawTypeData();
    setDrawArr(res.data);
  }

  const toggleDel = () => setModalDel(!modalDel);

  function pinSymbol(color) {
    return {
      path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
      fillColor: color,
      fillOpacity: 1,
      strokeColor: '#000',
      strokeWeight: 2,
      scale: 1,
   };
  }

  const initializeMap = (arrPos, zoom, draw) => {
    var bounds = new google.maps.LatLngBounds();

    for (var i = 0; i < arrPos.length; i++) {
      bounds.extend(arrPos[i]);
    }

    const lat = bounds.getCenter().lat();
    const lng = bounds.getCenter().lng();

    try {
      map = new google.maps.Map(document.getElementById('map'), {
        zoom: zoom,
        center: new google.maps.LatLng(lat, lng),
        zoomControl: true,
        mapTypeControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false
      });
  
     if(arrPos === undefined) {
        zones = []
       
        for(var z of zones) {
          z.setMap(map)
        }
      }
      else {
        if(draw === 'polygon') {
          zones.push(new google.maps.Polygon({
            paths: arrPos,
            strokeColor: '#616161',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#616161',
            fillOpacity: 0.35,
          }));
        }
        else if (draw === 'point') {
          zones.push(new google.maps.Marker({
            map: map,
            position: arrPos,
            icon: pinSymbol(data.color),
          }));
        }
        else if(draw === 'circle') {
          zones.push(new google.maps.Circle({
            strokeColor: '#616161',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#616161',
            fillOpacity: 0.35,
            center: {
              lat: arrPos[0].lat,
              lng: arrPos[0].lng
            },
            radius: arrPos[0].radius,
          }));
        }
        else if(draw === 'polyline') {
          zones.push(new google.maps.Polyline({
            path: arrPos,
            geodesic: true,
            strokeColor: '#616161',
            strokeOpacity: 1.0,
            strokeWeight: 2,
          }));
        }
      
    
        for(var zn of zones) {
          zn.setMap(map)
        }
      }

      google.maps.event.addListener(map, 'zoom_changed', function() {
        var z = map.getZoom();
        setZoom(z);
      });
    }
    catch(err) {
      console.log(err);
    }
  }

  const deleteArea = async () => {
    const res = await deleteMapAreaData(id);

    if(res.success === true) {
      window.location.href = '/admin/gomap/map';
    }
    else {
      notifyAlert("danger", lang("MESSAGE"), res.error.message);
    }
  }

  const updArea = async () => {
    setLoading({...loading, button: true});
    if(name.trim() === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_NAME_MAP"));
    }
    else if(selState === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_STATE_MAP"));
    }
    else if(selCity === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_CITY_MAP"));
    }
    else if(selServices === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_SERVICES_MAP"));
    }
    else if(layer === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_LAYER_MAP"));
    }
    else if(draw === "") {
      notifyAlert("default", lang("MESSAGE"), lang("EMPTY_DRAW_TYPE_MAP"));
    }
    else {
      const _active = (active === "") ? 1 : active;
      const jsn = {
        "name": name,
        "code": code,
        "zoom": zoom,
        "description": description,
        "state": selState,
        "city": selCity,
        "services": selServices,
        "layer": layer,
        "active": _active,
        "draw": draw,
        "bunisseUnit": bunisseUnit
      }
      
      const res = await updateMapAreaData(id, jsn);

      if(res.success === true) {
        window.location.href = '/admin/gomap/map';
      }
      else {
        notifyAlert("danger", lang("MESSAGE"), res.error.message);
      }
    }
  
      setLoading({...loading, button: false});
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("MAP")} </h3>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">

                  <Row>
                    <Col lg="12" style={{
                      textAlign: 'center'
                    }}>
                      <div style={{
                        marginBottom: '10px',
                      }}>
                        <div id="map" style={{
                          height: '600px',
                          width: '100%'
                        }}></div>
                      </div>
                    </Col>
                  </Row>
                  
                  {
                    (loading.load) ? <div style={{
                      textAlign: 'center',
                      marginTop: "20px"
                    }}> 
                      <Spinner style={{ color: getColor() }} />
                    </div> :
                    <>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("NAME")} </label>
                            <Input
                              disabled={![1,2,3].includes(role)}
                              value={name}
                              className="form-control"
                              onChange={val => setName(val.target.value)}/>
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label"> {lang("CODE")} </label>
                            <Input
                              disabled={![1,2,3].includes(role)}
                              value={code}
                              className="form-control"
                              onChange={val => setCode(val.target.value)}/>
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label"> {lang("ZOOM")} </label>
                            <Input
                              value={zoom}
                              readOnly={true}
                              className="form-control" />
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label"> {lang("DESCRIPTION")}{' '}{lang("OPTIONAL")} </label>
                            <textarea
                              disabled={![1,2,3].includes(role)}
                              id='txtArea'
                              className="form-control"
                              value={description}
                              onChange={val => setDescription(val.target.value)}>
                            </textarea>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("STATE")} </label>
                            <select
                              disabled={![1,2,3].includes(role)}
                              className="form-control"
                              value={selState}
                              onChange={val => setResquestCity(val.target.value)}
                            >
                              <option value="">{lang("SELECT")}</option>
                              {
                                stateArr.map( (s,i) => (
                                  <option key={i} value={s.id}>{s.name}</option>
                                ))
                              }
                            </select>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("CITY")} </label>
                            <select
                              disabled={![1,2,3].includes(role)}
                              className="form-control"
                              value={selCity}
                              onChange={val => setSelCity(val.target.value)}
                            >
                              <option value="">{lang("SELECT")}</option>
                              {
                                citiesArr.map( (c,i) => (
                                  <option key={i} value={c.id}>{c.name}</option>
                                ))
                              }
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("SERVICES_TYPE")} </label>
                            <select
                              disabled={![1,2,3].includes(role)}
                              className="form-control"
                              value={selServices}
                              onChange={val => setSelServices(val.target.value)}
                            >
                              <option value="">{lang("SELECT")}</option>
                              {
                                servicesArr.map( (s,i) => (
                                  <option key={i} value={s.id}>{s.name} - {s.code}</option>
                                ))
                              }
                            </select>
                          </FormGroup>
                        </Col>                    

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("LAYERS")} </label>
                            <select
                              disabled={![1,2,3].includes(role)}
                              className="form-control"
                              value={layer}
                              onChange={val => setLayer(val.target.value)}
                            >
                              <option value="">{lang("SELECT")}</option>
                              {
                                layerArr.map( (l, i) => (
                                  <option key={i} value={l.id}>{l.name}</option>
                                ))
                              }
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("DRAW_TYPE")} </label>
                            <select
                              disabled={![1,2,3].includes(role)}
                              className="form-control"
                              value={draw}
                              onChange={val => setDraw(val.target.value)}
                            >
                              <option value="">{lang("SELECT")}</option>
                              {
                                drawArr.map( (d, i) => (
                                  <option key={i} value={d.id}>{d.name}</option>
                                ))
                              }
                            </select>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("ACTIVE")} </label>
                            <select
                              disabled={![1,2,3].includes(role)}
                              className="form-control"
                              value={active}
                              onChange={val => setActive(val.target.value)}
                            >
                              <option value="1">{lang("YES")}</option>
                              <option value="0">{lang("NO")}</option>
                            </select>
                          </FormGroup>
                        </Col>

                      </Row>
                      
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("BUSINESS_UNIT")}{' '}{lang( "OPTIONAL")} </label>
                            <Input
                              disabled={![1,2,3].includes(role)}
                              value={bunisseUnit}
                              className="form-control"
                              onChange={val => setBunisseUnit(val.target.value)}/>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col lg="12">
                          <br />
                          <div className="float-right">
                            {
                              (loading.button) ? <>
                                <Spinner color={getColor(1)} />
                              </> :
                              <>
                                <Button color="gray" to="/admin/gomap/map" tag={Link}> {lang("BACK")} </Button>
                                {
                                  ([1,2,3].includes(role)) ? <>
                                    <Button
                                      color="danger"
                                      onClick={() => toggleDel()}
                                      > {lang("DELETE")}
                                    </Button>

                                    <Button
                                      color="primary"
                                      onClick={() => updArea()}
                                      > {lang("SAVE")}
                                    </Button>                                  
                                  </> : <></>
                                }
                              </>
                            }
                          </div>
                        </Col>
                      </Row>                    
                    </>
                  }
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
	
        <Modal isOpen={modalDel} toggle={toggleDel}>
          <ModalHeader toggle={() => setModalDel(false)}> {lang("MESSAGE")} </ModalHeader>
          <ModalBody>
            {lang("AREA_DELETE")}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => toggleDel()}> {lang("CANCEL")} </Button>
            <Button color="danger" onClick={() => deleteArea()}>{lang("CONTINUE")} </Button>
          </ModalFooter>
        </Modal>
     
      </Container>

    </>
  )
}

export default EditMap;