/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, {useState, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import Header from "components/Headers/Header";
import {Spinner, Card, Container, CardHeader, Row, Button, Col, CardBody, FormGroup, Input } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import lang from 'lang';
import { getAllCompanyStateData, getCitiesByStateData } from "api/places";
import { getCompanyPaymentTypeData, addClientAtZohoData, getClientInfoData } from "api/company";
import { getColor } from "utils/token";
import dayjs from 'dayjs';

const EditClient = () => {
  const notificationAlertRef = React.useRef(null);
  const {id} = useParams();
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [cardMask, setCarMask] = useState('');
  const [cardId, setCardId] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [comments, setComments] = useState('');
  const [arrState, setArrState] = useState([]);
  const [arrCities, setArrCities] = useState([]);
  const [arrPayment, setArrPayment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [zoBtn, setZoBtn] = useState(false);
  const [sys, setSys] = useState(false);
  const [addressFiscal, setAddressFiscal] = useState('');
  const [addressInstalation, setAddressInstalation] = useState('');
  const [customerType, setCustomerType] = useState('');
  
  useEffect(() => {
    getClientInfo();
    getAllState();
    getCompanyPaymentType();
  },[]);
 
  const notifyAlert = (type, title, message) => {
    let options = {
      place: "tc",
      message: (
      <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  
  const getClientInfo = async () => {
    setLoading(true);
    const res = await getClientInfoData(id);
    console.log(res.data);

    setName(res.data.name);
    setLastName(res.data.lastName);
    setEmail(res.data.email);
    setPhone(res.data.phone);
    setCarMask(String(res.data.cardId)[0]);
    setCardId(String(res.data.cardId).substring(1,(res.data.cardId).length));
    setState(res.data.state);
    getCitiesByState(res.data.state);
    setCity(res.data.city);
    setPaymentType(res.data.type);
    setPaymentDate(dayjs(res.data.pay_date).add(1, 'day').format('YYYY-MM-DD').toString())
    setComments(res.data.comment);
    setSys(res.data.sys);
    setAddressFiscal(res.data.addressFiscal);
    setAddressInstalation(res.data.addressInstalation);
    setCustomerType(res.data.customer_type);
    setLoading(false);
  }

  const getAllState = async () => {
    const res = await getAllCompanyStateData();
    setArrState(res.data);
  }

  const getCitiesByState = async (id) => {
    const res = await getCitiesByStateData(id);
    setArrCities(res.data);
    setState(id);
  }

  const getCompanyPaymentType = async () => {
    const res = await getCompanyPaymentTypeData();
    setArrPayment(res.data);
  }
  
  const addClientAtZoho = async () => {
    setZoBtn(true);
    
    const jsn = {
      "id": id,
    }
    const res = await addClientAtZohoData(jsn);

    if(res.success === true) {
      notifyAlert("danger", lang("MESSAGE"), res.error.message);
    }
    else {
      notifyAlert("danger", lang("MESSAGE"), res.error.message);
    }

    setZoBtn(false);
  }

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col mb-0 float-left">
                    <h3> {lang("CLIENT")} </h3>
                  </div>
                  {
                    (sys === null) ? <>
                      <div className="col">
                        {
                          (zoBtn) ? <>
                            <div className='float-right'>
                              <Spinner style={{color: getColor(1)}}/>
                            </div>
                          </> :
                          <>
                            <Button
                              color='danger'
                              className="float-right btn-sm"
                              onClick={() => addClientAtZoho()}
                            >{lang("ADD_USER_ZOHO")}</Button> 
                          </>
                        }
                      </div>
                    </> : <></>
                  }
                </Row>
              </CardHeader>

              <CardBody>
                <div className="pl-lg-4">
                  {
                    (loading) ? <> 
                      <div className='text-center'>
                        <Spinner style={{color: getColor(1)}}/>
                      </div>
                    </>: <>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("NAME")} </label>
                            <Input 
                              value={name}
                              placeholder={lang("NAME")}
                              readOnly={true} />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("LASTNAME")} </label>
                            <Input 
                              value={lastName}
                              placeholder={lang("LASTNAME")}
                              readOnly={true} />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("EMAIL")} </label>
                            <Input 
                              value={email}
                              placeholder={lang("EMAIL")}
                              readOnly={true}
                            />
                          </FormGroup>
                        </Col>
                        
                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("PHONE")} </label>
                            <Input 
                              value={phone}
                              placeholder={lang("PHONE")}
                              readOnly={true} />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("CARD_RIF")} </label>
                            <br />
                            <div style={{
                              display: 'flex'
                            }}>
                              <select
                                style={{
                                  width: '30%'
                                }}
                                className="form-control"
                                value={cardMask}
                                disabled={true}
                              >
                                <option value="">{lang("SELECT")}</option>
                                <option value="V">V</option>
                                <option value="E">E</option>
                                <option value="J">J</option>
                                <option value="G">G</option>
                              </select>
                              <Input 
                                value={cardId}
                                placeholder={lang("CARD_RIF")}
                                readOnly={true}
                              />
                            </div>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("SEL_STATE")} </label>
                            <select
                              className="form-control"
                              value={state}
                              disabled={true}>
                              <option>{lang("SELECT")}</option>
                              {
                                arrState.map( (s, i) => (
                                  <option 
                                  key={i}
                                  value={s.id}>{s.name}</option>
                                  ))
                                }
                            </select>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("CITY_ONE")} </label>
                            <select
                              className="form-control"
                              value={city}
                              disabled={true}>
                              <option>{lang("SELECT")}</option>
                              {
                                arrCities.map( (s, i) => (
                                  <option 
                                  key={i}
                                  value={s.id}>{s.name}</option>
                                  ))
                                }
                            </select>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("PAYMENT_TYPE")} </label>
                            <select
                              className="form-control"
                              value={paymentType}
                              disabled={true}>
                              <option>{lang("SELECT")}</option>
                              {
                                arrPayment.map( (s, i) => (
                                  <option 
                                    key={i}
                                    value={s.id}>{s.name}</option>
                                ))
                              }
                            </select>
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("PAYMENT_DATE")} </label>
                            <Input 
                              type='date'
                              value={paymentDate}
                              placeholder={lang("PAYMENT_DATE")}
                              disabled={true} />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label className="form-control-label"> {lang("CUSTOMER_TYPE")} </label>
                            <select
                              className="form-control"
                              value={customerType}
                              disabled={true}
                              onChange={val => setCustomerType(val.target.value)}>
                              <option value="">{lang("SELECT")}</option>
                              <option value="individual">{lang("INDIVIDUAL")}</option>
                              <option value="business">{lang("BUSINESS")}</option>
                            </select>
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label"> {lang("FISCAL_ADDRESS")} </label>
                            <textarea 
                              value={addressFiscal}
                              className="form-control"
                              readOnly={true}></textarea>
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label"> {lang("INSTALATION_ADDRESS")} </label>
                            <textarea 
                              value={addressInstalation}
                              className="form-control"
                              readOnly={true}></textarea>
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <FormGroup>
                            <label className="form-control-label"> {lang("OBSERVATION")} </label>
                            <textarea 
                              value={comments}
                              className="form-control"
                              readOnly={true}></textarea>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12" >
                          <br />
                          <div className="float-right">
                              <Button color="gray" to="/admin/gopay/client" tag={Link}> {lang("BACK")} </Button>
                          </div>
                        </Col>
                      </Row>
                    </>
                  }

                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default EditClient;