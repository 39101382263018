import axios from "axios";
import * as CONS from "./constants";
import { getToken } from "../utils/token";

export async function getAllCountriesData() {
	const token = getToken('GoSuiteToken');
	
	try {
		const res = await axios.get(CONS.countries, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getAllStateData(id) {
	const token = getToken('GoSuiteToken');
	
	try {
		const res = await axios.get(`${CONS.country_state}/${id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getAllAccessData(id) {
	const token = getToken('GoSuiteToken');
	
	try {
		const res = await axios.get(CONS.country_access, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getAllBillingData(id) {
	const token = getToken('GoSuiteToken');
	
	try {
		const res = await axios.get(CONS.country_billing, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function setCompanyData(jsn) {
	const token = getToken('GoSuiteToken');
	
	try {
		const res = await axios.post(CONS.company_creation, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getCompaniesData(jsn) {
	const token = getToken('GoSuiteToken');
	
	try {
		const res = await axios.get(CONS.companies, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function getRoles() {
  const token = getToken('GoSuiteToken');

  try {
		const res = await axios.get(CONS.user_roles, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}

}

export async function registerNewUser(jsn) {
  const token = getToken('GoSuiteToken');

  try {
		const res = await axios.post(CONS.register_user, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}

}

export async function getUsersData(company_id) {
  const token = getToken('GoSuiteToken');

  try {
		const res = await axios.get(`${CONS.company_users}/${company_id}`, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}
}

export async function updateUsersData(jsn) {
  const token = getToken('GoSuiteToken');

  try {
		const res = await axios.put(`${CONS.update_company_user}/${jsn.CompanyUsersID}`, jsn, {
      headers: {
        'token': token
      }
    });
		return res.data;
	}
	catch(err) {
		return err;
	}

}

